/**
 * 定义排序的方法
 * @param {*} property 
 * @returns 
 */
let compare = function (property) {
  return function (a, b) {
    var value1 = a[property];
    var value2 = b[property];
    return value1 - value2
  }
}

/**
 * 项目PPT特定定义的数据排序的方法
 * @param {*} serverPpt 服务器返回的PPT的数据
 */
let pptArrSort = (serverPpt) => {
  //先给服务器返回的Ppt数据每项加入一个索引值,索引值的取值规则是取Ppt名称中的编号
  let arr = []
  serverPpt.forEach(item => {
    //创建新的对象
    let obj = new Object();
    //配置图片地址
    obj.img = item;
    //取出'('和')'的位置
    let start = item.indexOf("(") + 1
    let end = item.indexOf(")")
    //截取序号
    let sortId = item.slice(start, end);
    obj.sortId = sortId;
    arr.push(obj)
  })
  //第二步:对arr按照sortId的升序进行排序
  arr.sort(compare('sortId'))
  return arr;
}

export {
  pptArrSort
}
