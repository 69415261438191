import { createStore } from 'vuex'
export default createStore({
    state: {
        userInfo: '',
        isLoginAlert: false,
        isUserInfoAlert: false,
        
        quesition: {
            pageType: 1, // 1 考试 2 解析 3 批改 4 考情
            pageName: '',
            examId: 20101,
            classId: 132,
            role: 1, // 1 学生 2 老师
            progress: 0,
            currentTime: 0,
            actQueData: {},
            quesitionInfoData: {},
            quesitionData: [],
            uid:'', // 批改试卷参数 用户id
            correctUsers: 0 // 待批改人数
        }
    },

    getters: {
        getIsLoginAlert(state) {
            return state.isLoginAlert
        },

        getIsUserInfoAlert(state) {
            return state.isUserInfoAlert
        },

        getUserInfo(state) {
            if(!state.userInfo){
                console.log(localStorage.getItem('autoLogin'))
                if (localStorage.getItem('autoLogin') === 'true') {
                    state.userInfo = JSON.parse(localStorage.getItem('userInfo'));
                } else {
                    state.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
                }
                console.log(state.userInfo)
            }
            return state.userInfo
        },

        // quesition
        getQuesitionData(state) {
            return state.quesition
        },

        // ... 做题进度条
        getProgress (state, num) {
            return parseInt(num / state.quesition.quesitionInfoData.totalQuestionCount * 100)
        },

        getCorrectUsers (state) {
            return state.quesition.correctUsers
        }
    },

    mutations: {
        setLoginAlertShow(state, type) {
            state.isLoginAlert = type
        },

        setUserInfoAlertShow(state, type) {
            state.isUserInfoAlert = type
        },

        setUserInfo(state, info) {
            console.log(info)
            if (info) {
                if (localStorage.getItem('autoLogin') === 'true') {
                    localStorage.setItem('userInfo', JSON.stringify(info))
                } else {
                    sessionStorage.setItem('userInfo', JSON.stringify(info))
                }
            }
            state.userInfo = info
        },

        // quesition
        setPaperParams (state, params) {
            state.quesition.pageType = params.type
            state.quesition.examId = params.examId
            state.quesition.classId = params.classId
            state.quesition.uid = params.uid
            state.quesition.role = params.role
            state.quesition.pageName = params.pageName,
            state.quesition.progress = 0
        },

        setCurrentTime (state, num) {
            state.quesition.currentTime = num
        },

        setActQueData (state, data) {
            console.log(data)
            state.quesition.actQueData = data ? data : {}
            console.log(state.quesition.actQueData)
        },

        setQueInfoData (state, data, seartchQueData) {
            if (seartchQueData) {
                state.quesition.quesitionInfoData.scratchQuestionData = seartchQueData
                return 
            }
            state.quesition.quesitionInfoData = data
        },

        setQueData (state, data) {
            console.log(data)
            state.quesition.quesitionData = data
        },

        setProgress (state, num) {
            state.quesition.progress = parseInt(num / state.quesition.quesitionInfoData.totalQuestionCount * 100)
        },

        setUserCorrectOrNot (state) {
            let userList = state.quesition.quesitionInfoData.userList
            console.log(userList)
            console.log(state.quesition.uid)
            let index = userList.findIndex((value) => value.userId === state.quesition.uid)
            console.log(index)
            console.log(this)
            if (index != -1) {
                userList[index].correctOrNot = 1
                console.log(this.$store)
                state.quesition.quesitionInfoData.userList = userList
            }
            this.dispatch('setCorrectUsers')
        },

        setUid (state, uid) {
            state.quesition.uid = uid
        },

        setCorrectUsers (state) {
            let num = 0;
            for (let i = 0; i < state.quesition.quesitionInfoData.userList.length; i++) {
                if (state.quesition.quesitionInfoData.userList[i].correctOrNot == 0) {
                    num += 1
                }
            }
            state.quesition.correctUsers = num
        }
    },

    actions: {
        setLoginAlertShow(context, type) {
            context.commit('setLoginAlertShow', type)
        },

        setUserInfoAlertShow(context, type) {
            console.log('a')
            context.commit('setUserInfoAlertShow', type)
        },

        setUserInfo(context, info) {
            context.commit('setUserInfo', info)
        },

        // quesition
        setPaperParams(context, info) {
            context.commit('setPaperParams', info)
        },

        setCurrentTime(context, num) {
            context.commit('setCurrentTime', num)
        },

        setActQueData(context, data) {
            console.log(data)
            context.commit('setActQueData', data)
        },

        setQueInfoData(context, data, seartchQueData) {
            context.commit('setQueInfoData', data, seartchQueData)
        },

        setQueData(context, data) {
            context.commit('setQueData', data)
        },

        setProgress(context, data) {
            context.commit('setProgress', data)
        },

        setUserCorrectOrNot(context, data) {
            context.commit('setUserCorrectOrNot', data)
        },

        setCorrectUsers(context) {
            context.commit('setCorrectUsers')
        },

        setUid(context, uid) {
            context.commit('setUid', uid)
        }
    }
})