import App from './App'
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import VueAwesomeSwiper from 'vue-awesome-swiper';
// import 'swiper/dist/css/swiper.css';
import router from './router'
import store from './store'

// 公共样式
import './assets/css/element-variables.scss'
import './assets/css/elementUi.css'
import './assets/css/default.css'
import './assets/icon/iconfont.css'

import util from './util/util'
import $ from 'jquery'
import * as echarts from 'echarts'
import * as ElIcons from '@element-plus/icons-vue'

const app = createApp(App)
for (let i in ElIcons) {
    app.component(i, ElIcons[i])
}
app.config.ignoredElements = [/^app-/]
app.config.globalProperties.$util = util
app.config.globalProperties.$ = $
app.config.globalProperties.$echarts = echarts
app.use(ElementPlus, {
    locale: zhCn
})
app.use(VueAwesomeSwiper)
app.use(router)
app.use(store)
app.mount('#app')