const getQueTitle = function (queType) {
    let queTitle = ''
    switch (Number(queType)) {
        case 1:
            queTitle = '选择题'
            break;
        case 2:
            queTitle = '填空题'
            break;
        case 3:
            queTitle = '判断题'
            break;
        case 4:
            queTitle = '创作题'
            break;
        default:
            break;
    }
    return queTitle
}

const getBreadcrumbName = function (pageType) {
    let breadcrumb = ''
    switch (Number(pageType)) {
        case 1:
            breadcrumb = ''
            break;
        case 2:
            breadcrumb = '解析'
            break;
        case 3:
            breadcrumb = '批改试卷'
            break;
        case 4:
            breadcrumb = '班级考情'
            break;
        default:
            break;
    }
    return breadcrumb
}

const numToLetter = function (num) {
    let myarr = new Array('A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z');
    return myarr[num]
}

export default {
    getQueTitle,
    getBreadcrumbName,
    numToLetter,
}