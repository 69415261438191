<template>
  <div :class="['que-option type1', getQuesitionData.actQueData.userAnswer]">
      <el-radio-group class="ub ub-ver" v-model="getAnswer" @change="changeSelect" :disabled="getQuesitionData.pageType != 1">
        <div class="option-item ub ub-ac" v-for="(item, index) in getQuesitionData.actQueData.optionList" :key="index">
            <el-radio :label="item.serialNum">
                <span class="letter">{{getLetter(Number(item.serialNum) -1) + '.'}}</span>
            </el-radio>
            <div v-html="item.option" class="select-item"></div>
        </div>
      </el-radio-group>
  </div>

</template>

<script>
import queJs from '../../../../../util/que'
import { mapGetters } from 'vuex'
export default {
    data () {
     return {

     }
   },
   components: {
       
   },

   created() {
       
   },

   mounted() {
       console.log('bbbb')

   },

   computed: {
       ...mapGetters([
        'getQuesitionData'
        ]),

       getLetter () {
         return function (index) {
             return queJs.numToLetter(index)
         }
       },

       getAnswer: {
           get: function () {
               console.log(this.getQuesitionData)
               if (this.getQuesitionData.pageType == 1) {
                   return this.getQuesitionData.actQueData.userAnswer
               } else if (this.getQuesitionData.pageType == 2){
                   return this.getQuesitionData.actQueData.useAnswerRecord
               } else if (this.getQuesitionData.pageType == 4) {
                   return this.getQuesitionData.actQueData.optionAnswer
               } else {
                 return ''
               }
           },

           set: function (val) {
            console.log(val)
               this.getQuesitionData.actQueData.userAnswer = val
               console.log(this.getQuesitionData.actQueData.userAnswer)
            //    this.$set(this.getQuesitionData.actQueData ,"userAnswer", val)
           }
       }
   },

   methods: {
       changeSelect (val) {
           // ...
           console.log(val)
            console.log(this.getQuesitionData.actQueData.optionList)

       }
   },
}
</script>

<style lang="scss">
.que-option {
    .el-radio-group {
        width: 100%;
        align-items: flex-start;
        .option-item {
            .el-radio {
                white-space: normal;
                .el-radio__input {
                    position: absolute;
                    top: 50%;
                    margin-top: -10px;

                    .el-radio__inner {
                        width: 20px;
                        height: 20px;
                        background-color: #fff;
                    }

                    .el-radio__inner:hover {
                        border-color: var(--el-color-primary);
                    }
                    
                    .el-radio__inner::after {
                        width: 12px;
                        height: 12px;
                        background-color: var(--el-color-primary);
                    }
                }

                .el-radio__input.is-checked {
                    .el-radio__inner {
                        border-color: var(--el-color-primary);
                    }
                }

                .el-radio__label {
                    padding-left: 30px;
                    font-size: 18px;
                    width: 100%;
                    box-sizing: border-box;
                    display: block;

                    .letter {
                        position: absolute;
                        top: 50%;
                        left: 40px;
                        margin-top: -12px;
                        color: #000;
                    }
                }
            }

            .select-item {
                width: 100%;
                box-sizing: border-box;
                display: inline-block;
                padding: 5px 0px 5px 30px;
                color: #000;
                line-height: 20px;
                margin: 10px 0;

                p {
                    width: 100%;
                    word-wrap: break-word;
                    padding: 0;
                    margin: 0;
                    color: #000;
                    font-size: 18px;
                    line-height: 26px;
                }

                img {
                    vertical-align: middle;
                    display: block;
                }
            }

            .el-radio.is-checked {
                .select-item {
                    background-color: #c4e1ff;
                    border-radius: 3px;
                }
            }
        }  
    }
}
</style>