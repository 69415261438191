<template>
    <div class="model-test-scroll-resources">
        <div class="swiper-module" v-if="curriculaList && curriculaList.length > 0">
            <swiper :options="swiperOption" ref="swiperFour">
                <swiper-slide v-for="(item, index) in curriculaList" :key="index" class="stop-swiping">
                    <div :class="['model-process-item', permit == 1 ? '' : 'gray']" @click="resourcesDetail(item.curriculumId, item.curriculumType, item.curriculumId, mainId, item.curriculumName, permit)">{{ item.curriculumName }}</div>
                </swiper-slide>
            </swiper>
            <div :class="['switch-button-prev icon-xiaoyu iconfont', isSwiperFirst ? 'first' : '']" @click="swiperSwitch(1)"></div>
            <div :class="['switch-button-next icon-dayuhao iconfont', isSwiperLast || curriculaList.length < 4 ? 'last' : '']" @click="swiperSwitch(2)"></div>
        </div>
        <div class="download iconfont icon-xiazai1">
            <div v-if="isDownloadUrl">
                <a :href="downloadUrl" target="_blank" v-if="permit == 1">备选资料下载</a>
                <span @click="$message.info('暂无权限，请联系客服！')" v-else>备选资料下载</span>
            </div>
            <span v-else>暂无资源</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['curriculaList', 'mainId', 'downloadUrl', 'permit'],
    data() {
        return {
            swiperOption: {
                loop: false,
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 0,
                noSwipingClass: 'stop-swiping',
                on:{          
                    // 使用es6的箭头函数，使this指向vue对象          
                    slideChange: () => {
                        this.isSwiperEnd()
                        this.isSwiperBeginning()
                    },
                }    
            },
            isSwiperLast: true,
            isSwiperFirst: true
        }
    },

    computed: {
        swiperFour: function () {
            return this.$refs.swiperFour
        },

        isDownloadUrl: function () {
            return this.downloadUrl.indexOf('http') != -1
        }
    },

    mounted() {
        setTimeout(() => {
          this.isSwiperEnd()
          this.isSwiperBeginning()
          console.log(this.permit)
       }, 1500)
    },

    methods: {
       swiperSwitch(index) {
           if (index == 1) {
                this.swiperFour.swiper.slidePrev();
           } else {
                this.swiperFour.swiper.slideNext();
           }
       },

       isSwiperEnd () {
            this.isSwiperLast = this.swiperFour.swiper.isEnd
       },

       isSwiperBeginning () {
           this.isSwiperFirst = this.swiperFour.swiper.isBeginning 

       },

       resourcesDetail(id, type, curriculumId, mainId, title, permit) {
           if (!permit) {
               this.$message.info("暂无权限，请联系客服！");
               return
           }
           this.$router.push({path: `/courseProcess`, query: {mode: 2, id: id, type: type, curriculumId: curriculumId, mainId: mainId, title: title}})
       }
    }
}
</script>

<style lang='scss'>
.model-test-scroll-resources {
        height: 260px;
        background-color: #fff;
        padding-top: 35px;
        box-sizing: border-box;
        .swiper-module {
            width: 100%;
            margin: 0 auto;
            position: relative;
            
            .swiper-container {
                width: 1052px;
                margin: 0 auto;
                .swiper-wrapper {
                    .swiper-slide {
                        // width: 262px !important;
                    }

                    .model-process-item {
                        width: 251px;
                        height: 138px;
                        line-height: 138px;
                        font-size: 24px;
                        color: #fff;
                        background-color: #70B600;
                        box-sizing: border-box;
                        // border: 1px solid #0080ff;
                        border-radius: 5px;
                        overflow: hidden;
                        cursor: pointer;
                        background-image: url('../../../assets/image/lever_item_bg.png');
                        background-size: 100%;
                        background-repeat: no-repeat;
                        text-align: center;

                        .modle-test-title {
                            width: 100%;
                            height: 55px;
                            line-height: 55px;
                            text-align: center;
                            background-color: var(--second-bgc);
                            font-weight: bold;
                        }

                        .modle-test-con {
                            width: 100%;
                            box-sizing: border-box;
                            border: 1px solid var(--primary-border);
                            border-top: 0px;
                            border-bottom-left-radius: 25px;
                            border-bottom-right-radius: 25px;
                        }

                        .modle-test-time {
                            height: 60px;
                            line-height: 60px;
                        }

                        .modle-test-detail {
                            span {
                                color: var(--el-color-primary);
                                cursor: pointer;
                            }
                        }

                        .el-button {
                            width: 167px;
                            height: 38px;
                            border-radius: 100px;
                            background-color: var(--el-color-primary);
                            color: #fff;
                            font-size: 16px;
                            border-color: var(--el-color-primary);
                            padding: 0;
                            margin-top: 15px;
                            margin-bottom: 15px;
                        }

                        .el-button:active {
                            background-color: #0068cf;
                        }
                    }

                    .modle-test-info {
                        width: 193px;
                        margin: 0 auto;
                        line-height: 24px;
                        font-size: 14px;
                        text-align: left;
                        color: #999999;
                        
                        .last-time {
                            // padding: 12px 0 8px;
                            font-size: 12px;
                        }

                        .modle-see {
                            color: #0080ff;
                            cursor: pointer;
                        }
                    }

                    .attendance-see {
                        text-align: left;
                        font-size: 14px;
                        color: var(--el-color-primary);
                        width: 193px;
                        margin: 5px auto;
                        cursor: pointer;
                    }
                }

                .swiper-pagination {
                    bottom: 5px;
                }
            }

            .switch-button-prev,
            .switch-button-next {
                font-size: 54px;
                color: var(--el-color-primary);
                position: absolute;
                margin-top: -30px;
                top: 50%;
                cursor: pointer;
            }

            .switch-button-prev {
                left: 0;
            }

            .switch-button-prev.first,.switch-button-next.last {
                color: #bfbfbf;
            }

            .switch-button-next {
                right: 0;
            }
        }

        .download {
            text-align: left;
            color: #000;
            margin-top: 23px;
            padding-left: 70px;
            line-height: 30px;
            display: block;
        }

        .download a {
            color: #000;
        }

        .download::before {
            font-size: 30px;
            float: left;
            margin-right: 5px;
        }
}
</style>