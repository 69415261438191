<template>
  <div class="home">
    <page-header></page-header>
    <div class="content">
        <el-image class="banner" :src="require('../../assets/image/home_banner.jpg')"></el-image>
        <module-one></module-one>
        <module-two></module-two>
        <module-three></module-three>
        <module-four></module-four>
        <module-five></module-five>
        <module-six></module-six>
        <module-advert></module-advert>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '../../components/PageHeader'
import PageFooter from '../../components/PageFooter'
import ModuleOne from './components/ModuleOne'
import ModuleTwo from './components/ModuleTwo'
import ModuleThree from './components/ModuleThree'
import ModuleFour from './components/ModuleFour'
import ModuleFive from './components/ModuleFive'
import ModuleSix from './components/ModuleSix'
import ModuleAdvert from './components/ModuleAdvert'
export default {
  components: {
    PageHeader,
    PageFooter,
    ModuleOne,
    ModuleTwo,
    ModuleThree,
    ModuleFour,
    ModuleFive,
    ModuleSix,
    ModuleAdvert
  },

  data() {
    return {

    }
  }
}
</script>

<style lang="scss">
.home {
  .content {
    min-height: 700px;
    margin-top: -90px;

    .banner {
      width: 100%;
      background-color: #0c0151;

      img {
        float: left;
        overflow: hidden;
        min-width: 1400px;
        max-width: 1920px;
        margin: auto;
      }
    }
  }
}
</style>