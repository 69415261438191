<template>
  <div class="module-four">
      <div class="module-content">
        <h5>自主研发的编程教材</h5>
        <el-image :src="image" lazy></el-image>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            image: require('../../../assets/image/home_module_four.png')
        }
    }
}
</script>

<style lang="scss">
.module-four {
    position: relative;
    padding-top: 160px;

    .module-content {
        width: 1352px;
        height: 734px;
        border: 5px solid var(--el-color-primary);
        margin: 0 auto;
        border-radius: 30px;

        h5 {
            padding: 0;
            margin: 0;
            font-size: 66px;
            letter-spacing: 8px;
            width: 822px;
            height: 154px;
            line-height: 154px;
            border: 5px solid var(--el-color-primary);
            border-radius: 30px;
            position: absolute;
            left: 50%;
            margin-left: -411px;
            top: 68px;
            background-color: #fff;
            z-index: 10;
            color: var(--el-color-primary);
            font-weight: 500;
            text-align: center;
        }

        img {
            margin-top: 141px;
        }
    }
}
</style>