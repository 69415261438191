<template>
  <el-dialog
    :model-value="getIsUserInfoAlert"
    title="个人信息"
    :close-on-click-modal="false"
    custom-class="default-dialog user-info-dialog"
    :before-close="handleClose"
  >
    <div class="user-info-con ub" v-if="getUserInfo">
        <!-- 错误提示 -->
        <el-alert :title="errInfo.title"
                    v-if="errInfo.show"
                    :closable="false"
                    :type="errInfo.type"
                    show-icon></el-alert>
        <!-- 错误提示 end -->
        <div class="user-message ub-f1">
            <div class="input-item ub">
                <span class="label">昵称</span>
                <el-input v-model="userNick" type="text" maxlength="5"></el-input>
            </div>

            <div class="input-item ub">
                <span class="label">真实姓名</span>
                <el-input v-model="userName"
                            type="text"
                            @keyup.enter="saveInfo"  maxlength="5"></el-input>
            </div>

            <div class="input-item ub">
                <span class="label">性别</span>
                <div class="ub sex-con ub-be">
                    <button :class="['default-button', userSex == index ? 'act' : '']"
                            @click="setSex(index)"
                            v-for="(item, index) in sexOptions" :key="index">{{ item }}</button>
                </div>
            </div>

            <div class="input-item ub">
                <span class="label"></span>
                <el-date-picker
                    v-model="actAge"
                    type="date"
                    placeholder="Pick a day"
                    :disabled-date="disabledDate"
                    value-format="YYYY-MM-DD"
                />
                <!-- <el-select v-model="actAge"
                            placeholder="请选择"
                            @change='selectAge'>
                    <el-option v-for="item in ageOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.label">
                    </el-option>
                </el-select> -->
            </div>

            <div class="input-item ub">
                <span class="label">绑定手机号</span>
                <el-input v-model="userTel" type="text" :disabled="true"></el-input>
            </div>

            <div class="input-item ub" v-if="getUserInfo.role == 2">
                <span class="label">教师所在学校</span>
                <el-input v-model="getUserInfo.schoolName"
                            type="text"
                            :disabled="true"></el-input>
            </div>
        </div>
        <div class="user-img">
            <el-upload class="avatar-uploader"
                     :action="upLoadUrl"
                     :data="upLoadParam"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload">
                <div :class="['img', getUserInfo.user_img ? '' : 'default-pic']" :style="getUserInfo.user_img ? 'background-image:url(' + getUserInfo.user_img + ');' : 'background-image:url(' + require('../assets/image/header_default_user_logo.png') + ');'"></div>
                <div class="upload-text">上传头像</div>
            </el-upload>
        </div>
    </div>
    <el-button class="default-button save-button" type="primary" @click="userInfoVerification">保存</el-button>
  </el-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { login } from '../http/api'
import config from '../http/config'
export default {
    props: ["getIsUserInfoAlert"],
    setup() {
        const formData = {
                
            };

        return {
            formData
        }
    },
    data() {
        return {
            errInfo: {
                show: false,
                title: '',
                type: 'info',
            },
            sexOptions: ['男', '女'],
            ageOptions: [],
            // userInfo
            userImg: '',
            userNick: '',
            userName: '',
            userSex: 0,
            actAge: '',
            userShoolName: ''
        }
    },
    created() {
        this.ageOptions = this.getAgeOptions()
    },

    computed: {
        ...mapState([
            'isUserInfoAlert'
        ]),

        ...mapGetters([
            'getIsUserInfoAlert',
            'getUserInfo'
        ]),

        disabledDate() {
            return (time) => {
                return time.getTime() > Date.now()
            }
        }
    },

    methods: {
        /**
         * 关闭弹窗
         */
        handleClose() {
            this.$store.dispatch('setUserInfoAlertShow', false)
        },

        /**
         * 初始化数据
         */
        initUserInfo() {
            this.userImg = this.getUserInfo.user_img
            this.userNick = this.$util.isDefine(this.getUserInfo.nickname) ? this.getUserInfo.nickname : "cxw_" + this.getUserInfo.tel.slice(7)
            this.userName = this.getUserInfo.name
            this.userSex = this.$util.isDefine(this.getUserInfo.sex) ? this.getUserInfo.sex : this.userSex
            this.actAge = this.$util.isDefine(this.getUserInfo.age) ? this.getUserInfo.age : '';
            // this.actAge = ''
            this.userTel = this.getUserInfo.tel.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
            // 上传头像
            this.upLoadUrl = config.login
            console.log(this.upLoadUrl)
            this.upLoadParam = {
                type: 8,
                uid: this.getUserInfo.id,
                c: this.getUserInfo.tel,
                token: this.getUserInfo.token
            }
            console.log(this.upLoadParam)
        },

        /**
         * 设置年龄段
         * @returns {Array} ageOptions 年龄段
         */
        getAgeOptions() {
            let ageOptions = []
            for (var i = 4; i < 51; i++) {
                var str = {
                    value: "选项" + i,
                    label: i + "岁"
                };
                ageOptions.push(str);
            }
            return ageOptions
        },

        /**
         * 选择年龄
         */
        selectAge() {

        },

        /**
         * 修改性别
         * @param {Number} type 0 男 1 女
         */
        setSex(type) {
            this.userSex = type
        },

        /**
         * 验证用户信息
         */
        userInfoVerification() {
            if(!this.$util.isDefine(this.userNick)) {
                this.errInfo = {
                    title: "请输入昵称！",
                    show: true
                };
                return
            }

            if (this.userNick.length > 40) {
                this.errInfo = {
                    show: true,
                    title: "昵称最多允许40个字！"
                };
                return;
            }

            if(!this.$util.isDefine(this.userName)) {
                this.errInfo = {
                    title: "请输入真实姓名！",
                    show: true
                };
                return
            }

            if (this.userName.length > 15) {
                this.errInfo = {
                    show: true,
                    title: "真实姓名最多允许15个字！"
                };
                return;
            }

            /* if (this.regExp(this.userName)) {
                this.errInfo = {
                    show: true,
                    title: "真实姓名禁止特殊字符和数字！"
                };
                return;
            } */

            this.saveInfo()
        },

        /**
         * 保存修改
         */
        saveInfo() {
            this.errInfo = {
                show: false,
                title: ""
            };

            let params = {
                type: 7,
                c: this.getUserInfo.tel,
                token: this.getUserInfo.token,
                name: this.userName,
                na: this.userNick,
                age: this.actAge,
                sex: this.userSex 
            }
            login(params).then((res) => {
                console.log(res)
                this.$message({
                    message: "更新成功！",
                    type: "success",
                    showClose: true
                });

                let userInfo = this.getUserInfo
                userInfo.token = res.token
                userInfo.nickname = this.userNick
                userInfo.name = this.userName
                userInfo.sex = parseInt(this.userSex)
                userInfo.age = this.actAge
                this.upLoadParam.token = res.token
                this.$store.dispatch('setUserInfo', userInfo)
                this.initUserInfo()
            })
        },

        /**
         * 头像上传验证
         */
        beforeAvatarUpload(file) {
            this.userImgLoading = true;
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.error("上传头像图片只能是 JPG或者PNG 格式!");
                this.userImgLoading = false;
            }

            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
                this.userImgLoading = false;
            }
            return (isJPG || isPNG) && isLt2M;
        },

        // 上传头像
        handleAvatarSuccess(res, file) {
            console.log(res)
            if (res.state == 200) {
                this.$message({
                    message: "上传成功！",
                    type: "success"
                });
                let userInfo = this.getUserInfo
                console.log(userInfo)
                userInfo.user_img = res.user_img
                this.$store.dispatch('setUserInfo', userInfo)
            }
            console.log(res)
            console.log(file)
        },

        // even(文本框内容)
        regExp(str) {
            // 规则对象(flag)
            var flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"),
                num = /[0-9]/;
            // 判断 even 是否包含特殊字符
            if(flag.test(str) || num.test(str)){
                console.log('a')
                return true
            } else {
                return false
            }
        },
    },

    watch: {
        getIsUserInfoAlert(newVal) {
            console.log(config)
            if (newVal && this.$util.isDefine(this.getUserInfo)) {
                this.initUserInfo()
            }
        }
    }
}
</script>

<style lang="scss">
.user-info-dialog {
    min-width: 870px;
    .el-dialog__body {
        padding-top: 0;
        .user-info-con {
            width: 706px;
            margin: 0 auto;
            padding: 70px 0 0;
            position: relative;

            .el-alert {
                position: absolute;
                top: 16px;
                text-align: left;
                border: 1px solid #d46f16;
                color: #d46f16;
                background-color: #ffe6e5;
            }

            .user-message {
                .input-item {
                    margin-bottom: 22px;
                    span.label {
                        width: 187px;
                        height: 40px;
                        line-height: 40px;
                        text-align: right;
                        padding-right: 8px;
                        display: block;
                        float: left;
                        color: #808080;
                    }

                    .el-input {
                        width: 334px;

                        .el-input__inner {
                            height: 40px;
                            line-height: 40px;
                        }
                    }

                    .sex-con {
                        width: 334px;


                    }
                }
            }

            .user-img {
                width: 100px;
                cursor: pointer;

                .el-upload {
                    text-align: center;
                    display: block;

                    .img {
                        width: 100px;
                        height: 100px;
                        border-radius: 10px;
                        border: 1px solid #dddddd;
                        background-color: #f4f4f4;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
            }
        }

        .save-button {
            margin: 0 auto;
            display: block;
            background-color: var(--el-color-primary);
            color: #fff;
        }
    }
}
</style>