<template>
 <div :class="['main-option ub ub-ver']">
     <QueTitle :title='title' title-type="mainOption"></QueTitle>
     <div class="main-con ub ub-ver ub-f1">
       <div class="quesition-option ub ub-f1">
         <el-scrollbar>
           <div class="container">
              <component class="que-option-body" :is='"queOption" + getQuesitionData.actQueData.questionType' v-if="getQuesitionData.actQueData.questionType"></component>
              <div class="quesition-analysis" v-if="getQuesitionData.pageType != 1">
                <div class="correct-score ub" v-if="getQuesitionData.pageType == 3">
                  <div class="title">【本题得分】：</div>
                  <el-input :class="[getQuesitionData.actQueData.questionScoreState ? 'err' : '']" v-model="getQuesitionData.actQueData.userQuestionSum" placeholder="请输入分数" :disabled="getQuesitionData.actQueData.useCorrectOrNot == 1"></el-input>
                </div>
                <div class="answer ub" v-if="(getQuesitionData.pageType == 2 || getQuesitionData.pageType == 3) && getQuesitionData.actQueData.questionType != 4"><div class="title">【正确答案】：</div><div :class="['chiose', this.getQuesitionData.actQueData.questionType == 3 ? getQueType3Answer() : '']">{{getAnswer()}}</div></div>
                <div class="analysis ub ub-ver">
                  <div class="ub analysis-header" @click="analysisSwitch">
                    <div class="title">【试题解析】：</div>
                    <div class="switch ub-f1">{{ isAnalysisSwitch ? '收起' : '展开' }}</div>
                  </div>
                  <p :class="isAnalysisSwitch ? 'open' : 'close'" v-html="getQuesitionData.actQueData.optionAnswerParse"></p></div>
              </div>
           </div>
         </el-scrollbar>
       </div>
       <div :class="['quesition-switch ub ub-pc', isLastQuesition ? 'last' : 'nolast']">
        <QueButton class="prev-btn" text="上一题" v-if="getQuesitionData.actQueData.serialNum != 1" @buttonClick="queSwitch('prev')"></QueButton>
        <div v-if="getQuesitionData.pageType == 1 || ((getQuesitionData.pageType != 1) && !isLastQuesition)">
          <QueButton class="next-btn" :isAct="true" :isButtonLoading="isButtonLoading" v-if="!isLastQuesition" text="下一题" @buttonClick="queSwitch('next')" ></QueButton>
          <QueSubmit v-else></QueSubmit>
        </div>
        <QueButton class="next-btn" :isAct="true" text="完成批阅" v-if="getQuesitionData.pageType == 3 && getQuesitionData.actQueData.questionType == 4 && isLastQuesition && getQuesitionData.actQueData.useCorrectOrNot != 1" @buttonClick="correctScore()"></QueButton>
       </div>
     </div>
 </div>
</template>

<script>
import { questionInfoConfigPost } from '../../../../http/api'
import QueTitle from './QueTitle.vue'
import QueButton from './QueButton.vue'
import QueOption1 from './queOption/QueOption1.vue'
import QueOption2 from './queOption/QueOption2.vue'
import QueOption3 from './queOption/QueOption3.vue'
import QueOption4 from './queOption/QueOption4.vue'
import QueSubmit from './QueSubmit.vue'
import { mapGetters } from 'vuex'
import queJs from '../../../../util/que'
const regPos = /^\d+(\.\d+)?$/; //非负浮点数
 export default {
   data () {
     return {
         title: '作答区',
         queOption: '',
         isAnalysisSwitch: false,
         isButtonLoading: false
     }
   },
   components: {
       QueTitle,
       QueButton,
       QueOption1,
       QueOption2,
       QueOption3,
       QueOption4,
       QueSubmit
   },

   created() {
    //  this.$store.dispatch('setUserCorrectOrNot')
   },

   mounted() {
    
   },

   computed: {
     ...mapGetters([
        'getQuesitionData'
        ]),

     isLastQuesition () {
       return this.getQuesitionData.actQueData.serialNum == this.getQuesitionData.quesitionInfoData.totalQuestionCount
     },


     getAnswer () {
       return function () {
         if (this.getQuesitionData.actQueData.questionType == 1) {
           return queJs.numToLetter(this.getQuesitionData.actQueData.optionAnswer - 1)
         } else if (this.getQuesitionData.actQueData.questionType == 3) {
           return ''
         } else {
           return this.getQuesitionData.actQueData.optionAnswer
         }
       }
     },

     getQueType3Answer () {
       return function () {
          return this.getQuesitionData.actQueData.optionAnswer == 1 ? 'iconfont icon-zhengque zhengque-img' : 'iconfont icon-cuowu cuowu-img'
       }
     }
   },

   methods: {
     /**
      * 上一题/下一题切换
      */
     queSwitch (type) {
       let actQueData = this.getQuesitionData.actQueData
       let quesitionData = this.getQuesitionData.quesitionData
       let queTypeIndex = quesitionData.findIndex(v=>v.questionType == actQueData.questionType)
       let queIndex = Number(actQueData.questionIndex)
       console.log(quesitionData)
       if (type == 'next') {
         if (queIndex == quesitionData[queTypeIndex].list.length -1) {
           if (queTypeIndex == quesitionData.length - 1) {
            //  http.submitPaper().then((res) => {
            //     console.log(res)
            //     this.isButtonLoading = true
            //     http.submitPaperRequest(res).then((res) => {
            //         this.isButtonLoading = false
            //         this.$message.success('提交成功');
            //         setTimeout(() => {
            //           this.$router.push({name: this.getQuesitionData.pageName})
            //         }, 1000)
            //     }).catch((err) => {
            //       this.isButtonLoading = false
            //     })
            //   }).catch((err) => {
            //     console.log(err)
            //   })
            return
           } else{
             queTypeIndex += 1
             queIndex = 0
           }
         } else {
           queIndex += 1
         }
       } else {
         if (queIndex == 0) {
           if (queTypeIndex == 0){
             alert('第一题')
             return
           } else {
             queTypeIndex -= 1
             queIndex = quesitionData[queTypeIndex].list.length - 1
           }
         } else {
           queIndex-=1
         }
       }

       this.$store.dispatch('setActQueData', quesitionData[queTypeIndex].list[queIndex]) 
       this.isAnalysisSwitch = false
       console.log(this.isAnalysisSwitch)
     },
     
    /**
      * 查看解析
      */
    analysisSwitch () {
      console.log(this.isAnalysisSwitch)
      this.isAnalysisSwitch = !this.isAnalysisSwitch
    },

    /**
     * 提交评分
     */
    correctScore () {
      console.log('bbb')
        let score = {}
        for (let i = 0; i < this.getQuesitionData.quesitionData.length; i++) {
          console.log(this.getQuesitionData.quesitionData[i])
          for (let j = 0; j < this.getQuesitionData.quesitionData[i].list.length; j++) {
            console.log('cccc')
            console.log(this.getQuesitionData.quesitionData[i].list[j].userQuestionSum)
            console.log(this.getQuesitionData.quesitionData[i].list[j].questionScoreState)
              if (this.getQuesitionData.quesitionData[i].list[j].userQuestionSum == '' || this.getQuesitionData.quesitionData[i].list[j].questionScoreState) {
                console.log('jjj')
                this.$message({
                  message: '请完成评分后在提交，或者检查评分是否正确！',
                  type: 'warning',
                  showClose: true
                });
                return 
              }
              score[this.getQuesitionData.quesitionData[i].list[j].questionId] = this.getQuesitionData.quesitionData[i].list[j].userQuestionSum
          }
        }
        
        this.correctQue(score)
        
    },

    /**
     * 批改作业
     */
    correctQue(score) {
      let formData = new FormData();
      //参数
      formData.append("type", 7);
      formData.append("score", JSON.stringify(score));
      formData.append("userId", this.getQuesitionData.uid);
      formData.append("examId", this.getQuesitionData.examId);
      formData.append("projectSign", 'frog');
      questionInfoConfigPost(formData).then(res => {
        console.log(res)
        this.$message({
            message: '提交成功',
            type: 'success',
            showClose: true
        });
        for (let i = 0; i < this.getQuesitionData.quesitionData.length; i++) {
          for (let j = 0; j < this.getQuesitionData.quesitionData[i].list.length; j++) {
            this.getQuesitionData.quesitionData[i].list[j].useCorrectOrNot = 1
          }
        }
        this.$store.dispatch('setUserCorrectOrNot')
      }).catch(err => {
          console.log(err)
      })
    }
   },

   watch: {
     'getQuesitionData.actQueData' () {
       this.isAnalysisSwitch = false
       /* setTimeout(() => {
          $('img').each(function(i) {
          console.log($(this))
          let imgWidth = $(this).width()
          console.log(imgWidth)
          $(this).css('width', `${imgWidth * 0.7}`)
        })
       }, 1000) */
     },

     'getQuesitionData.actQueData.userQuestionSum' (newVal, oldVal) {
       console.log(newVal, oldVal)
       if (regPos.test(newVal) &&  parseInt(newVal) > -1 && parseInt(newVal) <  parseInt(this.getQuesitionData.actQueData.questionScore) + 1) {
         this.getQuesitionData.actQueData.questionScoreState = false
       } else {
         this.getQuesitionData.actQueData.questionScoreState = true
       }
     }
   }
 }
</script>

<style lang="scss">
.main-option {
  
  .main-con {
    box-sizing: border-box;
    .quesition-option {

      .el-scrollbar {
        width: 100%;

        .el-scrollbar__wrap {
          overflow-x: hidden;

          .container {
            padding: 5px 15px 0;

            .que-option-body {
              opacity: 0;
            }

            .quesition-analysis {
              margin-top: 30px;
              line-height: 30px;

              .correct-score {
                min-width: 130px;
                line-height: 37px;
                margin-bottom: 5px;

                .title {
                  min-width: 130px;
                }

                .el-input {
                  input {
                    height: 37px;
                    line-height: 37px;
                    border-radius: 50px;
                  }
                }

                .el-input.err {
                    input {
                      border-color: red;
                    }
                }
              }
              
              .answer {
                margin: 10px 0;
                line-height: 32px;

                .chiose {
                  color: var(--primary-bgc);
                  font-size: 24px;
                  font-weight: bold;
                }

                .chiose.iconfont {
                  font-weight: normal;
                }

                .chiose.icon-zhengque {
                  color: #1FAB2E;
                }

                .chiose.icon-cuowu {
                  color: #E70012;
                }
              }

              .analysis {
                .analysis-header {
                  cursor: pointer;
                  .switch {
                    color: var(--el-color-primary);
                    font-weight: 700;
                    text-align: right;
                    -moz-user-select: none; /*火狐*/
                    -webkit-user-select: none; /*webkit浏览器*/
                    -ms-user-select: none; /*IE10*/
                    -khtml-user-select: none; /*早期浏览器*/
                    user-select: none;
                  }
                }

                p {
                  margin: 5px 0;
                  transition: all 0.2s;
                }

                p.close {
                  height: 0;
                  opacity: 0;
                  overflow: hidden;
                }

                p.open {
                  height: auto;
                }
              }
            }

            img {
              max-width: 100%;
            }
          }
        }
      }
    }

    .quesition-switch {
      margin-top: 30px;
      margin-bottom: 32px;
      .prev-btn {
        margin-right: 34px;
      }
    }
  }
}

/* .main-option.GIE {
    .main-con {

      .quesition-option {

        .el-scrollbar {

          .el-scrollbar__wrap {
            img {
              max-width: 100%;
              max-height: inherit;
            }
          }
        }
      }
  }
} */
 
</style>
