//上课ppt
<template>
    <div class="class_ppt">
        <!-- 内容布局 -->
        <div class="content" v-if="!showLoading">
            <!-- 左边的索引列表 -->
            <div class="left_list" v-if="false">
                <div :class="['item_list', { select_bg: activePos == index }]" v-for="(item, index) in pptList" :key="index" @click.stop="changePpt(item, index)">
                    <img class="video_img" :src="item.pptCover" />
                    <div class="video_name">{{ item.pptName }}</div>
                </div>
            </div>

            <!-- 右边的ppt展示 -->
            <div class="right_content" id="iframe_ppt">

                <iframe id="if_cs_ppt" class="show_ppt" :src="pptAddress" frameborder="0"></iframe>

                <!-- 放大缩小的按钮 -->
                <div class="bom_scale iconfont" :class="[isScale ? 'icon-tuichuquanping' : 'icon-quanping']" @click.stop="doScaleAction"></div>
            </div>

        </div>
        <!-- 加载框 -->
        <Loading v-if="showLoading"></Loading>
    </div>
</template>
<script>
//导入Ppt排序的问题
import { curriculumController, pfrResourcesController } from '../../../http/api'
import { pptArrSort } from "../../../util/Arr";
//引入混入文件
import screen from "../../../minxin/screen"
export default {
    name: "ClassPpt",
    mixins: [screen],
    data() {
        return {
            //左边的PPT播放列表
            pptList: [],
            //每个小节对应的PPT列表
            pptArr: [],
            //左边列表选中的位置
            activePos: 0,
            //底部显示的页面位置
            currPos: 0,
            //加载框
            showLoading: false,
            //初始状态激活的幻灯片的索引，从 0 开始
            currPage: 0,
            //ppt的加载地址
            pptAddress: "",
            //放大或者缩小的标识
            isScale: false,
        };
    },
    created() {
        //请求数据
        // this.httpData();
    },

    mounted() {
        document.getElementById("if_cs_ppt").contentDocument.oncontextmenu =
            function () {
                return false;
            };

        //监听ESC事件
        window.onresize = () => {
            if (this.isFullscreen() == null) {
                //缩小状态
                this.isScale = false;
            } else {
                //放大状态
                this.isScale = true;
            }
        };
        
        console.log(this.mode)
        console.log(this.id)
        console.log(this.type)
        
        this.getPptInfo()
    },
    methods: {
        /**
        * 获取ppt数据
        */
        getPptInfo () {
            let data = {
                type: this.mode,
                sectionId: this.type
            }
            let request = null
            if (this.mode == 1) {
                data.pfrId = this.id
                request = pfrResourcesController(data)
            } else {
                data.mainId = this.mainId
                data.curriculumId = this.curriculumId
                request= curriculumController(data)
            }
            request.then((res) => {
                console.log(res)
                this.pptAddress = this.mode == 1 ? res.data[0].resourcesUrl : res.data[0].curriculumUrl;
            }).catch(() => {
                
            })
        },

        //幻灯片改变的方法
        change(pos) {
            this.currPos = pos;
        },
        //左边列表条目的点击事件
        changePpt(item, index) {
            if (this.activePos != index) {
                this.activePos = index;
                //在同步一下右边列表的数据
                this.pptArr = pptArrSort(item.list);
                //同步底部的页码显示
                this.currPos = 0;
            }
        },

        //全屏的方法
        showFullAction() {
            let iframe = document.getElementById("iframe_ppt");
            this.requestFullScreen(iframe);
        },

        /**
         * 点击全屏和缩小的操作
         */
        doScaleAction() {
            if (this.isScale) {
                //缩小
                this.exitFullscreen();
            } else {
                //放大
                this.showFullAction();
            }
            this.isScale = !this.isScale;
        },
    },
    props: ["mode", "id", "type", "curriculumId", "mainId"],
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.class_ppt {
    background: black;
    display: flex;
    align-items: center;
    flex: 1;
    .content {
        width: 100%;
        margin: 0 auto;
        display: flex;
        height: calc(100vh - 70px);
        //左边的索引List部分
        .left_list {
            margin-right: 16px;
            height: 100%;
            background: rgba($color: #abacae, $alpha: 0.2);
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            width: 220px;
            overflow-x: hidden;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            .item_list {
                height: 68px;
                padding: 16px 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                //视频图片
                .video_img {
                    margin-left: 14px;
                    width: 58px;
                    height: 58px;
                    border-radius: 2px;
                    object-fit: cover;
                }
                //视频名称
                .video_name {
                    color: white;
                    flex: 1;
                    margin: 0 14px;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .item_list:hover {
                // background: rgba($color: #000000, $alpha: 0.4);
                background: rgba($color: white, $alpha: 0.4);
            }

            //选中的背景
            .select_bg {
                // background: rgba($color: #000000, $alpha: 0.4);
                background: rgba($color: white, $alpha: 0.4);
            }
        }

        // 右边的展示PPT列表部分
        .right_content {
            flex: 1;
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;

            #pdf_show_content {
                overflow: hidden;
            }

            .bom_scale {
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
                color: white;
                // background: #83e1ff;
                background: rgba($color: var(--primary-bgc), $alpha: 0.4);
                padding: 4px;
                font-size: 16px;
                border-radius: 2px;
            }
            .show_ppt {
                flex: 1;
                border-radius: 2px;
                height: 100%;
            }
        }
    }
}
</style>
