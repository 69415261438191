<template>
 <div class="selet-list">
   <ul :class="['ub ub-wrap', getQuesitionData.pageType != 1 ? 'analysis' : '']">
     <el-tooltip class="item" effect="dark" :content="tooltip(item)" placement="top-start" 
          v-for="(item, index) in actQueList.list" 
          :key="index"
          :disabled="showTooltip(item)">
        <el-button 
          size="small" 
          :class="[getQuesitionData.actQueData.questionType == item.questionType && getQuesitionData.actQueData.questionIndex == index ? 'act' : '', 
                    getQuesitionData.pageType == 1 && item.userAnswer ? 'done' : '', isRight(item)]"
          :style="(index + 1)%5 == 0? 'margin-right: 0': ''" 
          @click="queIndexBtn(item)">{{item.serialNum}}
        </el-button>
      </el-tooltip>
   </ul>
 </div>
</template>

<script>
import { mapGetters } from 'vuex'

 export default {
   props: ['actQueList'],
   data () {
     return {

     }
   },
   components: {
     
   },

   computed: {
     ...mapGetters([
        'getQuesitionData'
      ]),

     isRight () {
       return function (item) {
         if (this.getQuesitionData.pageType == 1 ||  this.getQuesitionData.pageType == 3) {
            return ''
         } else if (this.getQuesitionData.pageType == 2){
            return item.questionType == 4 ? item.useCorrectOrNot == 1 ? '' : 'not-reviewed' : item.userQuestionIsRight == 1 ? 'right' : 'err' 
         } else if (this.getQuesitionData.pageType == 4) {
            return item.questionType == 4 ? 'not-reviewed' : item.correctRate * 100 == 100 ? 'right' : 'err'
         }
       }
     },

     showTooltip () {
        return function () {
          return this.getQuesitionData.role == 2 && this.getQuesitionData.pageType == 4 ? false : true
        }
     },

     tooltip () {
       return function (item) {
         
         return item.questionType != 4 ? `正确率：${item.correctRate * 100}%，答题：${item.answerNumber}人，回答正确：${item.AnswerCorrectNumber}人` : `作答：${item.answerNumber}人，平均得分：${item.totalScore}分`
       }
     }
   },

   created () {
   },

   methods: {
     queIndexBtn (item) {
       this.$store.dispatch('setActQueData', item)
     }
   }
 }
</script>

<style lang="scss" scoped>
.selet-list {
  text-align: left;

  .el-button {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    margin-right: 8px;
    margin-left: 0;
    margin-bottom: 9px;
    font-size: 16px;
    color: #606266 !important;
  }

  .el-button.done {
    background-color: var(--el-color-primary);
    border-color: #fff;
    color: #fff !important;
  }
  
  .el-button.act {
    background-color: inherit;
    border-color: var(--el-color-primary);
    color: #000 !important;
  }

  .el-button.err {
    background-color: #fe6f71;
    border-color: #fe6f71;
    color: #fff !important;
  }

  .el-button.right {
    background-color: #22ac38;
    border-color: #22ac38;
    color: #fff !important;
  }

  .el-button.not-reviewed {
    background-color: #c0c0c0;
    border-color: #c0c0c0;
    color: #fff !important;
  }

  .analysis .el-button.act {
    font-weight: bold;
    font-size: 20px;
  }
}
 
</style>
