<template>
  <div class="scratch-modle ub-f1" v-if="iframeSrc">
      <iframe ref="scratch3Iframe" id='scratch3Id' class="scratch3" :src="iframeSrc" frameborder="0" ></iframe>
  </div>
</template>

<script>
import { curriculumController, pfrResourcesController } from '../../../http/api'

export default {
  props: ["mode", "id", "type", "curriculumId", "mainId"],
  data() {
    return {
      iframeSrc: '1'
    }
  },

  mounted() {
    console.log('aaa')
    this.getScratchInfo()
  },

  methods: {
    /**
      * 获取ppt数据
      */
      getScratchInfo () {
          let data = {
                type: this.mode,
                sectionId: this.type
            };
            let request = null;
            if (this.mode == 1) {
                data.pfrId = this.id
                request = pfrResourcesController(data)
            } else {
                data.mainId = this.mainId
                data.curriculumId = this.curriculumId
                request= curriculumController(data)
            }
            request.then((res) => {
                console.log(res)
                this.iframeSrc = `https://pbl.zdw000.com/scratch3.0#${res.data[0].projectId}?isTk=true`
                // this.pdfUrl = this.mode == 1 ? res.data.data[0].resourcesUrl : res.data.data[0].curriculumUrl;
                // this.getNumPages();
            }).catch(() => {
                
            })
      },
  }, 

  watch: {
    type(nv) {
      console.log('nv', nv)
      this.iframeSrc = ''
      this.getScratchInfo()
    }
  }
}
</script>

<style lang="scss">
.scratch-modle {
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.scratch-modle::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3rem;
  background-color: hsla(49, 100.0%, 50.20%,1);
}
</style>