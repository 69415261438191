<template>
  <div :class="['student-evaluatel-phone', isPhone ? 'phone' : '']">
      <div class="content">
          <img class="cxw-logo" src="../../assets/image/footer_logo.png" alt="" />
          <img class="cxw-banner" src="../../assets/image/progress/head_img.png" alt="" />
          <div class="cxw-module">
              <div class="cxw-module-title ub ub-ac">
                  <img v-if="learnData.student_headimg" class="user-img" :src="learnData.student_headimg" alt="">{{learnData.student_name}}
              </div>
              <div class="cxw-module-content">
                <div class="learn-item ub" v-for="(item, index) in learn" :key="index">
                    <div class="learn-item-title ub ub-ac ub-start" :class="`learn-item-title${index + 1}`"><span :style="{backgroundImage:'url(' + item.bgi +')'}"></span>{{item.name}}</div>
                    <div class="learn-item-content ub-f1-item" v-if="index == 0 || index == 1">{{item.content}}</div>
                    <div class="learn-item-content ub-f1-item" v-else v-html="item.content"></div>
                </div>
              </div>
          </div>
          <div class="cxw-chart" v-if="learnData.student_headimg" id="cxw-chart"></div>
          <div class="cxw-module cxw-module2">
              <div class="cxw-module-title ub ub-ac">指导老师评语</div>
              <div class="cxw-module-content">{{learnData.comment}}</div>
          </div>
          <div class="cxw-module cxw-module3">
              <div class="cxw-module-title ub ub-ac">指标等级说明</div>
              <div class="cxw-module-content">
                  <div class="lever-item ub ub-ver" v-for="(item, index) in lever" :key="index">
                    <div class="lever-title" :style="'background-color:' + item.gradeBgc + ''"><span class="index">{{(index + 1) + '分'}}</span><span>{{item.gradeName}}</span></div>
                    <div class="lever-content">{{item.content}}</div>
                  </div>
              </div>
          </div>
          <button class="cxw-button" @click="showPhone">免费领取试听课</button>
          
          <div class="cxw-qrcode ub ub-ver ub-ac" v-if="learnData.school_qrcode">
              <div class="qrcode-img ub ub-ac ub-pc">
                <el-image :src="learnData.school_qrcode" fit="cover"></el-image>
              </div>
              <div class="qrcode-text">{{isPhone ? '长按' : '扫码'}}识别二维码，联系老师入校体验</div>
          </div>
          <div :class='["cxw-footer ub ub-pc ub-ac", isPhone ? "" : ""]' v-if="learnData.school_name">
              <div :class='["school-info ub ub-ver ub-f1"]'>
                  <div class="school-name">{{learnData.school_name}}</div>
                  <div class="school-message">地址：{{learnData.address}}</div>
                  <div class="school-message">电话：{{learnData.school_tel}}</div>
              </div>
                <el-image class="footer-logo"  :src="learnData.school_logo" fit="cover"></el-image>
          </div>
      </div>
  </div>
</template>

<script>
import { versionControlle } from '../../http/api'
import { getCurrentInstance } from 'vue';
export default {
    data() {
        return {
            isPhone: false,
            chartData: [0, 0, 0, 0, 0],
            learnData: {},
            learn: [{
                name: '指导老师',
                bgi: require('../../assets/image/progress/progress_teacher.png'),
                content: ''
            }, {
                name: '学习时间',
                bgi: require('../../assets/image/progress/progress_time.png'),
                content: ''
            }, {
                name: '学习内容',
                bgi: require('../../assets/image/progress/progress_con.png'),
                content: ''
            }, {
                name: '学习心得',
                bgi: require('../../assets/image/progress/progress_get.png'),
                content: ''
            }],
            lever: [{
                gradeName: '初步展现',
                gradeBgc: '#feb25e',
                content: '学生在该方面的特质或能力上有初步的展现，能够胜任一般的编程任务，需要进一步开发潜能。'
            }, {
                gradeName: '正在发展',
                gradeBgc: '#ff9d38',
                content: '学生在该方面的特质或能力上表现较好，能够跟着老师完成稍有难度的编程任务。'
            }, {
                gradeName: '表现良好',
                gradeBgc: '#fc9316',
                content: '学生在该方面的特质或能力上有良好的表现，能够经老师少量指点触类旁通，完成编程任务。'
            }, {
                gradeName: '比较突出',
                gradeBgc: '#ff7900',
                content: '学生在该方面的特质或能力上比较突出，有出众的能力基础，可以在编程中自我发挥。'
                
            }, {
                gradeName: '非常优秀',
                gradeBgc: '#ff6100',
                content: '学生在该方面的特质或能力上异常优秀，能够运用课上所学编程知识，灵活创造、高效运行，是编程不可多得的苗子。'
            }]
        }
    },
    created() {
        this.isPhone = this.isMobile()
        console.log(this.isMobile())
        let urlParams = this.$util.getRequest()
        if (!this.$util.isDefine(urlParams) || !this.$util.isDefine(urlParams.row_id)) {
            this.$message.error('参数异常');
            return
        }

        this.getData(urlParams.row_id, urlParams.platform)
    },

    mounted() {
        window.onresize = () => {
            
        }
    },

    methods: {
        getData(rowid, platform) {
            versionControlle({
                        type: 2,
                        row_id: rowid,
                        platform: platform ? platform : '' 
                    })
                .then((res) => {
                    console.log(res)
                    let data = res
                    this.learn[0].content = data.teacher_name
                    this.learn[1].content = data.study_time + '-' + data.study_time2.substring(11,16)
                    this.learn[2].content = data.study_content.replace(/\n/g,"<br/>");
                    this.learn[3].content = data.study_income.replace(/\n/g,"<br/>");
                    this.thinking,this.decompose,this.understand,this.interest,this.imagine
                    this.chartData[0] = Number(data.thinking)
                    this.chartData[1] = Number(data.decompose)
                    this.chartData[2] = data.understand
                    this.chartData[3] = data.interest
                    this.chartData[4] = data.imagine
                    console.log(this.chartData)
                    this.learnData = data
                    console.log(res)
                    setTimeout(() => {
                        this.drawPie()
                    }, 500)
                })
                .catch(() => {});
            /* this.axios.get(this.GLOBAL.axiosData.wfUrl + 'Learning',{
                    params: {
                        type: 2,
                        c: '',
                        row_id: rowid,
                        token: '',
                    },
                    timeout: this.GLOBAL.axiosData.timeOut
                })
                .then((res) => {
                    let data = res.data
                    this.learn[0].content = data.teacher_name
                    this.learn[1].content = data.study_time + '-' + data.study_time2.substring(11,16)
                    this.learn[2].content = data.study_content.replace(/\n/g,"<br/>");
                    this.learn[3].content = data.study_income.replace(/\n/g,"<br/>");
                    this.thinking,this.decompose,this.understand,this.interest,this.imagine
                    this.chartData[0] = Number(data.thinking)
                    this.chartData[1] = Number(data.decompose)
                    this.chartData[2] = data.understand
                    this.chartData[3] = data.interest
                    this.chartData[4] = data.imagine
                    console.log(this.chartData)
                    this.learnData = data
                    console.log(res)
                    setTimeout(() => {
                        this.drawPie()
                    }, 500)
                }).catch((err) => {
                    console.log(err)
                }) */
        },

        showPhone() {
            this.$alert(`${this.learnData.school_contacts ? this.learnData.school_contacts + ':' + this.learnData.school_tel : ''}`, '招生负责人联系方式', {
                confirmButtonText: '立拨打电话',
                customClass: `evaluatelAlert ${this.isPhone ? 'phone' : ''}`,
                callback: action => {
                    if (action == 'confirm') {
                        if (!this.$util.isDefine(this.learnData.school_tel)) {
                            this.$message({
                                message: '暂无手机号',
                                type: 'warning'
                            });
                            return
                        }
                        window.location.href = 'tel://'+ this.learnData.school_tel
                    }
                }
            });
        },

        drawPie(){
            // let internalInstance = getCurrentInstance();
            // console.log(internalInstance)
            // let echarts = internalInstance.appContext.config.globalProperties.$echarts;
            console.log(this.$echarts)
            let charts = this.$echarts.init(document.getElementById('cxw-chart'));
            let option = {
                title: {
                    target: "blank",
                    textAlign: 'left',

                },
                tooltip: {},//提示层
                series: [{
                    name: '学情分析',
                    type: 'radar',
                    data: [{
                        value: this.chartData,
                        label:{
                            normal:{
                                show:true,
                                textStyle: {
                                    color: 'black',// 工艺等文字颜色
                                    fontSize:'14',

                                },
                                formatter:function(params){
                                    return params.value;
                                }
                            },
                        },
                    }],
                    itemStyle:{
                        normal:{
                            color:'#ffc62d',
                            lineStyle:{
                                color:'#f76d10',
                            },
                        },
                    },
                }],
                radar: {
                    name: {
                        textStyle: {
                            color: '#fff', //字体颜色
                            borderRadius: 1, //圆角
                            padding: [3, 5], //padding
                            fontSize: this.isPhone ? '12' : '16',//字体大小
                        }
                    },
                    axisLabel:{
                        show:false,
                        color:'black',
                        fontSize:'22px'
                    },
                    axisLine:{
                        show:true,
                        lineStyle:{
                            // color:'#00b89c'
                        },
                    },
                    splitLine:{
                        lineStyle:{
                            color:'#bfbfbf'
                        }
                    },
                    splitArea:{
                        areaStyle:{
                            color:'#FFF'
                        }
                    },
                    radius: '70%',
                    indicator: [{
                            name: '逻辑思维能力',
                            max: 5,
                            axisLabel:{show:false}
                        },
                        {
                            name: '任务分解能力',
                            max: 5,
                            axisLabel:{show:false}
                        },
                        {
                            name: '指令理解能力',
                            max: 5,
                            axisLabel:{show:false}
                        },
                        {
                            name: '学习兴趣与主动性',
                            max: 5,
                            axisLabel:{show:false}
                        },
                        {
                            name: '创造想象力',
                            max: 5,
                            axisLabel:{show:false}
                        }
                    ],
                },
            }
            charts.setOption(option);
        },

        isMobile() {
            var userAgentInfo = navigator.userAgent;
            var mobileAgents = [ "Android", "iPhone", "SymbianOS", "Windows Phone", "iPad","iPod"];
            var mobile_flag = false;
            //根据userAgent判断是否是手机
            for (var v = 0; v < mobileAgents.length; v++) {
                if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                    mobile_flag = true;
                    break;
                }
            }
            var screen_width = window.screen.width;
            var screen_height = window.screen.height;   
            //根据屏幕分辨率判断是否是手机
            if(screen_width < 500 && screen_height < 800){
                mobile_flag = true;
            }
            return mobile_flag;
        }
    }
}
</script>

<style lang="scss">
.student-evaluatel-phone {
    width: 100%;
    background-color: #00b89c;
    text-align: left;

    .content {
        width: 1190px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 70px;

        .cxw-logo {
            width: 225px;
        }

        .cxw-banner {
            width: 1109px;
            margin: 80px auto 114px;
            display: block;
        }

        .cxw-chart {
            height: 600px;
        }

        .cxw-module {
            width: 1190px;
            margin: 0 auto 70px;
            border-radius: 30px;
            overflow: hidden;
            .cxw-module-title {
                height: 138px;
                background-color: #fff3c3;
                padding: 0 70px;
                font-size: 34px;
                font-weight: bold;

                .user-img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    border: 3px solid #fff;
                    margin-right: 37px;
                }
            }

            .cxw-module-content {
                background-color: #fff;
                min-height: 300px;
                position: relative;
                padding: 45px 75px 130px;
                font-size: 22px;
                line-height: 44px;

                .learn-item {
                    margin-bottom: 40px;
                    .learn-item-title {
                        width: 210px;
                        font-weight: bold;
                        color: #01b89c;
                        text-align: right;
                        position: relative;
                        margin-right: 64px;
                        align-items: flex-start;
                        span {
                            width: 57px;
                            height: 49px;
                            display: block;
                            margin-right: 36px;
                            background-repeat: no-repeat;
                            background-position: center center;
                        }
                    }
                }

                .lever-item {
                    .lever-title {
                        width: 190px;
                        height: 44px;
                        text-align: center;
                        color: #fff;
                        border-radius: 30px;
                        margin: 14px 0;

                        span.index {
                            margin-right: 14px;
                        }
                    }
                }
            }

            .cxw-module-content::before {
                content: '';
                width: 100%;
                height: 120px;
                background-image: url("../../assets/image/progress/progress_bl.png");
                background-size: 100% 100%;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        .cxw-module2, .cxw-module3 {
            .cxw-module-title {
                padding-left: 209px;
                position: relative;
            }

            .cxw-module-title::before {
                content: '';
                position: absolute;
                left: 82px;
                bottom: 0;
            }
        }

        .cxw-module2 {
            .cxw-module-title::before {
                width: 88px;
                height: 129px;
                background-image: url("../../assets/image/progress/progress_teach.png");
            }
        }

        .cxw-module3 {
            .cxw-module-title::before {
                width: 143px;
                height: 102px;
                left: 48px;
                top: 17px;
                background-image: url("../../assets/image/progress/progress_book.png");
            }
        }

        .cxw-button {
            width: 100%;
            height: 95px;
            background-color: #ffc62e;
            border-radius: 50px;
            text-align: center;
            border: 0px;
            font-size: 28px;
            margin-bottom: 70px;
            letter-spacing: 5px;
            color: #000000;
        }

        .cxw-button:active {
            background-color: #fab804;
        }

        .cxw-qrcode {
            margin-bottom: 70px;
            .qrcode-img {
                width: 378px;
                height: 378px;
                background-color: #fff;
                border-radius: 30px;
                overflow: hidden;
                .el-image {
                    width: 100%;
                    height: 100%;
                }
            }

            .qrcode-text {
                font-size: 28px;
                color: #fff;
                margin-top: 30px;
            }
        }

        .cxw-footer {
            .school-info {
                color: #fff;

                .school-name {
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 14px;
                }

                .school-message {
                    font-size: 22px;
                    margin-bottom: 5px;
                }
            }

            .footer-logo {
                display: block;
                max-width: 200px;
            }
        }
    }
}

.evaluatelAlert {
    border: 0px !important;
    border-radius: 20px;
    .el-message-box__header {
        background-color: #01b89c;
        text-align: center;

        span {
            color: #ffffff;
            font-weight: bold;
            font-size: 20px;
        }
    }

    .el-message-box__headerbtn {
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border-radius: 50%;
        top: 9px;
        right: 9px;
        .el-icon-close {
            font-size: 24px;
            font-weight: bold;
            margin-top: 3px;
        }

        .el-icon-close::before {
            color: #909399 !important;
        }
    }

    .el-message-box__content {
        min-height: 100px;
        display: flex;
        flex-direction: row;
        position: relative;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
	    justify-content: center;

        .el-message-box__container {
            font-size: 18px;
        }
    }
}

.student-evaluatel-phone.phone {
    padding: 0 10px;
    box-sizing: border-box;
    .content {
        width: 100%;
        padding-bottom: 20px;
        .cxw-logo {
            width: 170px;
        }

        .cxw-banner {
            width: 100%;
            margin: 20px auto 40px
        }

        .cxw-chart {
            height: 300px;
        }

        .cxw-module {
            width: 100%;
            margin-bottom: 25px;
            .cxw-module-title {
                padding: 0 15px;
                height: 60px;
                font-size: 18px;
                .user-img {
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }
            }
            
            .cxw-module-content {
                padding: 10px 10px 60px;
                font-size: 16px;
                min-height: 100px;
                line-height: 28px;
                
                .learn-item {
                    margin-bottom: 15px;
                    .learn-item-title {
                        width: 105px;
                        margin-right: 15px;
                         span {
                             width: 30px;
                             height: 26px;
                             background-size: 100% 100%;
                             margin-right: 10px;
                         }
                    }
                }

                .lever-title {
                    width: 150px;
                    height: 36px;
                    span {
                        line-height: 36px;
                    }
                }
            }

            .cxw-module-content::before {
                height: 60px;
            }
        }

        .cxw-module2, .cxw-module3 {
            .cxw-module-title {
                padding-left: 80px;
            }
        }

        .cxw-module2 {
            .cxw-module-title::before {
                width: 40px;
                height: 59px;
                left: 20px;
                background-size: 100%;
            }
        }

        .cxw-module3 {
            .cxw-module-title::before {
                width: 50px;
                height: 36px;
                left: 15px;
                top: 12px;
                background-size: 100%;
            }
        }

        .cxw-button {
            height: 56px;
            font-size: 22px;
            margin-bottom: 40px;
        }

        .cxw-qrcode {
            margin-bottom: 40px;

            .qrcode-img {
                width: 200px;
                height: 200px;
                img {
                    width: 100%;
                }
            }

            .qrcode-text {
                font-size: 16px;
                margin-top: 10px;
            }
        }

        .school-info {
            .school-name {
                font-size: 20px;
                margin-bottom: 10px;
            }
            
            .school-message {
                font-size: 16px;
            }
        }

        .footer-logo {
            max-width: 100px;
            flex-shrink: 0;
            margin-left: 10px;
        }
    }
}

.evaluatelAlert.phone {
    width: 320px;
    border-radius: 10px;
    .el-message-box__header {
        span {
            color: #ffffff;
            font-weight: bold;
            font-size: 18px;
        }
    }

    .el-message-box__headerbtn {
        width: 25px;
        height: 25px;
        i {
            font-size: 20px;
        }
    }

    .el-message-box__content {
        min-height: 50px;
    }
}
</style>