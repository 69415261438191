<template>
 <div :class="['main container ub ub-ver reload']">
     <!-- 头部 -->
     <div class="main-header ub " v-if="queData">
         <div class="ub" v-if='getQuesitionData.pageType == 1'>
             <div>{{queData.subName}}</div>
         </div>
         <el-breadcrumb separator-class="el-icon-arrow-right" class="ub-f1" v-else>
            <el-breadcrumb-item :to="{ path: '/ModelTest' }">模考中心</el-breadcrumb-item>
            <el-breadcrumb-item>{{getBreadcrumb}}</el-breadcrumb-item>
         </el-breadcrumb> 
         <div class="ub-f1 paper-name">{{getQuesitionData.examName}}</div>
         <div class="paper-time student" v-if='getQuesitionData.pageType == 4' @click="showStudents()"><span>作答学生</span></div>
         <div class="paper-time student" v-else-if='getQuesitionData.pageType == 3'></div>
         <div class="paper-time" v-else>考试用时：<span>{{hours}}：{{min}}：{{sec}}</span></div>
     </div>
     <div class="main-header ub " v-else>
         <el-breadcrumb separator-class="el-icon-arrow-right" class="ub-f1">
            <el-breadcrumb-item :to="{ path: '/ModelTest' }">模考中心</el-breadcrumb-item>
            <el-breadcrumb-item>{{getBreadcrumb}}</el-breadcrumb-item>
         </el-breadcrumb> 
     </div>
     <!-- 头部 end -->
     <div class="main-body ub ub-f1">
        <QueInfoArea class="main-left" :queData="queData" v-if="queData"></QueInfoArea>
        <div class="ub-f1" v-if="queData">
            <el-scrollbar style="height:100%" ref="scrollbar" class="content-scrollBar">
                <div class="ub ub-f1" style="height: 100%">
                    <QueStemArea class="main-center"></QueStemArea>
                    <QueOptionArea class="main-right"></QueOptionArea>
                </div>
            </el-scrollbar>
        </div>
        <el-empty :description="isGetDataErrTip" v-if="isGetDataErr"></el-empty>
        <!-- <Loading v-if="isGetDataLoading" :isLoading="isLeverLoading" loadText="信息加载中..."></Loading> -->
     </div>

     <el-dialog
        title="提示"
        custom-class="default-dialog big-img-dialog"
        :model-value="bigImgDialogVisible"
        :before-close="handleClose">
        <img :src="bigImgSrc" alt="">
    </el-dialog>
 </div>
</template>

<script>
import { questionInfoConfig } from '../../../../http/api'
import { mapGetters } from 'vuex'
 import QueInfoArea from './QueInfoArea.vue'
 import QueStemArea from './QueStemArea.vue'
 import QueOptionArea from './QueOptionArea.vue'
 import queJs from '../../../../util/que'
 import $ from 'jquery'
 export default {
    props: ['paperParams'],
   data () {
     return {
         newPaperParams: '',
         bigImgDialogVisible: false,
         bigImgSrc: '',
         queData: null,
         timerInit: null,
         currentTime: 0,
         hours: '00',
         min: '00',
         sec: '00',
         isGetDataLoading: false,
         isGetDataErr: false,
         isGetDataErrTip: ''
     }
   },
   components: {
       QueInfoArea,
       QueStemArea,
       QueOptionArea
   },

   computed: {
    ...mapGetters([
      'getUserInfo',
      'getQuesitionData'
    ]),

    getBreadcrumb () {
        return queJs.getBreadcrumbName(this.getQuesitionData.pageType)
    }  
   },

    created () {
        console.log(this)
        /* this.paperParams = {
            examId: "20426",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 1,
            classId: 1800314,
            uid: 2000002345,
            role: '1'
        } */
        /* this.paperParams = {
            examId: "10102",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 2,
            classId: 1800311,
            uid: 366,
            role: '1'
        } */
        /* this.paperParams = {
            examId: "10102",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 4,
            classId: 1800311,
            uid: 2000002345,
            role: '1'
        } */
        console.log(this.paperParams)
        if (this.paperParams.examId) {
            this.newPaperParams = this.paperParams
            sessionStorage.setItem('newPaperParams', JSON.stringify(this.paperParams))
            // queState.setPaperParams(this.paperParams)
        } else {
            console.log(sessionStorage.getItem('newPaperParams'))
            this.newPaperParams = JSON.parse(sessionStorage.getItem('newPaperParams'))
        }
        // queState.setPaperParams(this.newPaperParams)
        this.$store.dispatch('setPaperParams', this.newPaperParams)
        console.log(this.getQuesitionData.pageType)
        this.getData()
    },

    mounted () {
        this.listenPage()

        window.addEventListener('click', e => {
            if (e.target.localName == 'img' && e.target.currentSrc.indexOf('https://pbl.zdw000.com/') != -1) {
                this.bigImgSrc = e.target.currentSrc
                this.bigImgDialogVisible = true
            }
         },  false);
    },

    unmounted () {
        clearInterval(this.timerInit);
        window.onbeforeunload = null
    },

    methods: {
        getData () {
            let params = {}
            params.type = this.getQuesitionData.pageType
            params.examId =  this.getQuesitionData.examId
            localStorage.removeItem('isReload')
            if (this.getQuesitionData.pageType == 1) { // 做题
                localStorage.setItem('isReload', true)
            } else if (this.getQuesitionData.pageType == 2) { // 解析
                params.type = 3,
                params.userId = this.getQuesitionData.uid,
                params.projectSign = 'frog'
                params.classId = this.getQuesitionData.classId
            } else if (this.getQuesitionData.pageType == 3) {
                params.type = 5,
                params.classId = this.getQuesitionData.classId,
                params.projectSign = 'frog'
            } else if (this.getQuesitionData.pageType == 4) {
                params.classId = this.getQuesitionData.classId,
                params.projectSign = 'frog'
            }
            questionInfoConfig(params).then(res => {
                console.log(res)
                let data = res.data
                this.queData = data
                console.log(data)
                // queState.setQueInfoData(data)
                this.$store.dispatch('setQueInfoData', data)
                let quesitionData = []
                for (let i in data) {
                    if (i.indexOf('QuestionData') != -1) {
                        quesitionData.push(data[i])
                    }
                }
                quesitionData.sort(this.soryBy('questionType'))
                // queState.setActQueData()
                this.$store.dispatch('setActQueData', {})
                for (let i = 0; i < quesitionData.length; i++) {
                    for (let j = 0; j < quesitionData[i].list.length; j++) {
                        if (!this.getQuesitionData.actQueData.questionType) {
                            console.log(quesitionData[i].list[j])
                            this.$store.dispatch('setActQueData', quesitionData[i].list[j])
                            // queState.setActgetQuesitionData(quesitionData[i].list[j])
                        }
                    }
                }
                console.log(quesitionData)
                this.$store.dispatch('setQueData', quesitionData)
                // queState.setgetQuesitionData(quesitionData)
                if (this.getQuesitionData.pageType == 1) {
                    this.timeStart()
                } else if (this.getQuesitionData.pageType == 2) {
                    this.currentTime = data.answerTime
                    this.timetoStr()
                } else if (this.getQuesitionData.pageType == 3) {
                    this.$store.dispatch('setUserCorrectOrNot')
                }
       console.log(this)
            }).catch((err) => {
                console.log(err)
                this.isGetDataErr = true
                this.isGetDataErrTip = err && err.data.msg ? err.data.msg : '加载失败！'
            })
        },

        soryBy () {
            return function (a, b) {
                return a.questionType - b.questionType
            }
        },

        timeStart () {
            this.timerInit = setInterval(this.timers, 1000);
        },

        timers () {
            this.timetoStr()
            this.currentTime++;
            this.$store.dispatch('setCurrentTime', this.currentTime)
        },

        timetoStr () {
            // 开始执行计时
            this.timeObj = { // 时间对象
                sec: Math.floor(this.currentTime % 60),
                min: Math.floor(this.currentTime / 60) % 60,
                hours: Math.floor(this.currentTime / 60 / 60) % 24
            }
            // 计算出时分秒
            this.hours = this.timeObj.hours<10 ? '0' + this.timeObj.hours : this.timeObj.hours;
            this.min = this.timeObj.min<10 ? '0' + this.timeObj.min : this.timeObj.min;
            this.sec = this.timeObj.sec<10 ? '0' + this.timeObj.sec : this.timeObj.sec;
        },

        /**
         * 显示已答该题学生 
         */
        showStudents () {
            let questionStudent = this.getQuesitionData.actQueData.questionStudent
            this.$alert(questionStudent.length > 0 ? questionStudent.join(',') : '暂无答题学生！', '提示', {
                confirmButtonText: '确定',
                customClass: 'messageBox-default explain',
                callback: () => {
                }
            });
        },

        listenPage() {
            if (this.getQuesitionData.pageType == 1) {
                window.onbeforeunload = function (e) {
                    e = e || window.event;
                    console.log(e)
                    if (e) {
                        e.returnValue = '关闭提示';
                    }
                    return '关闭提示';
                };
            } else {
                window.onbeforeunload = null
            }
        },

        handleClose() {
            this.bigImgDialogVisible = false
        }
    },

   watch: {
       'getQuesitionData.actQueData' (newVal, oldVal) {
            if (newVal == oldVal) {
                return
            }
            $('.que-option-body').css('opacity', '0')
            $('.que-stem-body').css('opacity', '0')
            setTimeout(() => {
                // 选项图片缩小
                if ($('.que-option-body img').length > 0 &&  newVal.questionType != 4) {
                    $('.option-item img').each(function() {
                        console.log('aaa')
                        let imgWidth = $(this).width()
                        $(this).css({'width': `${imgWidth * newVal.subjectValue}`})
                        $(this).parents('.que-option-body').css('opacity', '1')
                    })
                } else {
                    $('.que-option-body').css('opacity', '1')
                }

                // 题干图片缩小
                if ($('.que-stem-body img').length > 0) {
                    $('.que-satem img').each(function() {
                        let imgWidth = $(this).width()
                        $(this).css('width', `${imgWidth * newVal.titleValue}`)
                        $(this).parents('.que-stem-body').css('opacity', '1')
                    })
                } else {
                    $('.que-stem-body').css('opacity', '1')
                }
            }, 300)
       }
   }
 }
</script>
<style lang="scss">
.main {
    padding: 0 4px;
    height: 100%;
    box-sizing: border-box;
    background-color: #eff3f8;
    text-align: left;
    font-size: 18px;

    .main-header {
        height: 68px;
        line-height: 68px;
        font-size: 24px;
        box-sizing: border-box;
        .paper-name {
            margin-left: 42px;
            margin-right: 10px;
        }
        .paper-time {
            font-size: 16px;
            padding-right: 15px;
            span {
                font-weight: bold;
            }
        }

        .paper-time.student {
            color: var(--el-color-primary);
            cursor: pointer;
        }

        .el-breadcrumb {
            line-height: 68px;
            font-size: 16px;

            .el-breadcrumb__item {
                .el-breadcrumb__separator,
                .el-breadcrumb__inner {
                    color: #000;
                    font-weight: 700;
                }
            }

            .el-breadcrumb__item:last-child {
                .el-breadcrumb__inner {
                    color: var(--el-color-primary);
                }
            }
        }
    }

    .el-scrollbar__view {
        height: 100%;
    }

    .main-left, .main-center, .main-right {
        background-color: #fff;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .main-left {
        width: 266px;
        min-width: 266px;
    }

    .content-scrollBar {
        .el-scrollbar__wrap {
            overflow-x: hidden;

            .main-center {
                width: 50%;
                min-width: 458px;
                margin: 0 4px;
            }

            .main-right {
                width: 50%;
                min-width: 458px;
            }
        }
    }

    .el-empty {
        width: 100%;
    }

    .big-img-dialog {
        background-color: unset;
        box-shadow: unset;
        width: 100%;
        text-align: center;

        .el-dialog__header {
            background: unset;
            .el-dialog__title {
                display: none;
            }

            .el-dialog__headerbtn {
                i {
                    color: #fff;
                }

                i::before {
                    color: #fff;
                    font-size: 50px;
                }
            }
        }

        .el-dialog__body {
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .el-dialog__footer {
            display: none;
        }
    }
}
 
</style>
