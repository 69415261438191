<template>
 <div class="correct-list">
     <el-scrollbar style="height:100%" ref="scrollbar">
        <div :class="['user-item', index == actIndex ? 'act' : '']" 
                @click="chioseUser(index)"
                v-for="(item, index) in getQuesitionData.quesitionInfoData.userList" 
                :key="index"
                :title="item.userName">
                <span class="iconfont icon-duihao" v-if="item.correctOrNot == 1"></span>
                <span class="iconfont icon-loudou" v-else></span>
                {{item.userName}}
        </div>
     </el-scrollbar>
 </div>
</template>

<script>
import { questionInfoConfig } from '../../../../http/api'
import { mapGetters } from 'vuex'

 export default {
   data () {
     return {
         actIndex: -1
     }
   },
   components: {

   },

   computed: {
       ...mapGetters([
        'getQuesitionData'
      ]),
   },

   created () {

   },

   mounted () {
       if (this.actIndex < 0) {
        let userList = this.getQuesitionData.quesitionInfoData.userList
        let len = userList.length
        for (let i = 0; i < len; i++) {
            if (userList[i].correctOrNot == 0) {
                this.actIndex = i
                this.getActUserQue(userList[i].userId)
                break
            }

            if (i == len - 1) {
                this.actIndex = 0
            }
        }
        
       }
   },

   methods: {
       chioseUser (index) {
           if (index == this.actIndex) return
           this.actIndex = index
           this.getActUserQue(this.getQuesitionData.quesitionInfoData.userList[index].userId)
       },

       getActUserQue (uid) {
        //    queState.setUid(uid)
            this.$store.dispatch('setUid', uid)
           questionInfoConfig({
               type: 6,
               examId: this.getQuesitionData.examId,
               userId: uid,
               classId: this.getQuesitionData.classId,
               projectSign: 'frog'
           }).then(res => {
               let data = res.data
               
               console.log(data)
               let quesitionData = []
                for (let i in data) {
                    if (i.indexOf('QuestionData') != -1) {
                        quesitionData.push(data[i])
                    }
                }

               console.log(quesitionData)
               this.$store.dispatch('setActQueData', quesitionData[0].list[0])
               this.$store.dispatch('setQueData', quesitionData)

            //    queState.setActQueData(quesitionData[0].list[0])
            //    queState.setQueData(quesitionData)
           }).catch(err => {
               console.log(err)
           })
       }
   },
    watch: {

    }
 }
</script>

<style lang="scss">
.correct-list {
    padding-bottom: 10px;
    .user-item {
        width: 232px;
        box-sizing: border-box;
        height: 37px;
        border: 1px solid var(--el-color-primary);
        border-radius: 50px;
        line-height: 37px;
        padding: 0 40px 0 24px;
        cursor: pointer;
        margin: 0 auto 10px;
        position: relative;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;

        span {
            display: inline-block;
            width: 29px;
            height: 29px;
            line-height: 29px;
            font-size: 20px;
            color: #fff;
            font-weight: bold;
            text-align: center;
            background-color: #22ac38;
            border-radius: 50%;
            position: absolute;
            right: 6px;
            top: 50%;
            margin-top: -14.5px;
        }

        span.icon-loudou {
            background-color: initial;
            color: var(--el-color-primary);
            font-size: 26px;
            font-weight: 100;
        }
    }

    .user-item.act {
        background-color: #d6edff;
    }
}
 
</style>
