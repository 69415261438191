<template>
    <div class="app-body">
      <!-- 登录框 -->
      <login-alert :isLoginAlert="getIsLoginAlert"></login-alert>
      <userinfo-alert :getIsUserInfoAlert="getIsUserInfoAlert"></userinfo-alert>
      <router-view></router-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserinfoAlert from './components/UserinfoAlert.vue'
import LoginAlert from './components/LoginAlert.vue'
export default {
  name: 'App',
  components: {
    LoginAlert,
    UserinfoAlert
  }, 

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  computed: {
    ...mapGetters([
      'getIsLoginAlert',
      'getIsUserInfoAlert'
    ])
  },
  
  watch: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  
}

.app-body {
  height: 100%;
}
</style>
