<template>
 <div :class="['modle-test-scroll', getUserInfo ? getUserInfo.role == 2 ? '' : 'student' : '']">
     <div class="swiper-module" v-if="getUserInfo">
          <swiper :options="swiperOption" ref="swiperOne" @swiper="onSwiper">
           <swiper-slide v-for="(item, index) in paperData" :key="index" :class="['stop-swiping', item.examName ? '' : 'hide']">
                <div class="modle-test-item ub ub-ver ub-ac">
                    <div class="modle-test-title">{{item.examName}}</div>
                    <div class="modle-test-con ub ub-ver ub-ac ub-f1">
                        <div class="modle-test-time ub-f1">建议用时：{{item.recommendTime}}min</div>
                        <div class="modle-test-detail" v-if="item.userWhetherExam == 1">试题解析：<span @click="goToModelPractice(2, item.examId)">查看</span></div>
                        <el-button v-if="item.permit == 1" :class="item.answerAgain == 1 ? '': 'gray'" @click="item.answerAgain == 1 ? goToModelPractice(1, item.examId) : ''">{{item.answerAgain == 1 ? '去答题' : '已答题'}}</el-button>
                        <el-button v-else class="gray">暂无权限</el-button>
                    </div>
                </div>
                <div class="modle-test-info ub ub-ac">
                    <div class="ub ub-f1 ub-ver" v-if="getUserInfo.role == 2">
                        <div class="last-time ub-f1">待批改：{{item.noCorrectNum}}人</div>
                        <div class="last-time ub-f1">已批改：{{item.yseCorrectNum}}人</div>
                    </div>
                    <div class="ub ub-f1 ub-ver" v-if="getUserInfo.role == 1 && item.userWhetherExam == 1">
                        <div class="last-time ub-f1">最近作答时间：{{item.updateTime}}</div>
                    </div>
                    <div class="modle-see" @click="goToModelPractice(3, item.examId)" v-if="getUserInfo.role == 2 && (item.noCorrectNum > 0 || item.yseCorrectNum > 0)">查看</div>
                </div>
                <div class="attendance-see" @click="goToModelPractice(4, item.examId)" v-if="getUserInfo.role == 2 && item.classWhetherExam == 1">查看班级考情</div> 
            </swiper-slide>
            <div class="swiper-pagination"></div>
        </swiper>
        <div :class="['switch-button-prev icon-xiaoyu iconfont', isSwiperFirst ? 'first' : '']" @click="swiperSwitch(1)"></div>
        <div :class="['switch-button-next icon-dayuhao iconfont', isSwiperLast || paperData.length < 4 ? 'last' : '']" @click="swiperSwitch(2)"></div>
     </div>
 </div>
</template>

<script>
import { mapGetters } from 'vuex'
 export default {
    props: ['paperData', 'classId'],
    components: {
    },

    data () {
     return {
        swiperOption: {
            loop: false,
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 0,
            noSwipingClass: 'stop-swiping',
            // 显示分页
            pagination: {
                el: ".swiper-pagination",
                type: 'fraction',
            },
            on: {     
				// 使用es6的箭头函数，使this指向vue对象          
				slideChange: (swiper) => {
                    console.log(swiper)
                    this.isSwiperEnd()
                    this.isSwiperBeginning()
                },

                click: function () {
                    console.log(this); // 当前滑块索引
                }
			}
        },
        isSwiperLast: true,
        isSwiperFirst: true,
        actExamId: ''
     }
   },
   computed: {
       ...mapGetters([
            'getUserInfo'
        ]),

        swiperOne: function () {
            return this.$refs.swiperOne
        },
   },

    
   
    created() {
       
    },
   
    mounted() {
        setTimeout(() => {
            console.log(this.swiperOne)
            if (this.swiperOne) {
                this.isSwiperEnd()
                this.isSwiperBeginning()
                let selectAct = localStorage.getItem('selectAct')
                if (selectAct) {
                    selectAct = JSON.parse(selectAct)
                    if (selectAct.examId) {
                        let index = this.paperData.findIndex((item) => {
                            return item.examId == selectAct.examId
                        })
                        if (index > 3) {
                            this.$refs.swiperOne.swiper.slideTo(index, 1000);
                        }
                    }
                }
            }
        }, 1500)
    },

    methods: {
       swiperSwitch (index) {
           if (index == 1) {
                this.swiperOne.swiper.slidePrev();
           } else {
                this.swiperOne.swiper.slideNext();
                
           }
           this.isSwiperEnd()
           this.isSwiperBeginning()
       },

       isSwiperEnd () {
            this.isSwiperLast = this.swiperOne.swiper.isEnd
       },

       isSwiperBeginning () {
           this.isSwiperFirst = this.swiperOne.swiper.isBeginning 
       },

       goToModelPractice (type, examId) {
            this.setActLocalStorage('examId', examId)
           let  params = {
                    type: type, 
                    examId: examId, 
                    classId: this.classId, 
                    uid: this.getUserInfo.id, 
                    role: this.getUserInfo.role
                }
           console.log('params', params)
           this.$router.push({name: 'modelPractice', params:{type: type, examId: examId, classId: this.classId, uid: this.getUserInfo.id, role: this.getUserInfo.role}});
       },

       /**
         * 设置当前高亮节点
         */
        setActLocalStorage(key, index) {
            let selectAct = JSON.parse(localStorage.getItem('selectAct'))
            selectAct[key] = index
            localStorage.setItem('selectAct', JSON.stringify(selectAct))
        },
    }
 }
</script>

<style lang="scss" scoped>
.modle-test-scroll{
    .swiper-module {
        width: 100%;
        margin: 0 auto;
        position: relative;
        
        .swiper-container {
            width: 848px;
            margin: 0 auto;
            padding-bottom: 30px;
            .swiper-wrapper {
                .swiper-slide {
                    // width: 262px !important;
                }

                .swiper-slide.hide {
                    opacity: 0;
                }

                .modle-test-item {
                    width: 203px;
                    height: 203px;
                    box-sizing: border-box;
                    // border: 1px solid #0080ff;
                    border-radius: 25px;
                    margin: 0 auto 15px;
                    overflow: hidden;

                    .modle-test-title {
                        width: 100%;
                        height: 55px;
                        line-height: 55px;
                        text-align: center;
                        background-color: var(--el-color-second);
                        font-weight: bold;
                    }

                    .modle-test-con {
                        width: 100%;
                        box-sizing: border-box;
                        border: 1px solid var(--el-color-primary);
                        border-top: 0px;
                        border-bottom-left-radius: 25px;
                        border-bottom-right-radius: 25px;
                    }

                    .modle-test-time {
                        height: 60px;
                        line-height: 60px;
                    }

                    .modle-test-detail {
                        span {
                            color: var(--el-color-primary);
                            cursor: pointer;
                        }
                    }

                    .el-button {
                        width: 167px;
                        height: 38px;
                        border-radius: 100px;
                        background-color: var(--el-color-primary);
                        color: #fff;
                        font-size: 16px;
                        border-color: var(--el-color-primary);
                        padding: 0;
                        margin-top: 15px;
                        margin-bottom: 15px;
                    }

                    .el-button:active {
                        background-color: var(--el-color-primary);
                    }
                }

                .modle-test-info {
                    width: 193px;
                    margin: 0 auto;
                    line-height: 24px;
                    font-size: 14px;
                    text-align: left;
                    color: #999999;
                    
                    .last-time {
                        // padding: 12px 0 8px;
                        font-size: 12px;
                    }

                    .modle-see {
                        color: #0080ff;
                        cursor: pointer;
                    }
                }

                .attendance-see {
                    text-align: left;
                    font-size: 14px;
                    color: var(--el-color-primary);
                    width: 193px;
                    margin: 5px auto;
                    cursor: pointer;
                }
            }

            .swiper-pagination {
                bottom: 5px;
            }
        }

        .switch-button-prev,
        .switch-button-next {
            font-size: 54px;
            color: var(--el-color-primary);
            position: absolute;
            margin-top: -60px;
            top: 50%;
            cursor: pointer;
        }

        .switch-button-prev {
            left: 0;
        }

        .switch-button-prev.first,.switch-button-next.last {
            color: #bfbfbf;
        }

        .switch-button-next {
            right: 0;
        }
    }
}

// .modle-test-scroll.student {
//     .swiper-container { 
//         // width: 1049px;

//         .swiper-wrapper {

//             .modle-test-item {
//                 width: 233px;
//             }

//             .modle-test-info {
//                 width: 216px;
//                 text-align: center;
//             }
//         }

//     }
// }
 
</style>
