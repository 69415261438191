<template>
  <div class="legal-disclaimer">
    <p>
      凡在本平台上发布的作品，其所有权均属武汉智多蛙教育科技有限公司所有。任何媒体、网站或个人未经本公司授权不得转载、链接、转贴或以其他方式使用平台作品。已经本公司授权的媒体、网站或个人，在下载使用时必须注明"来源：智多蛙"，违者本公司将依法追究相关法律责任。
    </p>
    <p>
      本公司在制作上述作品时，采用了部分来源网络的内容，
      该部分内容并未标明出处导致原作者不详， 因而本公司未能获取相应的授权。
      本公司非常重视知识产权问题，
      尊重原作者的智力成果，如果本司的上述作品使用了您的版权内容，
      请您及时与本公司联系，本公司将立即与您协商解决版权相关事宜。
    </p>
    <p>
      所有在本平台上购买课程的用户，一经确认，均代表已同意自愿购买本平台课程。由于本平台课程产品的特殊性，用户购买课程后则不能退款，请用户自行确认后再购买课程产品。
    </p>
    <p>本声明所有解释权均归武汉智多蛙教育科技有限公司所有。</p>
    <p class="tx-r" style="margin-top:20px;">武汉智多蛙教育科技有限公司</p>
    <p class="tx-r">2019年8月</p>
  </div>
</template>

<script>
export default {
  components: {

  }
}
</script>

<style>

</style>