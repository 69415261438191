
import { get, post} from './request'
import config from './config'

console.log(config)
export const getLatestVersion = p => get(config.getLatestVersion, p);
export const login = p => get(config.login, p);
export const teacherHomePage = p => get(config.teacherHomePage, p);
export const curriculumController = p => get(config.curriculumController, p);
export const pfrResourcesController = p => get(config.pfrResourcesController, p);
// quesition questionInfoConfig
export const questionInfoConfig = p => get(config.questionInfoConfig, p) 
export const questionInfoConfigPost = p => post(config.questionInfoConfig, p) 
export const examInfoConfig = p => get(config.examInfoConfig, p)
export const examInfoConfigPost = p => post(config.examInfoConfig, p)
export const managerClass = p => get(config.managerClass, p);
export const versionControlle = p => get(config.versionControlle, p);
