<template>
  <div class="module-one">
    <h5>编程能带给孩子什么?</h5>
    <div class="module-content ub">
      <el-image class="ub-f1" :src="require('../../../assets/image/home_module_one.png')" lazy></el-image><!-- 
       --><ul>
        <li v-for="(item, index) in list" :key="index">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: ['提高创新能力', '数理逻辑思维能力', '培养耐心和专注力', '提升团队协作能力', '分析和解决问题的能力', '升学助力，提高竞争力']
    }
  }
}
</script>

<style lang="scss">
.module-one {
  width: 100%;
  height: 882px;
  padding-top: 70px;
  background: var(--el-color-primary);
  background-image: url('../../../assets/image/home_module_one_bg.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  margin-top: -10px;

  h5 {
    padding: 0;
    margin: 0;
    font-size: 66px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    letter-spacing: 8px;
  }

  .module-content {
    width: 1165px;
    margin: 0 auto;

    img {
      width: 508px;
      height: 584px;
    }

    ul {
      width: 541px;
      margin-top: 80px; 
      li {
        width: 541px;
        height: 72px;
        line-height: 72px;
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 10px;
        font-size: 38px;
        font-weight: bold;
        color: var(--el-color-primary);
        position: relative;
        text-align: center;
      }

      li::before,
      li::after {
        content: '';
        width: 29px;
        height: 29px;
        background-color: var(--el-color-primary);
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        position: absolute;
        top: 24px;
      }

      li::before {
        left: 25px;
      }

      li::after {
        right: 25px;
      }
    }
  }
}

</style>