<template>
 <div :class="['main-stem ub ub-ver ub-f1']">
    <QueTitle :title='getTitle(getQuesitionData.actQueData)' title-type="mainQuesition"></QueTitle>
    <div class="main-con ub-f1">
      <el-scrollbar style="height:100%" ref="scroll">
        <div class="container">
          <component class="que-stem-body" :is="'queStem' + getQuesitionData.actQueData.questionType" v-if="getQuesitionData.actQueData.questionType"></component>
        </div>
      </el-scrollbar>
    </div>
 </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QueTitle from './QueTitle.vue'
import QueStem1 from './queStem/QueStem1.vue'
import QueStem2 from './queStem/QueStem2.vue'
import QueStem3 from './queStem/QueStem3.vue'
import QueStem4 from './queStem/QueStem4.vue'
import que from '../../../../util/que'
 export default {
   data () {
     return {
         title: '第1题：单项选择题（X分）'
     }
   },
   components: {
       QueTitle,
       QueStem1,
       QueStem2,
       QueStem3,
       QueStem4
   },
   computed: {
     ...mapGetters([
        'getQuesitionData'
      ]),

     getTitle () {
       return function (item) {
         return `第${item.serialNum ? item.serialNum : 0}题：${que.getQueTitle(item.questionType)}（${item.questionScore ? item.questionScore : 0}分）`
       }
     }
   },
   created() {
     
   },
   mounted() {
    //  this.$refs.scroll.wrap.scrollTop = 0
    //   setTimeout(() => {

    //   }, 500);
   }, 

   watch: {
    //  'getQuesitionData.actQueData' () {
    //    this.$refs.scroll.wrap.scrollTop = 0
    //  }
   }
 }
</script>

<style lang="scss">
.main-stem {
  .main-con {
    overflow: hidden;
    box-sizing: border-box;
    height: 100%;

    .el-scrollbar__wrap {
        overflow-x: hidden;

        .container {
          box-sizing: border-box;
          padding: 0 15px;

          .que-stem-body {
            // opacity: 0;
          }

          p {
            line-height: 30px;
            margin: 16px 0;
          }

          img {
            max-width: 100%;
          }
        }
    }
  }
}

/* .main-stem.GIE {
  .main-con {
    .el-scrollbar__wrap {
      .container {
        img {
          max-width: 100%;
          max-height: inherit;
        }
      }
    }
  }
} */
 
</style>
