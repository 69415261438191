<template>
<!-- 填空题 -->
 <div class="que-satem type1">
     <div class="title" v-html="getQuesitionData.actQueData.questionTitle"></div>
 </div>
</template>

<script>
import { mapGetters } from 'vuex'

 export default {
   data () {
     return {
       
     }
   },
   components: {

   },

   computed: {
     ...mapGetters([
        'getQuesitionData'
      ]),
   }
 }
</script>

<style>

 
</style>
