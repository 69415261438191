//拼搭图 PDF展示
<template>
    <div class="tick_picture_pdf">
        <!-- 内容布局 -->
        <div class="content" v-if="!showLoading">
            <!-- 左边的索引列表 -->
            <!-- <div class="left_list" v-if="false">
                <div :class="['item_list', { select_bg: activePos == index }]" v-for="(item, index) in pptList" :key="index" @click.stop="changePpt(item, index)">
                    <img class="video_img" :src="item.pptCover" />
                    <div class="video_name">{{ item.pptName }}</div>
                </div>
            </div> -->
            <!-- 右边的PDF展示区域展示 -->
            <div class="right_content" id="iframe_pdf_tp" @mousemove="showMenu = true" @mouseleave="showMenu = false">

                <pdf id="pdf_show_content_tp" style="height: 100%; overflow-y: scroll;" :page="pageNum" :src="pdfUrl" @progress="loadedRatio = $event" @num-pages="pageTotalNum = $event"></pdf>

                <!-- 左右按钮 -->
                <button type="button" :disabled="currIndex == 0" class="el-carousel__arrow el-carousel__arrow--left animated fadeIn" :class="{ dis_cursore: currIndex == 0 }" v-show="showMenu" @click.stop="prePage">
                    <el-icon><ArrowLeftBold /></el-icon>
                </button>

                <button type="button" v-show="showMenu" :class="{ dis_cursore: currIndex == pageTotalNum - 1 }" class="el-carousel__arrow el-carousel__arrow--right animated fadeIn" @click.stop="nextPage">
                    <el-icon><ArrowLeftBold /></el-icon>
                </button>

                <!-- 放大缩小的按钮 -->
                <div class="bom_scale iconfont" :class="[isScale ? 'icon-tuichuquanping' : 'icon-quanping']" @click.stop="doScaleAction"></div>
            </div>
        </div>
        <!-- 加载框 -->
        <Loading v-if="showLoading"></Loading>
    </div>
</template>
<script>
import pdf from "vue-pdf";
import screen from "../../../minxin/screen"
export default {
    name: "TickPicturePdf",
    mixins: [screen],
    data() {
        return {
            //左边的PPT播放列表
            pptList: [],
            //每个小节对应的PPT列表
            pptArr: [],
            //左边列表选中的位置
            activePos: 0,
            //底部显示的页面位置
            currPos: 0,
            showLoading: true,
            //初始状态激活的幻灯片的索引，从 0 开始
            currPage: 0,
            pageNum: 1,
            pageTotalNum: 1, // 总页数
            loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
            //选中的页码
            currIndex: 0,
            //是否显示左右菜单
            showMenu: false,
            //当前加载的pdfUrl
            pdfUrl: "",
            //放大或者缩小的标识
            isScale: false,
        };
    },
    created() {
        //请求数据
        this.httpData();
    },
    mounted() {
        //监听全屏事件
        window.onresize = () => {
            if (this.isFullscreen() == null) {
                //缩小状态
                this.isScale = false;
            } else {
                //放大状态
                this.isScale = true;
            }
        };
    },
    methods: {
        //获取列表数据
        async httpData() {
            let param = this.getHttpParams();
            param.type = 4;
            param.curriculumCenterId = this.curriculumCenterId;
            param.curriculumChapterId = this.curriculumChapterId;
            param.chapterClassId = this.chapterClassId;
            param.sectionId = 3;
            let res = await this.$http.fetchPost(
                this.$api.LESSON_CENTER,
                param
            );
            this.showLoading = false;
            if (res.data.state == 200) {
                //获取PDF的展示地址
                this.pdfUrl = res.data.data[0].url;
                //计算总的页面数量
                this.getNumPages();
            } else {
                //直接将服务器返回的错误内容弹出提示
                this.showErrorMsg(res.data.msg);
            }
        },

        //幻灯片改变的方法
        change(pos) {
            this.currPos = pos;
        },

        //左边列表条目的点击事件
        changePpt(item, index) {
            if (this.activePos != index) {
                this.activePos = index;

                //重置选中的值
                this.currIndex = 0;

                //重置pageNum
                this.pageNum = 1;

                //重置展示的pdfUrl的数据
                this.getNumPages();
            }
        },

        //计算总的页码数量
        getNumPages() {
            // this.pdfUrl = this.pptList[this.activePos].pdfUrl;
            let loadingTask = pdf.createLoadingTask(this.pdfUrl);
            loadingTask.promise
                .then((pdf) => {
                    this.pageTotalNum = pdf.numPages;
                })
                .catch((err) => {
                    console.error("pdf 加载失败", err);
                });
        },

        // 上一页
        prePage() {
            if (this.currIndex > 0) {
                this.currIndex--;
                let page = this.pageNum;
                page = page > 1 ? page - 1 : this.pageTotalNum;
                this.pageNum = page;
                //翻页时候回到顶部
                this.toTop();
            }
        },

        // 下一页
        nextPage() {
            if (this.currIndex < this.pageTotalNum - 1) {
                this.currIndex++;
                let page = this.pageNum;
                page = page < this.pageTotalNum ? page + 1 : 1;
                this.pageNum = page;
                //翻页时候回到顶部
                this.toTop();
            }
        },

        // 页面回到顶部
        toTop() {
            document.getElementById("pdf_show_content_tp").scrollTop = 0;
        },

        //全屏的方法
        showFullAction() {
            let iframe = document.getElementById("iframe_pdf_tp");
            this.requestFullScreen(iframe);
        },

        /**
         * 点击全屏和缩小的操作
         */
        doScaleAction() {
            if (this.isScale) {
                //缩小
                this.exitFullscreen();
            } else {
                //放大
                this.showFullAction();
            }
            this.isScale = !this.isScale;
        },
    },
    props: ["curriculumCenterId", "curriculumChapterId", "chapterClassId"],
    computed: {},
    components: {
        pdf,
    },
};
</script>
<style lang="scss" scoped>
.tick_picture_pdf {
    background: black;
    display: flex;
    align-items: center;
    flex: 1;
    .content {
        width: 80%;
        margin: 0 auto;
        display: flex;
        height: calc(100vh - 70px);
        display: flex;
        align-items: center;
        justify-content: center;
        //左边的索引List部分
        .left_list {
            margin-right: 16px;
            height: 100%;
            background: rgba($color: #abacae, $alpha: 0.2);
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            width: 220px;
            overflow-x: hidden;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            .item_list {
                height: 68px;
                padding: 16px 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                //视频图片
                .video_img {
                    margin-left: 14px;
                    width: 58px;
                    height: 58px;
                    border-radius: 2px;
                    object-fit: cover;
                }
                //视频名称
                .video_name {
                    color: white;
                    flex: 1;
                    margin: 0 14px;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .item_list:hover {
                // background: rgba($color: #000000, $alpha: 0.4);
                background: rgba($color: white, $alpha: 0.4);
            }

            //选中的背景
            .select_bg {
                // background: rgba($color: #000000, $alpha: 0.4);
                background: rgba($color: white, $alpha: 0.4);
            }
        }

        // 右边的展示PPT列表部分
        .right_content {
            height: 92%;
            border-radius: 8px;
            flex: 1;
            display: flex;
            flex-direction: column;
            position: relative;

            .bom_scale {
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
                color: #000000;
                // background: #83e1ff;
                background: rgba($color: var(--primary-bgc), $alpha: 0.4);
                padding: 4px;
                font-size: 16px;
                border-radius: 2px;
            }

            .el-carousel__arrow {
                color: #000000;
                font-size: 38px;
                background: rgba($color: var(--primary-bgc), $alpha: 0.4);
            }

            .dis_cursore {
                cursor: not-allowed;
            }
        }
    }
}
</style>
