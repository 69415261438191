<template>
    <que-button class="finish-button" :isAct="true" :isButtonLoading="isButtonLoading" text="我要交卷" @buttonClick="submitPaper" v-if='getQuesitionData.pageType == 1'></que-button>
</template>

<script>
import { examInfoConfigPost } from '../../../../http/api'
import { mapGetters } from 'vuex'
import QueButton from './QueButton.vue'
export default {
    components: {
        QueButton
    },

    data() {
        return {
            isButtonLoading: false
        }
    },

    computed: {
        ...mapGetters([
            'getQuesitionData'
        ])
    }, 

    methods: {
        submitPaper () {
            let tip = ''
            if (this.getQuesitionData.progress != 100) {
                tip = '还有试题未答完，确认交卷吗？一旦交卷就不能再作答或者修改答案啦！'
            } else {
                tip = '确认交卷吗？一旦交卷就不能再作答或者修改答案啦！'
            }
            this.$messageBox.confirm(tip, '提示', {
                confirmButtonText: '确认提交',
                cancelButtonText: '继续答题',
                type: 'warning',
                customClass: 'default-dialog submit-message'
                // custom-class: 'default-dialog'
            }).then(() => {
                let userAnswer = {}
                for (let i = 0; i < this.getQuesitionData.quesitionData.length; i++) {
                    for (let j = 0; j < this.getQuesitionData.quesitionData[i].list.length; j++) {
                        (userAnswer[this.getQuesitionData.quesitionData[i].list[j].questionId]) = (this.getQuesitionData.quesitionData[i].list[j].userAnswer ? this.getQuesitionData.quesitionData[i].list[j].userAnswer : '')
                    }
                }
                this.requestPaper(userAnswer)
            })
        },

        requestPaper(userAnswer) {
            let formData = new FormData();
            //参数
            formData.append("type", 2);
            formData.append("answer", JSON.stringify(userAnswer));
            formData.append("userId", this.getQuesitionData.uid);
            formData.append("examId", this.getQuesitionData.examId);
            formData.append("classId", this.getQuesitionData.classId);
            formData.append("answerTime", this.getQuesitionData.currentTime);
            formData.append("role", this.getQuesitionData.role);
            formData.append("projectSign", 'frog');
            console.log(JSON.stringify(userAnswer))
            console.log(this.getQuesitionData.uid)
            console.log(this.getQuesitionData.examId)
            console.log('type', formData.get('type'))
            console.log('answer', formData.get('answer'))
            this.isButtonLoading = true
            examInfoConfigPost(formData).then(res => {
                console.log(res)
                this.isButtonLoading = false
                this.$message({
                    message: "提交成功！",
                    type: "success",
                    showClose: true
                });
                setTimeout(() => {
                    this.$router.push({name: this.getQuesitionData.pageName})
                }, 1000)
            }).catch(() => {
                this.isButtonLoading = false
            })
        }
    }
}
</script>

<style lang="scss">
.submit-message {
    padding-bottom: 30px;
    .el-message-box__header {
        position: relative;
        height: 36px;

        .el-message-box__title {
            display: none;
        }

        .el-message-box__headerbtn {
            .el-message-box__close {
                font-size: 32px;
            }
        }
    }

    .el-message-box__content {
        padding-top: 30px;
        min-height: 95px;
        word-wrap: break-word;
        overflow: hidden;
        padding: 10px 15px;
        font-size: 16px;
        color: #000;

        .el-icon {
            display: none;
        }
    }

    .el-message-box__btns {
        text-align: center;
        justify-content: space-between;
        padding: 5px 30px 0;

        .el-button {
            width: 170px;
            height: 42px;
            box-sizing: border-box;
            border-radius: 100px;
            font-size: 16px;
            background-color: var(--el-color-primary);
            border-color: var(--el-color-primary);
            color: #fff;
        }

        .el-button--primary {
            color: var(--el-color-primary);
            border-width: 2px;
            border-color: var(--el-color-primary);
            color: var(--el-color-primary);
            background-color: #fff;
            margin: 0;
        }
    }
}
</style>