<template>
  <div class="about-us">
    <page-header></page-header>
    <div class="content ub ub-be">
      <left-menu></left-menu>
      <div class="content-body">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '../../components/PageHeader'
import LeftMenu from '../../components/LeftMenu'
export default {
  components: {
    PageHeader,
    LeftMenu
  },

  created() {
    let path = this.$route.path
    if(path == '/aboutUs') {
      this.$router.push('/aboutUs/aboutCompany')
    }
    
  }
}
</script>

<style lang="scss">
.about-us {
  .content {
    min-height: 700px;
    width: 1190px;
    margin: 30px auto 0;
  }

  .content-body {
    width: 967px;

    p {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 5px
    }
  }
}
</style>