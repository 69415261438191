//班级消息
<template>
  <div class="class_message_dialog">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <div class="class_message_dialog_content">
          <!-- 内容部分 -->
          <div v-if="!showLoading">
            <!-- 老师端消息 -->
            <div class="messge_content" v-if="role == 2">
              <div
                class="item_class_msg"
                v-for="(item, index) in currMsg"
                :key="index"
              >
                <!-- 时间 -->
                <span>{{ formatDate(item.create_time) }}</span>

                <!-- 老师处理学生申请加入班级的 -->
                <div
                  class="teacher_msg_action"
                  v-if="
                    item.result_state == 1 ||
                    item.result_state == 2 ||
                    item.result_state == 0
                  "
                >
                  <span class="user_name"
                    >用户&nbsp;<span style="color: #1094fd">{{
                      item.name
                    }}</span
                    >&nbsp;申请加入班级</span
                  >

                  <!-- 操作部分 -->
                  <div class="action">
                    <!-- 已同意 -->
                    <div v-if="item.result_state == 1" class="has_agreement">
                      已同意
                    </div>
                    <!-- 已拒绝 -->
                    <div v-else-if="item.result_state == 2" class="has_reject">
                      已拒绝
                    </div>
                    <!-- 未处理 -->
                    <div v-else-if="item.result_state == 0" class="untreated">
                      <span class="bt_reject" @click.stop="doRejectAction(item)"
                        >拒绝</span
                      >
                      <span class="bt_agreement" @click.stop="doAgreement(item)"
                        >同意</span
                      >
                    </div>
                  </div>
                </div>

                <!-- 老师端移除班级  -->
                <div v-if="item.result_state == 3">
                  <div class="user_name">
                    <!-- 你将&nbsp;<span class="del_stu_names" :title="item.name">{{ -->
                    {{item.operator}}将&nbsp;<span class="del_stu_names" :title="item.name">{{
                    
                      item.name
                    }}</span
                    >&nbsp;移出班级群
                  </div>
                </div>
                <!-- 老师创建班级 -->
                <div class="margin_sty" v-if="item.result_state == 4">
                  {{item.operator}}修改了班级名称
                  <!-- 你修改了班级名称 -->
                </div>
                <!-- 消息失效 -->
                <div class="margin_sty" v-if="item.result_state == 9">
                  <span class="user_name"
                    >用户&nbsp;<span style="color: #1094fd">{{
                      item.name
                    }}</span
                    >&nbsp; 申请加入班级信息已失效</span
                  >
                </div>
                <div class="margin_sty" v-if="item.result_state == 10">
                  {{item.operator}}成功创建班级
                  <!-- 你成功创建班级 -->
                </div>
              </div>
            </div>

            <!-- 学生端消息 -->
            <div class="messge_content" v-if="role == 1 || role == 0">
              <div
                class="item_class_msg"
                v-for="(item, index) in currMsg"
                :key="index"
              >
                <!-- 时间 -->
                <span>{{ formatDate(item.create_time) }}</span>

                <!-- 消息内容 -->
                <div class="msg_content">
                  <div>
                    <!-- 已同意 -->
                    <div v-if="item.result_state == 1">
                      你的班级申请已通过！
                    </div>
                    <!-- 已拒绝 -->
                    <div v-else-if="item.result_state == 2">
                      你的班级申请已被拒绝,如果有疑问请联系班主任！
                    </div>
                    <div v-else-if="item.result_state == 3">
                      你已被班主任移出班级，如果有疑问请联系班主任！
                    </div>
                    <div v-else-if="item.result_state == 4">
                      班主任修改了班级名称！
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 底部的取消和确定按钮 -->
            <div class="bom_action">
              <!-- 上一页 -->
              <button
                :class="['cancel', { cursor_unclick: currPage == 0 }]"
                @click.stop="doPreAction"
                :disabled="currPage == 0"
              >
                <span>{{ currPage == 0 ? "第一页" : "上一页" }}</span>
              </button>
              <!-- 确定 -->
              <button
                :class="[
                  'commit',
                  { cursor_unclick: currPage == totalPage - 1 },
                ]"
                @click.stop="doNextAction"
                :disabled="currPage == totalPage - 1"
              >
                <span>{{
                  currPage == totalPage - 1 ? "末 页" : "下一页"
                }}</span>
              </button>
            </div>
          </div>

          <!-- 加载框部分 -->
          <!-- <LoadingMsg v-else></LoadingMsg> -->
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";

import { mapGetters } from "vuex";

import { managerClass } from "../../http/api";

//引入Loading框
// import LoadingMsg from "@/components/LoadingMsg";

export default {
  data() {
    return {
      //是否显示加载框
      showLoading: true,

      //用户角色 0学生,1老师
      role: -1,

      //对话框的宽度
      width: "580px",

      //每显示的消息条数
      pageNum: 4,

      //当前显示的页面
      currPage: 0,

      //总的页码数量
      totalPage: 0,

      //定义this
      that: this,

      //服务器返回的班级消息
      message: [],

      //当前消息分组的数组
      msgGroup: [],

      //当前页面需要显示的消息数据
      currMsg: [],
    };
  },
  created() {
    //获取用户的角色
    this.role = this.getUserInfo.role;
    console.log(this.role);
  },

  mounted() {
    //请求消息数据
    this.httpData();
  },
  methods: {
    //  getParams(type) {
    //       let useInfo = JSON.parse(localStorage.getItem("userInfo"));
    //         let obj = new Object();
    //         obj.type = type;
    //         obj.c = useInfo.tel;
    //         obj.uid = useInfo.id;
    //         obj.token = useInfo.token;
    //         let param = obj;
    //         return param;
    // },

    //获取班级下的所有消息
    httpData() {
      managerClass({
        type: "6",
        c: this.getUserInfo.tel,
        uid: this.getUserInfo.id,
        token: this.getUserInfo.token,
        classID: this.cid,
        state: "2",
        page: 1,
        num: 1500,
      })
        .then((res) => {
          console.log(res);

          //改变加载状态
          this.showLoading = false;

          //获取班级的消息数据
          this.message = res.infoList;

          //组装消息数据
          this.sysClassData(this.message);
        })
        .catch(() => {});
    },

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },

    //组装消息数据
    sysClassData(msgArr) {
      //先清空一下分组的数据以及当前显示消息的数组的数据
      this.msgGroup.splice(0, this.msgGroup.length);
      this.currMsg.splice(0, this.currMsg.length);

      for (let i = 0, len = msgArr.length; i < len; i += this.pageNum) {
        this.msgGroup.push(msgArr.slice(i, i + this.pageNum));
      }

      //总的消息页
      this.totalPage = this.msgGroup.length;

      //配置当前需要显示的学生数据,默认是第一页的数据
      this.msgGroup[0].forEach((item) => {
        this.currMsg.push(item);
      });
    },

    //拒绝加入班级的操作
    doRejectAction(item) {
      this.http(item, 1);
    },

    //同意加入班级的操作
    doAgreement(item) {
      this.http(item, 2);
    },

    //获取班级下的所有消息
    http(item, flag) {
      var res = "";
      if (flag == 1) {
        //拒绝,同步条目的处理状态
        res = 2;
      } else if (flag == 2) {
        //同意
        res = 1;
      }
      managerClass({
        type: "4",
        c: this.getUserInfo.tel,
        uid: this.getUserInfo.id,
        token: this.getUserInfo.token,
        id: this.getUserInfo.id,
        mid: item.id,
        res: res,
      })
        .then((res) => {
          console.log(res);

          //操作成功
          if (flag == 1) {
            //拒绝
            item.CLASS_STATUS = 2;
          } else if (flag == 2) {
            //同意
            item.CLASS_STATUS = 1;
          }
          //关闭当前弹框
          this.close();
          //通知父组件的页面刷新
          this.$emit("refreshData");
        })
        .catch(() => {});
    },

    //上一页
    doPreAction() {
      this.currPage -= 1;
      //同步页面数据
      this.sysPageMsgList();
    },

    //下一页
    doNextAction() {
      this.currPage += 1;
      //同步页面数据
      this.sysPageMsgList();
    },

    //根据当前页面同步消息显示的数据
    sysPageMsgList() {
      //先清空当前消息列表数据
      this.currMsg.splice(0, this.currMsg.length);

      //配置当前需要显示的学生数据,默认是第一页的数据
      this.msgGroup[this.currPage].forEach((item) => {
        this.currMsg.push(item);
      });
    },
    showSuccessMsg(text) {
      this.$message({
        message: text,
        type: "success",
      });
    },
    showErrorMsg(text) {
      this.$message.error(text);
    },

    showWarnMsg(text) {
      this.$message({
        message: text,
        type: "warning",
      });
    },
    getMesage(code) {
      let res = "";
      switch (parseInt(code)) {
        case 104:
          res = "传入参数异常";
          break;
        case 105:
          res = "程序异常";
          break;
        case 108:
        case 208:
          res = "您的账号已被挤下线,请重新登录~~";
          setTimeout(() => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("autoLogin");
            this.$router.replace("/");
          }, 1000);
          break;
        case 201:
          res = "此账号不存在";
          break;
        case 202:
          res = "学校加盟时间到期";
          break;
        case 203:
          res = "账号已存在";
          break;
        case 205:
          res = "手机号已被注册";
          break;
        case 207:
          res = "无效用户";
          break;
        case 209:
          res = "昵称重复";
          break;
        case 210:
          res = "用户未绑定手机号";
          break;

        case 211:
          res = "该手机号已绑定其他微信";
          break;
        case 212:
          res = "微信已绑定其他手机号";
          break;
        case 213:
          res = "与原密码一致";
          break;
        case 214:
          res = "账号已被停用";
          break;
        case 301:
          res = "验证码发送失败";
          break;
        case 302:
          res = "验证码错误";
          break;
        case 303:
          res = "今日短信次数已超上限";
          break;
        case 304:
          res = "验证码已失效";
          break;
        case 305:
          res = "验证码发送间隔过短";
          break;
        case 306:
          res = "验证码类型错误";
          break;
        case 500:
          res = "服务器异常";
          break;
        case 1001:
          res = "班级邀请码错误";
          break;
        case 1002:
          res = "人数已达上限";
          break;
        case 1009:
          res = "班级名重复";
          break;
        case 1010:
          res = "合作到期";
          break;
        case 1003:
          res = "班级课程到期";
          break;
        case 1011:
          res = "消息已失效";
          break;

        case 1004:
          res = "学生已加入申请的班级";
          break;
        case 1006:
          res = "班级名称重复";
          break;
        case 1013:
          res = "班级不存在";
          break;
        case 1018:
          res = "已申请加入班级，请勿重复申请";
          break;
        case 1017:
          res = "已在老师的其他班级里，不可重复加入班级哦";
          break;
        case 1019:
          res = "已申请加入老师名下班级，请等待老师审批";
          break;
        case 1020:
          res = "该班级名额已满，请联系老师";
          break;
        case 1021:
          res = "学生人数已满";
          break;
      }
      return res;
    },

    formatDate(date) {
      date = date == undefined ? new Date() : date;
      date = typeof date == "number" ? new Date(date) : date;
      var fmt = "yyyy-MM-dd HH:mm:ss";
      var obj = {
        y: date.getFullYear(), // 年份，注意必须用getFullYear
        M: date.getMonth() + 1, // 月份，注意是从0-11
        d: date.getDate(), // 日期
        q: Math.floor((date.getMonth() + 3) / 3), // 季度
        w: date.getDay(), // 星期，注意是0-6
        H: date.getHours(), // 24小时制
        h: date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, // 12小时制
        m: date.getMinutes(), // 分钟
        s: date.getSeconds(), // 秒
        S: date.getMilliseconds(), // 毫秒
      };
      var week = ["天", "一", "二", "三", "四", "五", "六"];
      for (var i in obj) {
        fmt = fmt.replace(new RegExp(i + "+", "g"), function (m) {
          var val = obj[i] + "";
          if (i == "w") return (m.length > 2 ? "星期" : "周") + week[val];
          for (var j = 0, len = val.length; j < m.length - len; j++)
            val = "0" + val;
          return m.length == 1 ? val : val.substring(val.length - m.length);
        });
      }
      return fmt;
    },
  },
  //过滤器
  filters: {
    // dataFormat(date, that) {
    //     return that.formatDate(date, "yyyy-MM-dd HH:mm");
    // },
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  components: {
    CommonDialog,
    // LoadingMsg,
  },
  props: ["showDialog", "title", "cid"],
};
</script>
<style lang="scss" scoped>
.class_message_dialog {
  ::v-deep .el-dialog {
    // top: 50%;
  }
  .class_message_dialog_content {
    min-height: 284px;
    display: flex;
    justify-content: center;
    color: black;
    font-size: 14px;
    flex-direction: column;

    //顶部部分
    .messge_content {
      min-height: 200px;
      justify-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 16px;
      //消息条目
      .item_class_msg {
        display: flex;
        height: 50px;
        align-items: center;
        color: black;
        font-size: 12px;

        .user_name {
          margin-left: 12px;
          display: flex;
          .del_stu_names {
            max-width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #1094fd;
          }
        }
        .margin_sty {
          margin-left: 12px;
        }

        .time {
          width: 110px;
        }

        //老师端消息
        .teacher_msg_action {
          display: flex;
        }

        .msg_content {
          // flex: 1;
          margin-left: 16px;
        }

        //已同意
        .has_agreement {
          // color: $common_bg;
          color: #70b600;
          margin-left: 2px;
        }

        //已拒绝
        .has_reject {
          color: #e81525;
          margin-left: 2px;
        }

        //未处理
        .untreated {
          display: flex;
          .bt_reject,
          .bt_agreement {
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .bt_reject {
            // color: $text_gray;
            color: gray;
            // border: 1px solid $common_bg;
            border: 1px solid #70b600;

            height: 20px;
            width: 36px;
            margin: 0 12px;
          }

          .bt_agreement {
            color: white;
            // background: $common_bg;
            background: #70b600;
            height: 22px;
            width: 38px;
          }
        }
      }
    }

    //底部的操作按钮
    .bom_action {
      margin-top: 20px;
      height: 48px;
      display: flex;
      justify-content: center;

      .cancel,
      .commit {
        border: none;
        width: 120px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }

      .cancel {
        background: #dcdcdc;
        margin-right: 30px;
        color: black;
      }

      .cursor_click {
        cursor: pointer;
      }

      .cursor_unclick {
        cursor: not-allowed;
      }

      .commit {
        // background: $common_bg;
        background: #70b600;
        color: white;
      }
    }
  }
}
</style>