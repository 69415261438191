<template>
  <div class="download">
    <page-header></page-header>
    <div class="content ub">
        <el-image class="download-img" :src="image" lazy></el-image>
        <div class="download-info">
          <div class="download-title">程序蛙PC端专为4~16岁孩子打造的“互联网+线上线下”全方位编程课程</div>
          <div class="download-sub-title">培养孩子形成编程思维，拥有创新能力</div>
          <div class="download-sub-title">提升数学等学科的学习能力</div>
          <div class="download-sub-title">成为数字时代需要的创新型人才</div>
          <div class="download-btn" v-if="versionInfo">
              <a :href="versionInfo.url ? versionInfo.url : 'javascript:void(0);'" v-if="!loadErr">
                  <div v-if="computerType == 1 || computerType == 2">
                      <span class="load-text">一键下载</span>
                      <span class="version">最新版本{{versionInfo.version}}</span>
                      <div class="icon-item">
                        <span class="iconfont cxw-icons-dunpai">官方</span>
                        <span class="iconfont cxw-icons-dunpai">优质</span>
                        <span class="iconfont cxw-icons-dunpai">安全</span>
                      </div>
                  </div>
                  <div v-else-if="computerType == 3"><span>mac电脑暂不支持</span></div>
                  <div v-else><span>该电脑暂不支持</span></div>
              </a>
              <a class="err" href="javascript:void(location.reload());" v-else>获取失败，点击刷新！</a>
          </div>
          <div class="download-other" v-if="versionInfo && !loadErr">
              <div class="other-item"><a class="iconfont cxw-icons-xiazai" :href="downloadUrl.downloadUrl32">Win 32 <span>最新版本：{{downloadUrl.versionWin32}}</span></a></div>
              <div class="other-item"><a class="iconfont cxw-icons-xiazai" :href="downloadUrl.downloadUrl64">Win 64 <span>最新版本：{{downloadUrl.versionWin64}}</span></a></div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '../../components/PageHeader'
import {getLatestVersion} from '../../http/api'
export default {
  components: {
    PageHeader
  },

  data() {
    return {
      image: require('../../assets/image/download_pic.png'),
      versionInfo: null,
      computerType: '',
      loadErr: false,
    }
  },

  created() {
    console.log(getLatestVersion)
    console.log(this)
    this.getComputerType()
    this.getDownLoadInfo()
  },

  methods: {
    getComputerType() {
      let agent = navigator.userAgent.toLowerCase();
      let isMac = function() {
            return /macintosh|mac os x/i.test(navigator.userAgent);
          }();
      if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
        this.computerType = 1
      }else if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
        this.computerType = 2
      }
      if(isMac){
        this.computerType = 3
      }
    },

    /**
    * 获取下载数据
    */
    getDownLoadInfo () {
        getLatestVersion().then((res) => {
            console.log(res)
            console.log(res.versionInfo)
            let downloadUrl = JSON.parse(res.versionInfo.newDownloadUrl)
            if(this.computerType == 1) {
                res.versionInfo.url = downloadUrl.downloadUrl32
            } else if (this.computerType == 2){
                res.versionInfo.url = downloadUrl.downloadUrl64
            } else {
                res.versionInfo.url = ''
            }
            this.versionInfo = res.versionInfo
            this.downloadUrl = downloadUrl
        }).catch (() => {
            this.loadErr = true
        }) 
    },
  }
}
</script>

<style lang="scss">
.download {
  .content {
    width: 1190px;
    margin: 132px auto 0;
    .download-img {
      width: 438px;
      margin-right: 100px;

      img {
        height: auto;
      }
    }

    .download-info {
      width: 668px;
      color: #535353;

      .download-title {
        font-size: 38px;
        font-weight: bold;
        margin-bottom: 10px;
        letter-spacing: 2px;
      }

      .download-sub-title {
        font-size: 27px;
        line-height: 52px;
        letter-spacing: 2px;
        padding-left: 67px;
        background: url('../../assets/image/download_sub_title_bg.png')
                    no-repeat
                    10px center /
                    auto auto;
      }

      .download-btn {
        margin-top: 35px;
        width: 403px;

        a {
          display: inline-block;
          width: 403px;
          text-align: center;
          background-color: var(--el-color-primary);
          border-radius: 4px;
          color: #fff !important;
          padding: 6px 0 8px;
          position: relative;
        }
      }

      .download-other {
        width: 668px;
        text-align: left;
        color: #00b89c;
        margin-top: 20px;

        .other-item {
          width: 403px;
          line-height: 26px;
          border-bottom: 1px solid #ccc;
          padding: 5px;
          font-size: 14px;

          a {
            width: 100%;
            padding: 0 25px 0 5px;
            position: relative;
            color: #000;
            box-sizing: border-box;
            display: inline-block;
            font-size: 14px;

            span {
              float: right;
            }
          }

          a::before {
            position: absolute;
            right: 3px;
            top: 0;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>