<template>
  <div :class="['module-advert', isBigAdvert ? '' : 'small']" :style="'bottom:' + advertButton + 'px'">
      <div class="module-content ub">
          <div class="advert-title">投资未来的人，才能拥有未来！</div>
          <div class="advert-ewm">
            <el-image :src="require('../../../assets/image/contact_us.png')" lazy></el-image>
            <div class="ewm-title">加入教育合伙人</div>
          </div>
          <span :class="['iconfont screen-btn', isBigAdvert ? 'icon-tuichuquanping' : 'icon-quanping']" @click="setAdvert()"></span>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            isBigAdvert: true,
            advertButton: 0
        }
    },

    created() {
        let isBigAdvert = sessionStorage.getItem('isBigAdvert');
        if (isBigAdvert == 'false') {
            this.isBigAdvert = false
        } else {
            this.isBigAdvert = true
        }
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll, true) // 监听（绑定）滚轮滚动事件
    },

    methods: {
        setAdvert() {
            this.isBigAdvert = !this.isBigAdvert
            sessionStorage.setItem('isBigAdvert', JSON.parse(this.isBigAdvert))
        },

        handleScroll() {
            let clientHeight =
            document.documentElement.clientHeight || document.body.clientHeight
            // 文档内容实际高度
            let bodyHeight = this.getScrollHeight()
            // 滚动条滚动高度
            let scrollHeight = this.getScrollTop()
            // 距离底部高度
            let showFooterHeight = bodyHeight - (scrollHeight + clientHeight)
            // this.showFooterHeight = showFooterHeight
            this.setAdvertBotton(showFooterHeight)
        },

        setAdvertBotton(showFooterHeight) {
            if (showFooterHeight < 156) {
                this.advertButton = 156 - showFooterHeight
            } else {
                this.advertButton = 0
            }
        },

        // 获取文档内容实际高度
        getScrollHeight() {
            return Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight
            )
        },

        // 获取滚动条滚动高度
        getScrollTop() {
            var scrollTop = 0
            if (document.documentElement && document.documentElement.scrollTop) {
                scrollTop = document.documentElement.scrollTop
            } else if (document.body) {
                scrollTop = document.body.scrollTop
            }
            return scrollTop
        },
    }
}
</script>

<style lang="scss">
.module-advert {
    width: 100%;
    height: 158px;
    background-color: #ffa837;
    position: fixed;
    bottom: 0;
    z-index: 99;

    .module-content {
        width: 1168px;
        margin: 0 auto;
        position: relative;

        .advert-title {
            width: 742px;
            padding: 0 20px;
            height: 158px;
            line-height: 158px;
            font-size: 48px;
            font-weight: bold;
            color: #fff;
            background-image: -webkit-linear-gradient(top, #fff 40%, #ffa837);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: left;
            position: relative;
            letter-spacing: 4px;
        }

        .advert-title::before, .advert-title::after {
            content: '';
            width: 32px;
            height: 32px;
            border: 5px solid #fff;
            position: absolute;
        }

        .advert-title::before {
            left: 0;
            top: 35px;
            border-right: 0;
            border-bottom: 0;
        }

        .advert-title::after {
            right: 24px;
            bottom: 38px;
            border-left: 0;
            border-top: 0;
        }

        .advert-ewm {
            width: 116px;
            margin-left: 20px;
            text-align: center;

            img {
                margin-top: 13px;
                width: 112px;
                height: 107px;
            }

            .ewm-title {
                width: 116px;
                height: 26px;
                line-height: 26px;
                color: #fff;
                font-weight: 600;
            }
        }

        .screen-btn {
            position: absolute;
            right: 0px;
            z-index: 10;
            font-size: 18px;
            color: #fff;
            cursor: pointer;
            padding: 0 3px;
            border: 3px solid #fff;
            color: #fff;
            border-radius: 4px;
            top: 13px;
            font-weight: bold;
        }
    }

    .module-content::before {
        content: '';
        width: 240px;
        height: 218px;
        position: absolute;
        right: 0;
        bottom: 0;
        background-image: url('../../../assets/image/home_advert.png');
        background-size: auto 100%;
        background-repeat: no-repeat;
    }
}

.module-advert.small {
    width: 189px;
    height: 198px;
    background-color: #fff;
    border-radius: 10px;
    position: fixed;
    right: 25px;
    bottom: 347px;
    font-size: 0;
    margin-bottom: 20px;
    box-shadow: #7a7a7a 0 0 15px;

    .module-content { 
        width: 100%;
        height: 100%;

        .advert-title {
            display: none;
        }

        .screen-btn {
            right: 8px;
            top: 8px;
            border-color: #ffa837;
            padding: 0 2px;
        }

        .screen-btn::before {
            color: #ffa837;
        }

        .advert-ewm {
            width: 100%;
            margin-left: 0;
            img {
                width: 100px;
                height: 100px;
                margin: 22px 0 28px;
            }

            .ewm-title {
                width: 100%;
                height: 48px;
                line-height: 48px;
                font-size: 16px;
                font-weight: bold;
                background-color: #ffa837;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                color: #fff;
            }
        }
    }

    .module-content::before {
        display: none;
    }
}
</style>