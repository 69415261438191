<template>
 <div class="modle-quesition">
     <quesition-model :paperParams="paperParams"></quesition-model> 
     <!-- <YCQuesition></YCQuesition> -->
 </div>
</template>

<script>
import QuesitionModel from './components/quesition/QuesitionsModel.vue'
// import YCQuesition from 'tk-npm-project';
import config from '../../http/config'
 export default {
   data () {
     return {
       paperParams: null
     }
   },
   components: {
     QuesitionModel,
    //  YCQuesition
   },


   created () {
     let params = this.$route.params
      this.paperParams = {
        type: params.type,
        projectUrl: config.newUrl,
        examId: params.examId,
        uid: params.uid,
        classId: params.classId,
        role: params.role,
        pageName: 'modelTest'
      }
      console.log(this.paperParams)

      /* this.paperParams = {
            examId: "20426",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 1,
            classId: 1800314,
            uid: 2000002345,
            role: '1'
        } */
        /* this.paperParams = {
            examId: "10102",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 2,
            classId: 1800311,
            uid: 366,
            role: '1'
        } */
        /* this.paperParams = {
            examId: "10101",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 3,
            classId: 1800385,
            uid: 2000002355,
            role: '2'
        } */
        /* this.paperParams = {
            examId: "10102",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 4,
            classId: 1800311,
            uid: 2000002345,
            role: '2'
        } */
   }
 } 
</script>

<style>
.modle-quesition {
  height: 100%;
}
 
</style>
