// 选择课程的弹框
<template>
  <div class="choose_course">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <div class="real_content">
          <!-- 加载框 -->
          <!-- <Loading v-if="showLoading"></Loading> -->
          <!-- 内容 -->
          <div class="choose_course_dialog_content">
            <!-- 列表布局 -->
            <div class="course_list">
              <div
                class="item_course"
                v-for="(item, index) in courseData"
                :key="index"
                @click="doItemClick(item)"
              >
                <!-- 图片 -->
                <div class="dv_show_img">
                  <img
                    :src="item.subjectImg"
                    :class="[
                      'img_course',
                      { activeBorder: item.subjectState == 1 },
                    ]"
                  />
                </div>

                <!-- 姓名 -->
                <div
                  :class="[
                    'name_course',
                    { activeColor: item.subjectState == 1 },
                  ]"
                >
                  {{ item.subjectName }}
                </div>

                <!-- 时间 -->
                <!-- <div :class="['time_course']"> -->
                  <!-- 有效期：{{ item.startTime }}-{{ item.expireTime }} -->
                <!-- </div> -->
              </div>
            </div>
            <!-- 底部的取消和确定按钮 -->
            <div class="bom_action">
              <!-- 取消 -->
              <div class="cancel" @click.stop="close">
                <span>取</span><span style="margin-left: 16px">消</span>
              </div>
              <!-- 确定 -->
              <el-button
                :loading="showCommitLoading"
                :class="['commit']"
                @click.stop="doCommitAction"
              >
                <span>确</span><span style="margin-left: 16px">定</span>
              </el-button>
            </div>

            <!-- 确定取消框 -->
            <!-- <div class="inner_dialog"></div> -->
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
import { mapGetters } from "vuex";

import { managerClass } from "../../../http/api";
export default {
  data() {
    return {
      //宽度
      width: "640px",
      //是否显示确定按钮加载框的标识
      showCommitLoading: false,
      //显示加载框
      showLoading: true,
      courseData: [],
    };
  },
  created() {
    //获取数据
    this.httpData();
  },
  methods: {
    //获取列表数据
    httpData() {
      managerClass({
        type: "13",
        uid: this.getUserInfo.id,
        token: this.getUserInfo.token,
        cid: this.cid,
      })
        .then((res) => {
          console.log(res);

            //表示请求成功
            this.courseData = res.subjectList;
            console.log(this.courseData);
         
        })
        .catch(() => {});
    },
    

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },
    //确定的操作
    doCommitAction() {
      this.showCommitLoading = true;
      this.httpCourseState();
    },

    //请求同步课程状态的接口
    httpCourseState() {
      managerClass({
        type: "14",
        uid: this.getUserInfo.id,
        token: this.getUserInfo.token,
        subIdList: this.getSubIdList(),
        cid: this.cid,
      })
        .then((res) => {
          console.log(res);

 
            //表示请求成功,通知班级界面进行数据刷新
            this.showCommitLoading = false;
            this.$emit("updatePage");
            this.close();
         
        })
        .catch(() => {});
    },
  
    //获取传递选中课程的数据
    getSubIdList() {
      let selectedCourse = this.courseData.filter(
        (item) => item.subjectState == 1
      );
      let subIdsArr = selectedCourse.map((obj) => {
        return obj.subjectId;
      });

      return subIdsArr.join(",");
    },

    //条目的点击事件
    doItemClick(item) {
      console.log(item);
      //改变选中状态
      if (item.subjectState == 1) {
        //取消
        if (item.studyRecord != 0) {
          //有学习记录
          this.$confirm(
            "该班级当前课程已有学习记录，确认更换课程权限吗？（注意：更换课程权限后将会影响学生访问原课程）",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
            }
          ).then(() => {
            //点击确定的操作
            item.subjectState = 0;
          });
        } else {
          item.subjectState = 0;
        }
      } else if (item.subjectState == 0) {
        //选中
        item.subjectState = 1;
      }
    },

    showSuccessMsg(text) {
      this.$message({
        message: text,
        type: "success",
      });
    },
    showErrorMsg(text) {
      this.$message.error(text);
    },

    showWarnMsg(text) {
      this.$message({
        message: text,
        type: "warning",
        showClose: true,
      });
    },
    getMesage(code) {
      let res = "";
      switch (parseInt(code)) {
        case 104:
          res = "传入参数异常";
          break;
        case 105:
          res = "程序异常";
          break;
        case 108:
        case 208:
          res = "您的账号已被挤下线,请重新登录~~";
          setTimeout(() => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("autoLogin");
            this.$router.replace("/");
          }, 1000);
          break;
        case 201:
          res = "此账号不存在";
          break;
        case 202:
          res = "学校加盟时间到期";
          break;
        case 203:
          res = "账号已存在";
          break;
        case 205:
          res = "手机号已被注册";
          break;
        case 207:
          res = "无效用户";
          break;
        case 209:
          res = "昵称重复";
          break;
        case 210:
          res = "用户未绑定手机号";
          break;

        case 211:
          res = "该手机号已绑定其他微信";
          break;
        case 212:
          res = "微信已绑定其他手机号";
          break;
        case 213:
          res = "与原密码一致";
          break;
        case 214:
          res = "账号已被停用";
          break;
        case 301:
          res = "验证码发送失败";
          break;
        case 302:
          res = "验证码错误";
          break;
        case 303:
          res = "今日短信次数已超上限";
          break;
        case 304:
          res = "验证码已失效";
          break;
        case 305:
          res = "验证码发送间隔过短";
          break;
        case 306:
          res = "验证码类型错误";
          break;
        case 500:
          res = "服务器异常";
          break;
        case 1001:
          res = "班级邀请码错误";
          break;
        case 1002:
          res = "人数已达上限";
          break;
        case 1009:
          res = "班级名重复";
          break;
        case 1010:
          res = "合作到期";
          break;
        case 1003:
          res = "班级课程到期";
          break;
        case 1011:
          res = "消息已失效";
          break;

        case 1004:
          res = "学生已加入申请的班级";
          break;
        case 1006:
          res = "班级名称重复";
          break;
        case 1013:
          res = "班级不存在";
          break;
        case 1018:
          res = "已申请加入班级，请勿重复申请";
          break;
        case 1017:
          res = "已在老师的其他班级里，不可重复加入班级哦";
          break;
        case 1019:
          res = "已申请加入老师名下班级，请等待老师审批";
          break;
        case 1020:
          res = "该班级名额已满，请联系老师";
          break;
        case 1021:
          res = "学生人数已满";
          break;
      }
      return res;
    },
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  components: {
    CommonDialog,
  },
  props: ["showDialog", "title", "cid"],
};
</script>
<style lang="scss" scoped>
.choose_course {
  ::v-deep .el-dialog {
    // top: 20%;
    // background: #eff2f7;
  }

  .real_content {
    min-height: 450px;
    .choose_course_dialog_content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .course_list {
        display: flex;
        flex-wrap: wrap;
        min-height: 500px;

        .item_course {
          width: 33%;
          height: 210px;
          background: white;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          margin-bottom: 10px;
          .dv_show_img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 68%;
            height: 110px;
            .img_course {
              width: 70%;
              height: 110px;
              object-fit: contain;
              border-radius: 4px;
            }
          }
          .activeBorder {
            border: 2px solid #70b600;
          }

          .name_course {
            margin-top: 12px;
            color: black;
            font-size: 12px;
            font-weight: bold;
          }
          .activeColor {
            color: #70b600;
          }

          .time_course {
            margin-top: 10px;
            font-size: 10px;
            // color: $text_gray;
            color: gray;
          }
        }
      }

      //底部的操作按钮
      .bom_action {
        margin-top: 20px;
        height: 48px;
        display: flex;
        justify-content: center;

        .cancel,
        .commit {
          width: 120px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
        }

        .cancel {
          background: #dcdcdc;
          margin-right: 30px;
          color: black;
        }

        .commit {
          // background: $common_bg;
          background: #70b600;
          color: white;
        }
      }

      //操作框
      .inner_dialog {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.4);
      }
    }
  }
}
</style>