//开始学习界面
<template>
    <div class="study ub ub-ver">
        <!-- 顶部的返回以及导航条部分 -->
        <div class="top">
            <!-- 返回 -->
            <div @click.stop="goBack" class="back iconfont icon-zuojiantou"></div>
            <!-- 占位 -->
            <div class="placeholder">{{ title }}</div>
            <!-- 当前小节文字 -->
            <div class="curr_name">当前小节</div>
            <!-- 导航条 -->
            <div class="top_nav">
                <div class="" :class="['item_nav',currPos == index ? 'item_nav_select' : 'item_nav_normal',]" v-for="(item, index) in navArr" :key="index" @click.stop="doTopNavClick(item, index)" :title="item.title">{{ index + 1 }}</div>
            </div>
            <!-- 头像 -->
            <img class="img_head" @click.stop="goPersonInfo" :src="getUserInfo.user_img" width="32" height="32" />
        </div>

        <!-- 底部切换PPT,慕课视频,答题,测评的组件,做保活处理 -->
        <keep-alive :include="['TeachingPlan','ClassPpt']">
            <component :is="currentView" :id="id" :type="parseInt(currPos) + 1" :mode="mode" :curriculumId="curriculumId" :mainId="mainId" />
        </keep-alive>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
//上课ppt
import ClassPpt from "./ClassPpt.vue";
//引入教案组件
import TeachingPlan from "./TeachingPlan.vue";
//项目范例
import ClassPptThird from "./ClassPptThird.vue";
//引入拼搭图PDF展示界面
import TickPicturePdf from "./TickPicturePdf.vue";
import ScratchModle from "./ScratchModle.vue";
// import screen from "../../mixin/screen";
import screen from "../../../minxin/screen"
export default {
    mixins: [screen],
    data() {
        return {
            title: '',
            //顶部导航条选中的索引,默认进来第一个选中
            currPos: 0,
            //当前显示的组件
            currentView: "",
            //顶部导航条的数据,需要根据不同课程来动态传递
            navArr: [],
            //用户头像
            user_img: "",
            //每个类型组件请求时需要传递的参数
            curriculumCenterId: "",
            curriculumChapterId: "",
            chapterClassId: "",
            //记录进入页面时history的长度
            rlen: history.length,
        };
    },
    created() {
        //初始化数据
        this.initData();
    },
    mounted() {
        //禁用右键相关功能
        this.prohibit();
    },
    methods: {
        //初始化数据
        initData() {
            //初次进入的时候从localStory中取出保存的顶部Tab栏的索引
            let curPos = localStorage.getItem("study_pos");
            if (!this.$util.isDefine(curPos)) {
                curPos = 0;
            }
            //将顶部的导航栏的索引位置选中
            this.currPos = curPos;

            //获取传递参数
            console.log(this.$route.query)
            this.id = this.$route.query.id
            this.mode = this.$route.query.mode
            this.curriculumId = this.mode == 2 ? this.$route.query.curriculumId : ''
            this.mainId = this.mode == 2 ? this.$route.query.mainId : ''
            this.title = this.$route.query.title

            //获取章节类型的标识,类似1-2-3这种标识
            this.type = this.$route.query.type;

            //先清空顶部导航条的数据
            this.navArr.splice(0, this.navArr.length);

            if (this.$util.isDefine(this.type)) {
                //1:使用-切割获取课程对应的各个组件
                let comArrs = this.type.split("-");

                if (this.$util.isDefine(comArrs)) {
                    comArrs.forEach((item) => {
                        this.navArr.push(this.getObjByType(item));
                    });
                }
            }
            //加载初始化组件
            console.log(this.navArr)
            console.log(curPos)
            this.currentView = this.navArr[curPos].name;
            console.log(this.currentView)
        },

        //根绝类型创建对索引对象
        getObjByType(type) {
            let obj = new Object();
            switch (parseInt(type)) {
                case 1:
                    obj.name = "ClassPpt";
                    obj.title = "上课ppt";
                    break;
                case 2:
                    obj.name = "TeachingPlan";
                    obj.title = "教案";
                    break;
                //原理的PDF展示
                // case 3:
                //     obj.name = "ClassPptThird";
                //     obj.title = "拼搭图";
                //     break;
                //更改后的PDF显示
                case 3:
                case 4:
                    obj.name = "ScratchModle";
                    obj.title = "源程序";
                    break;
            }
            return obj;
        },

        //返回
        goBack() {
            //返回的时候还原索引位置
            localStorage.setItem("study_pos", 0);
            this.$router.push('/courseCenter')
            // let len = this.rlen - history.length - 1; //-1是不进入iframe页面的下级页面直接退出的话，执行后退一步的操作
            // this.$router.go(len);
        },
        //顶部导航的点击事件
        doTopNavClick(item, pos) {
            if (this.currPos != pos) {
                this.currPos = pos;
                //使用组件跳转
                window.close();

                this.currentView = item.name;
                //localStory保存一下位置
                localStorage.setItem("study_pos", pos);
            }
        },
    },
    computed: {
        ...mapGetters([
            'getUserInfo'
        ]),
    },
    components: {
        TeachingPlan,
        ClassPpt,
        ClassPptThird,
        TickPicturePdf,
        ScratchModle
        
    },
};
</script>
<style lang="scss" scoped>
.study {
    // display: flex;
    // flex-direction: column;
    height: 100%;
    .top {
        display: flex;
        height: 70px;
        min-height: 70px;
        align-items: center;
        background: #302f35;
        color: white;
        .back {
            color: white;
            font-size: 22px;
            font-weight: bold;
            cursor: pointer;
            margin-left: 16px;
        }

        .placeholder {
            flex: 1;
            text-align: center;
        }

        //当前小节名称
        .curr_name {
            font-size: 14px;
        }

        //导航条
        .top_nav {
            margin: 0 16px;
            display: flex;
            .item_nav {
                cursor: pointer;
                margin-left: 8px;
                margin-right: 8px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                border: 2px solid white;
            }

            //顶部导航条选中的样式
            .item_nav_select {
                background: #fdd420;
            }

            //顶部导航条未选中样式
            .item_nav_normal {
                background: #494b56;
            }
        }

        //头像
        .img_head {
            margin-right: 26px;
            cursor: pointer;
            border-radius: 50%;
        }
    }
}
.study ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
</style>