<template>
  <div class="page-footer">
    <div class="footer-content ub ub-ac ub-pc">
        <img class="logo" src="../assets/image/footer_logo.png" alt="">
        <div class="message message-tel ub-f1-item">
          <p>武汉智多蛙教育科技有限公司</p>
          <p>电话：18162618661</p>
          <!-- <p>鄂ICP备19022715号-1</p> -->
          <p><a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备19022715号-2</a></p>
        </div>
        <div class="message message-address">
          <p>地址：武汉市江汉区建设大道562号国贸新都B座27楼F室<span class="zipCode">邮编：430000</span></p>
          <p>Copyright © 2016 All Right Reserved</p>
          <p class="ub ub-ac">
            <!-- <img src="../assets/image/guohui.png" alt="" srcset="" /><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010302002059" target="_blank">鄂公网安备 42010302002059号</a> -->
            <img src="../assets/image/guohui.png" alt="" srcset="" /><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010302002443" target="_blank">鄂公网安备 42010302002443号</a>
            <img :src="require('../assets/image/gswj.png')" width="19" height="19" title="武汉网监电子标识" alt="武汉网监电子标识" border="0">
            <a href="http://wljg.scjgj.wuhan.gov.cn:80/whwjww/indexquery/indexqueryAction!dizview.dhtml?chr_id=55ff813bea859e6c7f7c7094e1789a42&amp;bus_ent_id=160000011096085425&amp;bus_ent_chr_id=6376cd1ff3304363b62ebdea4de498a1" target="_blank">工商网监</a>
          </p>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.page-footer {
    width: 100%;
    height: 156px;
    color: #fff;
    background-color: var(--el-color-primary);
    
    .footer-content {
      width: 1000px !important;
      margin: 0 auto;
      height: 100%;

      img.logo {
        width: 230px;
        height: 76px;
        margin-right: 7px;
      }

      .message {
        line-height: 30px;
      }

      .message-tel {
        margin-left: 32px;
      }

      .message-address {
        a {
          margin-right: 10px;
        }

        img {
          margin-right: 5px;
        }
      }
    }
}
</style>