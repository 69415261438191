<template>
  <div class="about-company">
    <p class="fw-b">1、上课需要哪些工具？</p>
    <p class="p-con">电脑+课堂用书+练习册+上课软件</p>
    <p class="fw-b">2、微课打不开怎么办？</p>
    <p class="p-con">请检查网络是否正常，IE建议9.0以上</p>
    <p class="fw-b">3、官方推荐浏览器？</p>
    <p class="p-con">360浏览器 、qq浏览器</p>
    <p class="fw-b">4、课程不能点击</p>
    <p class="p-con">
      首先确定您是否购买了课程，只有购买的课程才能点击操作，可以在我的订单中查看购买的信息。如果您购买了课程，可以尝试重新登录，如果还不行，可咨询客服。
    </p>
    <p class="fw-b">5、购买后仍然不能点击课程？</p>
    <p class="p-con">
      请先确定购买成功，购买以后请刷新页面或者重新登录。如果还不能打开页面，请咨询客服。
    </p>
    <p class="fw-b">6、为什么练习题做不了？</p>
    <p class="p-con">练习题必须等课程关卡完成才可以做！请先闯关再做题。</p>
    <p class="fw-b">7、为什么我闯过的关卡打不开了？</p>
    <p class="p-con">
      如果关卡的锁是关闭状态，则是因为教师对关卡进行了锁定，只有教师才能打开，请联系教师解锁。
    </p>
    <p class="fw-b">8、锁是什么意思啊？</p>
    <p class="p-con">锁住是指教师为了统一学生进度，而对关卡进行锁定。</p>
    <p class="fw-b">9、怎么购买课程？</p>
    <p class="p-con">
      在课程页面，点击购买，扫码支付，购买成功后刷新页面，即可以对课程进行操作。
    </p>
    <p class="fw-b">10、怎么更改密码？</p>
    <p class="p-con">在创建页面，点击角色进行更改密码。</p>
    <p class="fw-b">11、如何查看班级？</p>
    <p class="p-con">点击个人头像，在下拉框中选择我的班级，进入班级页面。</p>
    <p class="fw-b">12、怎样加入班级？</p>
    <p class="p-con">需要找教师提供邀请码。</p>
    <p class="fw-b">13、怎么知道自己是否已经加入了班级？</p>
    <p class="p-con">
      点击头像下拉单中我的班级，班级页面出现班级信息则加入班级成功，班级页面没有班级则还没有成功加入班级。
    </p>
    <p class="fw-b">14、怎么选择适合孩子的课程？</p>
    <p class="p-con">
      可以根据孩子的年龄和年级进行选择。也可以咨询客服推荐。
    </p>
    <p class="tx-c" style="margin-bottom:0;line-height:0;">
      <img src="../../assets/image/contact_us.png" alt="" srcset="" />
    </p>

    <p class="tx-c fw-b">扫码咨询客服</p>
  </div>
</template>

<script>
export default {
  components: {

  }
}
</script>

<style>

</style>