<template>
 <div class="correct ub ub-ver ub-f1">
     <div class="title">（共{{getQuesitionData.correctUsers}}人待批改）</div>
     <QueCorrectList class="ub-f1"></QueCorrectList>
 </div>
</template>

<script>
import QueCorrectList from './QueCorrectList.vue'
import { mapGetters } from 'vuex'

 export default {
   data () {
     return {

     }
   },
   components: {
       QueCorrectList
   },

   computed: {
     ...mapGetters([
        'getQuesitionData'
      ]),
   }
 }
</script>

<style lang="scss">
.correct {
    .title {
        text-align: center;
        font-size: 14px;
        line-height: 70px;
    }
}
 
</style>
