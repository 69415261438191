<template>
  <div class="que-type4">
    <div class="que-option">
      <div class="image-content ub ub-ac ub-pc">
        <img :src="getQuesitionData.actQueData.optionList[0].option" alt="" srcset="">
        <QueButton type="submit" @buttonClick="buttonAction" :text="getQuesitionData.pageType == 1 ? '点击作答' : '查看'"></QueButton>
      </div>
    </div>
    
    <el-dialog  :model-value="dialogTableVisible" :fullscreen=true custom-class="que-dialog" :show-close=false>
      <iframe v-if="iframeSrc" ref="scratch3Iframe" id='scratch3Id' class="scratch3" :src="iframeSrc" frameborder="0" :width= "scratch3W +'px'" :height= "scratch3H+'px'"></iframe>
      
      <transition-group name="normal-expand">
        <div :class="['look-que-content', lookQueContentVisible ? 'show' : 'hide']" :key='111' id="look-que-content" :style="'height:'+scratch3H+'px'">
          <!-- <el-scrollbar style="height:100%" > -->
            <QueStemArea v-if="lookQueContentVisible" />
          <!-- </el-scrollbar> -->
        </div>
      </transition-group>
      
      <div class="scratch3-nav" v-if="getQuesitionData.pageType == 1">
        <div class="save-btn scratch3-nav-btn ub ub-pc"  @click="saveBtnAction"><span class="scratch3-nav-btn-span scratch3-nav-btn-save">保存并返回</span></div>
        <div :class="['look-que scratch3-nav-btn', lookQueContentVisible ? 'show' : 'hide']" @click="lookQueBtnAction"><span class="scratch3-nav-btn-span scratch3-nav-btn-look">{{ lookQueContentVisible ? '收起题目' : '查看题目' }}</span></div>
        <!-- <div class="look-que scratch3-nav-btn" v-if="lookQueContentVisible" @click="lookQueBtnAction"><span class="scratch3-nav-btn-span scratch3-nav-btn-look-active">收起题目</span></div> -->
        <div class="reload-btn scratch3-nav-btn" @click="reloadAction" ><span class="scratch3-nav-btn-span scratch3-nav-btn-reload">重置</span></div>
      </div>
      <div class="scratch3-nav" v-else>
        <div class="save-btn scratch3-nav-btn ub ub-pc"  @click="dialogTableVisible = !dialogTableVisible"><span class="scratch3-nav-btn-span scratch3-nav-btn-save">关闭窗口</span></div>
        <div class="save-btn scratch3-nav-btn ub ub-pc" @click="lookQueContentVisible = !lookQueContentVisible"><span class="scratch3-nav-btn-span scratch3-nav-btn-look">{{lookQueContentVisible ? '关闭题目' : '查看题目'}}</span></div>
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QueButton from '../QueButton.vue'
import QueStemArea from "../QueStemArea.vue";
import config from '../../../../../http/config'
export default {
  data() {
    return {
      dialogTableVisible: false,
      lookQueContentVisible:false,
      direction: 'rtl',
      scratch3W:1032,
      scratch3H:680,
      navMT:80,
      oldArea:{
        w:1032,
        h:680
      },
      isSave:'',
      projectId:'',
      // iframeHost: 'http://localhost:8601',  // 本地
      iframeHost: 'https://pbl.zdw000.com/scratch3.0',     // 本地

      iframeSrc: ''
    }
  },
  mounted () {
    console.log(config.newUrl)
    let host = this.GetUrlRelativePath(config.newUrl)
    if (this.checkip(host)) {
      this.iframeHost = 'https://pbl.zdw000.com/test/scratch3_test/'
    }
    var self = this;
    //监听窗口改变
    window.addEventListener('resize',function(){
      if (self&&self.dialogTableVisible&&((Math.abs(self.oldArea.w-window.frames.innerWidth)>=5)||(Math.abs(self.oldArea.h-window.frames.innerHeight)>=5))) {
        self.resizeAction();
      }
    });
    window.addEventListener('message',(e) =>{
        var params=e.data;
        this.dialogTableVisible = false
        this.getQuesitionData.actQueData.userAnswer = params.projectId
    },false);


  },
  methods: {
    handleClose(){

    },
    saveBtnAction(){
      console.log("保存并返回");
      
      let scratch3Id = document.getElementById('scratch3Id')
      let projectId = this.getQuesitionData.actQueData.userAnswer
      scratch3Id.contentWindow.postMessage({projectId: projectId ? projectId : ''}, `*`);
      this.dialogTableVisible = !this.dialogTableVisible;

    },
    lookQueBtnAction(){
      console.log("查看题目",this.lookQueContentVisible);
      this.lookQueContentVisible = !this.lookQueContentVisible;
      
      
    },
    reloadAction(){
      console.log("重置");
       document.getElementById('scratch3Id').src = `${this.iframeHost}${this.getQuesitionData.actQueData.userAnswerType ? `#${this.getQuesitionData.actQueData.userAnswerType}?isTk=true&pageType=${this.getQuesitionData.pageType}`: `?isTk=true&pageType=${this.getQuesitionData.pageType}`}`
       setTimeout(() => {
         let scratch3Id = document.getElementById('scratch3Id')
         console.log(scratch3Id)
          scratch3Id.contentWindow.postMessage({isTk: this.dialogTableVisible}, '*');
       }, 1500)
    },
    sendMsg(type){
      const params = {
        detail:{
          tel:'15500838051',
          type:type
        }
      };
      console.log(this.$refs.scratch3Iframe.contentWindow);
      this.$refs.scratch3Iframe.contentWindow.postMessage('queReq',params)
    },
    
    buttonAction() {
      var self = this;
      console.log(this.dialogTableVisible)
      this.dialogTableVisible = !this.dialogTableVisible
      this.lookQueContentVisible = false
      console.log(this.dialogTableVisible)
      setTimeout(() => {
        let scratch3Id = document.getElementById('scratch3Id')
        console.log(this.iframeHost)
        console.log(scratch3Id)
        console.log(scratch3Id.contentWindow)
        scratch3Id.contentWindow.postMessage({isTk: this.dialogTableVisible}, '*');
      }, 1500)
      var ts = new Date().getTime()
      console.log(this.getQuesitionData.actQueData)
      let userAnswer = ''
      if (this.getQuesitionData.pageType == 1) {
        // this.getQuesitionData.actQueData.userAnswerType = 331
        
        if (this.getQuesitionData.actQueData.userAnswerType && !this.getQuesitionData.actQueData.userAnswer) {
          userAnswer = this.getQuesitionData.actQueData.userAnswerType
        } else {
          userAnswer = this.getQuesitionData.actQueData.userAnswer
        }
      } else {
        userAnswer = this.getQuesitionData.actQueData.useAnswerRecord
      }
      console.log(userAnswer)
      this.iframeSrc = `${this.iframeHost}${userAnswer ? `#${userAnswer}&ts=${ts}&isTk=true&oldProjectId=${this.getQuesitionData.actQueData.userAnswerType}&pageType=${this.getQuesitionData.pageType}` : `?ts=${ts}&isTk=true&pageType=${this.getQuesitionData.pageType}`}`
      console.log("弹出答题",window.frames.innerWidth,window.frames.innerWidth)
      self.resizeAction();
      console.log(this.iframeSrc)
    },

    resizeAction(){
      const innerWidth = window.frames.innerWidth;
      const innerHeight = window.frames.innerHeight;

      const ave = (innerWidth-100)/(innerHeight-40)
      const oldAve = 1032/680
      let width , height
      if (ave > oldAve) {
        //过宽 按照高算
        height = innerHeight -40
        width = innerWidth-100
      }else{
        width = innerWidth -100
        height = width*680/1032
      }
      this.navMT = (height-3*136)/4
      this.scratch3W = width
      this.scratch3H = height
      let navArr = document.getElementsByClassName('scratch3-nav-btn')      
      setTimeout(() => {
        for (let i = 0; i < navArr.length; i++) {
        navArr.item(i).style.marginTop = this.navMT+'px'   
      }
      }, 100);
      this.oldArea.w = innerWidth 
      this.oldArea.h = innerHeight
    },

    GetUrlRelativePath(url) {
        var arrUrl = url.split("//");
        var arrUrl2 = arrUrl[1].split('/')
        let host = ''
        if (arrUrl2[0].indexOf(':') != -1) {
          host = arrUrl2[0].split(':')[0]
        } else {
          host = arrUrl2[0]
        }
        return host;
    },

    checkip(host) {
      var pattern=/^(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])$/;
      let flag_ip=pattern.test(host);
      if(!flag_ip){   
        return false;
      }
      return true
    }
  },
  components: {
    QueStemArea,
    QueButton
  },
  computed: {
        ...mapGetters([
          'getQuesitionData'
        ]),

       getAnswer: {
           get: function () {
               if (this.getQuesitionData.pageType == 1) {
                   return this.getQuesitionData.actQueData.userAnswer
               } else if (this.getQuesitionData.pageType == 2){
                   return this.getQuesitionData.actQueData.useAnswerRecord
               } else if (this.getQuesitionData.pageType == 4) {
                   return this.getQuesitionData.actQueData.optionAnswer
               } else {
                 return ''
               }
           },

           set: function (val) {
              this.getQuesitionData.actQueData.userAnswer = val
           }
       }
   },

   watch: {
     
   }
  

}
</script>

<style lang="scss">
.que-option {
  .image-content {
    margin: 28px auto 0;
    width: 409px;
    height: 249px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
    }

    button {
      position: relative;
      z-index: 99;
      width: 184px;
      height: 37px;
      border-radius: 30px;
      border: 0px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      background-color: #595959;
      
        span {
          color: #fff;
        }
    }

    button:active {
      border: 0px;
    }
  }

  .image-content::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    z-index: 9;
  }

  
  
}

.que-type4{

.que-dialog{
    background-color: rgba($color: #000000, $alpha: 0.4);
    overflow: hidden !important;
      .el-dialog__header{
        display: none;
      }
      .el-dialog__body{
        background: none;
        padding: 20px;
        width: 100%;
        height: 100%;
        iframe {
          background-color: #fff;
        }
      }
    .scratch3{
      float: left;
      border-radius:10px;
      min-width: 1032px;
      min-height: 680px;
    }
    .look-que-content{
      position: absolute;
      width: 500px;
      right: 80px;
      border-radius: 10px;
      background-color: white;
      min-height: 680px;
      overflow: hidden;
      box-shadow: -1px 0px 8px 1px #888888;
      transition: transform 0.1s, opacity 0.2s;
  }

  .look-que-content.hide {
    // transform: translateX(500px);
    opacity: 0;
    z-index: -1;
  }
    .main-stem {
      height: 100%;
    }
    .scratch3-nav{
      width: 80px;
      float: right;
    }
    .scratch3-nav-btn{
      margin-left: 20px;
      width: 40px;
      height: 136px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: royalblue;
      color: white;
      overflow: clip;
      cursor: pointer;
      position: relative;
      z-index: 999;
      .scratch3-nav-btn-span{
        display: block;
        line-height: 22px;
        font-size: 18px;
        writing-mode: horizontal-tb;
        font-weight: 500;
      }
      .scratch3-nav-btn-save{
        padding: 14px 10px;
      }
      .scratch3-nav-btn-look{
        padding: 25px 10px;
      }
      .scratch3-nav-btn-look-active{
        padding: 25px 10px;
      }
      .scratch3-nav-btn-reload{
        padding: 46px 10px;
        background-color: #fff;
        color: royalblue;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      
    }
    
  }

  .normal-expand-enter-active{
    transition:all 0.5s ease;
  }
  .normal-expand-enter {
    width: 0px !important;
  }
  .normal-expand-enter-to {
    width: 500px !important;
  }
  .normal-expand-leave-active{
    transition:all 0.3s ease;
  }
  .normal-expand-leave {
    width: 500px !important;
  }
  .normal-expand-leave-to {
    width: 0px !important;
  }
  }



</style>