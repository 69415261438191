<template>
  <div class="model-test">
    <page-header></page-header>
    <div class="content ub ub-ver">
      <div class="ub ub-f1" v-if="modleData">
        <!-- 左侧班级 -->
        <div class="class-menu ub ub-ver" id="class-menu" v-if="getUserInfo && modleData.userClassList.length > 0">
          <div class="menu-title">请选择班级</div>
          <div class="menu-list ub-f1">
            <div class="menu-list-content">
              <el-scrollbar style="height:100%" >
                <ul>
                    <li :class="index == classIndex ? 'act' : ''" 
                        v-for="(item, index) in modleData.userClassList" 
                        :key="index"
                        @click="index == classIndex ? '' : classSwitch(item.classId, index)">
                    <el-badge is-dot :hidden="item.correctNum == 0 || !item.correctNum" class="item">{{item.className}}</el-badge></li>
                </ul>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <!-- 左侧班级 end -->

        <!-- 右侧试卷 -->
        <div class="class-content ub ub-ver ub-f1" id="class-content" >
          <div class="tab-header ub ub-pc" v-if="leverData && leverData.length > 0">
            <div class="tab-item ub ub-ac ub-pc ub-f1" 
                v-for="(item, index) in leverData" 
                :key="index"
                @click="index == leverIndex ? '' : leverSwitch(index)">
                  <span :class="index == leverIndex ? 'act' : ''">{{item.mainName}}</span>
            </div>
          </div>
          <div class="tab-content ub ub-ver ub-f1" v-if="leverData">
            <div class="lever-module">
              <ul>
                <li :class="index == leverSubIndex ? 'act' : ''" 
                v-for="(item, index) in leverData[leverIndex].examSubTypeList" 
                :key="index"
                @click="index == leverSubIndex ? '' : leverSubSwitch(index)">{{item.usbName}}</li>
              </ul>
            </div>
            <div class="model-test-content ub ub-ver ub-f1">
              <ModelTestContent title="模拟考试" v-if="paperData" :paperData="paperData.answerList" :classId="modleData.userClassList[classIndex].classId"></ModelTestContent>
              <ModelTestContent title="历年真题" v-if="paperData" :paperData="paperData.subjectList" :classId="modleData.userClassList[classIndex].classId"></ModelTestContent>
              <loading-info v-if="isPaperLoading" :isLoading="isPaperLoading" loadText="试卷加载中..."></loading-info>
              <el-empty description="加载试卷信息失败，请重试！" v-if="isPaperLoadErr"></el-empty>
            </div>
          </div>
          <loading-info v-if="isLeverLoading" :isLoading="isLeverLoading" loadText="信息加载中..."></loading-info>
          <el-empty :description="isLeverLoadErrDes" v-if="isLeverLoadErr"></el-empty>
        </div>
        <!-- 右侧试卷 end -->
      </div>
      <el-empty :description="loadErrMsg ? loadErrMsg : '加载失败，请重试'" v-if="isLoadErr"></el-empty>
      <loading-info v-if="isLoading" :isLoading="isLoading" loadText="资源加载中..."></loading-info>
    </div>
  </div>
</template>

<script>
import { teacherHomePage } from '../../http/api'
import { mapGetters } from 'vuex'
import PageHeader from '../../components/PageHeader'
import LoadingInfo from '../../components/LoadingInfo.vue'
import ModelTestContent from './components/ModelTestContent.vue'
export default {
  components: {
    PageHeader,
    LoadingInfo,
    ModelTestContent
  },

  data() {
    return {
      isLoading: false,
      isLoadErr: false,
      loadErrMsg: '',
      modleData: '',
      // 班级
      classIndex: 0,
      // 级别
      leverData: null,
      leverIndex: 0,
      leverSubIndex: 0,
      // 试卷
      paperData: '',
      isPaperLoading: false,
      isPaperLoadErr: false,
      isLeverLoading: false,
      isLeverLoadErrDes: ''
    }
  },

  computed: {
    ...mapGetters([
      'getUserInfo'
    ])
  },

  created() {
    let selectAct = localStorage.getItem('selectAct')
    if (!selectAct) {
      localStorage.setItem('selectAct', JSON.stringify({classIndex: 0, leverIndex: 0, leverSubIndex: 0, examId: ''}))
    } else {
      selectAct = JSON.parse(selectAct)
      this.classIndex = selectAct.classIndex
      this.leverIndex = selectAct.leverIndex
      this.leverSubIndex = selectAct.leverSubIndex
    }
    this.getClassList()
  },

  methods: {
    /**
     * 获取班级
     */
    getClassList() {
      this.isLoading = true
      teacherHomePage({
          type: 1,
          uid: this.getUserInfo.id
      }).then((res) => {
          console.log(res)
          this.modleData = res.data
          console.log(this.modleData)
          this.isLoading = false
          if(res.data.userClassList.length == 0) {
            this.noClass()
          } else {
            this.getLeverList()
          }
          this.classIndex = res.data.userClassList.length > this.classIndex ? this.classIndex : 0
      }).catch((err) => {
        console.log(err)
          this.isLoadErr = true
          this.isLoading = false
          this.loadErrMsg = err.data ? err.data.msg : ''
      })
    },

    /**
      * 暂无班级
      */
    noClass () {
      this.isLeverLoadErr = true
      this.isLeverLoadErrDes = this.getUserInfo.role == 2 ? '暂无班级，请先创建班级！' : '暂无班级，请先加入班级！'
    },

    /**
     * 设置当前高亮节点
     */
    setActLocalStorage(key, index) {
      let selectAct = JSON.parse(localStorage.getItem('selectAct'))
      selectAct[key] = index
      localStorage.setItem('selectAct', JSON.stringify(selectAct))
    },

    /**
     * 获取级别
     */
    getLeverList() {
      this.isLoadEnd = false
      this.isLeverLoading = true
      this.isLeverLoadErr = false
      this.isPaperLoading = false
      this.isPaperLoadErr = false
      teacherHomePage({
          type: 5,
          classId: this.modleData.userClassList[this.classIndex].classId,
          projectSign: 'frog'
      }).then((res) => {
          console.log(res)
          this.leverData = res.data
          this.isLeverLoading = false
          console.log(this.leverData)
          this.leverIndex = this.leverData.length > this.leverIndex ? this.leverIndex : 0
          this.getPaperData()
          
      }).catch((err) => {
          console.log(err)
          this.leverData = ''
          this.paperData = ''
          this.isLeverLoadErr = true
          this.isLeverLoadErrDes = err && err.data.msg ? err.data.msg : '加载失败'
          this.isLeverLoading = false
          this.isLoadEnd = true
      })
    },

    /**
     * 获取试卷
     */
    getPaperData() {
      this.isPaperLoading = true
      this.isPaperLoadErr = false
      this.paperData = ''
      this.leverSubIndex = this.leverData[this.leverIndex].examSubTypeList .length > this.leverSubIndex ? this.leverSubIndex : 0
      teacherHomePage({
          type: 3,
          mainId: this.leverData[this.leverIndex].mainId,
          subId: this.leverData[this.leverIndex].examSubTypeList[this.leverSubIndex].subId,
          classId: this.modleData.userClassList[this.classIndex].classId,
          accountId: this.getUserInfo.id,
          projectSign: 'frog'
      }).then((res) => {
          console.log(res)
          this.paperData = res.data
          this.repairData('answerList', 4)
          this.repairData('subjectList', 4)
          this.isPaperLoading = false
          this.isLoadEnd = true
          console.log(this.paperData)
      }).catch(() => {
          this.isPaperLoading = false
          this.isPaperLoadErr = true
          this.isLoadEnd = true
      })
    },

    /**
     * 补差数据
     * @param {String} key 需要补数据的对象
     * @param {Number} num 每页滚动的数据
     */
    repairData(key, num) {
      let remainder = 0
      if (this.paperData[key].length > num) {
        remainder = num - (this.paperData[key].length % num)
      }
      for (let i = 0; i < remainder; i++) {
        this.paperData[key].push({})
      }
    },

    /**
    * 切换班级
    */
    classSwitch (id, index) {
      if (!this.isLoadEnd) return;
      if (this.getUserInfo.role == 2) {
          this.classId = this.modleData.userClassList[index].classId
      }
      this.classIndex = index
      this.leverIndex = 0
      this.leverSubIndex = 0
      this.setActLocalStorage('classIndex', index)
      this.setActLocalStorage('leverIndex', 0)
      this.setActLocalStorage('leverSubIndex', 0)
      this.setActLocalStorage('examId', '')
      this.getLeverList(id)

    },

    /**
    * 切换级别
    */
    leverSwitch (index) {
      this.leverIndex = index
      this.leverSubIndex = 0
      this.setActLocalStorage('leverIndex', index)
      this.setActLocalStorage('leverSubIndex', 0)
      this.setActLocalStorage('examId', '')
      this.getPaperData()
    },

    /**
    * 切换小级别
    */
    leverSubSwitch (index) {
      this.leverSubIndex = index
      this.setActLocalStorage('leverSubIndex', index)
      this.setActLocalStorage('examId', '')
      this.getPaperData()
    }
  },
  watch: {
    $route(to) {
      console.log(to)
      if (to.path != '/modelPractice') {
        localStorage.clear('selectAct')
      }
    }
  }
}
</script>

<style lang="scss">
.model-test {
  background-color: #f0f3f8;
  .content {
    min-height: 900px;
    width: 1192px;
    margin: 0 auto;
    position: relative;

    .class-menu {
      width: 208px;
      min-width: 208px;
      background-color: #fff;
      margin-right: 13px;

      .menu-title {
        line-height: 68px;
        margin-top: 7px;
        text-align: center;
      }

      .menu-list {
        position: relative;

        .menu-list-content {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;

          .el-scrollbar__wrap {
            overflow-x: hidden;

            ul {
              li {
                width: 180px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                margin: 5px auto 26px;
                background-color: #bfbfbf;
                color: #fff;
                border-radius: 100px;
                cursor: pointer;

                .el-badge {
                  width: 100%;
                }
              }

              li.act, li:hover {
                background-color: #f39800;
              }
            }
          }
        }
      }
    }

    // 右侧
    .class-content {
      width: 1192px;
      .tab-header {
        height: 83px;
        background-color: #fff;
        .tab-item {
          width: 271px;
          position: relative;

          span {
            display: block;
            width: 210px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            cursor: pointer;
          }

          span.act {
            background-color: var(--el-color-second);
            border-radius: 100px;
            color: var(--el-color-primary);
          }

          span:hover {
            color: var(--el-color-primary);
          }
        }

        .tab-item::before {
          content: '';
          width: 1px;
          height: 26px;
          background-color: #ccc;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -13px;
        }

        .tab-item:last-child::before {
          display: none;
        }
      }

      .lever-module {
        text-align: left;
        padding: 13px 0;
        li {
          display: inline-block;
          min-width: 168px;
          padding: 0 10px;
          height: 40px;
          line-height: 40px;
          background-color: #fff;
          border-radius: 100px;
          text-align: center;
          margin-right: 12px;
          cursor: pointer;
        }

        li.act, li.act:hover {
          color: #fff;
          background-color: var(--el-color-primary);
        }

        li:hover {
          color: var(--el-color-primary);
        }
      }

      .model-test-content {

      }
    }
  }
}
</style>