<template>
 <div class="loading" v-loading="isLoading">
     <div class="load-text">{{loadText}}</div>
 </div>
</template>

<script>
 export default {
     props:{
         isLoading: {
            type: Boolean,
            default: false
         },

         loadText: {
             type: String,
             default: '加载中...'
         }
     },
   data () {
     return {

     }
   },
   components: {

   }
 }
</script>

<style lang="scss">
.loading {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .load-text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100px;
        margin-left: -50px;
        margin-top: 30px;
        color: #4c4c4c;
        text-align: center; 
        z-index: 10000;
    }
}
 
</style>
