// 引入vue-router对象
import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";


// 1. 定义路由加载模式
const history = createWebHashHistory() 

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
    { 
      path: '/', 
      component: require('@/pages/home/home-page').default,
      name: 'home',
      meta: {
        isMenu: true,
        title: '首页'
      }
    }, { 
      path: '/courseCenter',
      component: require('@/pages/course/course-center').default,
      name: 'courseCenter',
      meta: {
        title: '课程中心',
        login: true,
        isMenu: true
      }
    }, { 
      path: '/courseProcess',
      component: require('@/pages/course/courseProcess/course-process').default,
      name: 'courseProcess',
      meta: {
        title: '课程进度',
        login: true
      }
    }, { 
      path: '/modelTest', 
      component: require('@/pages/modelTest/model-test').default,
      name: 'modelTest',
      meta: {
        isMenu: true,
        title: '模考中心',
        login: true
      }
    }, { 
      path: '/modelPractice', 
      component: require('@/pages/modelTest/model-practice').default,
      name: 'modelPractice',
      meta: {
        isMenu: false,
        title: '题库',
        login: true
      }
    }, { 
      path: '/aboutUs', 
      component: require('@/pages/aboutUs/about-us').default,
      name: 'aboutUs',
      meta: {
        isMenu: true,
        title: '关于我们'
      },
      children: [{
        path: '/aboutUs/aboutCompany',
        component: require('@/pages/aboutUs/about-company').default,
        name: 'aboutCompany',
        meta: {
          title: '公司简介'
        }
      }, {
        path: '/aboutUs/legalDisclaimer',
        component: require('@/pages/aboutUs/legal-disclaimer').default,
        name: 'legalDisclaimer',
        meta: {
          title: '法律声明'
        }
      }, {
        path: '/aboutUs/aboutProblem',
        component: require('@/pages/aboutUs/common-problem').default,
        name: 'aboutProblem',
        meta: {
          title: '常见问题'
        }
      }]
    }, { 
      path: '/downloadSoftware', 
      component: require('@/pages/download/download-software').default,
      name: 'downloadSoftware',
      meta: {
        isMenu: true,
        title: '下载'
      }
    },
    { 
      path: '/TeacherManagerClass', 
      component: require('@/pages/managerClass/TeacherManagerClass').default,
      name: 'TeacherManagerClass',
      meta: {
        isMenu: false,
        title: '班级管理',
        login: true
      }
    },
    { 
      path: '/StudentMyClass', 
      component: require('@/pages/managerClass/StudentMyClass').default,
      name: 'StudentMyClass',
      meta: {
        isMenu: false,
        title: '我的班级',
        login: true
      }
    },
    { 
      path: '/StuEvaluateIphone', 
      component: require('@/pages/evaluate/evaluate-center').default,
      name: 'StuEvaluateIphone',
      meta: {
        isMenu: false,
        title: '学生评价',
        login: false
      }
    },
    
]
  
// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history,
    routes, // `routes: routes` 的缩写
})


// 4. 添加全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 判断该路由是否需要登录权限
  let userInfo = ''
  if (localStorage.getItem('autoLogin') === 'true') {
    userInfo = JSON.parse(localStorage.getItem('userInfo'));
  } else {
    userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  }
  console.log(userInfo)
  if (to.meta.login) {
    if (userInfo) {
      next();
    } else {
      store.dispatch('setLoginAlertShow', true)
    }
  } else {
    next(); // 确保一定要有next()被调用
  }
})

export default router