<template>
  <div class="module-two">
    <h5>智多蛙课程体系</h5>
    <div class="content-body">
        <!-- <el-image :src="list[0].image" lazy></el-image> -->
              <el-image :src="image" lazy></el-image>

    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            image: require('../../../assets/image/home_module_two.png')
        }
    }
}
</script>

<style lang="scss">
.module-two {
    height: 1377px;
    h5 {
        color: var(--el-color-primary);
        height: 394px;
        line-height: 464px;
        font-weight: bold;
        padding: 0;
        margin: 0;
        font-size: 66px;
        letter-spacing: 8px;
        text-align: center;
    }

    .content-body {
        width: 1188px;
        height: 820px;
        margin: 0 auto;

        .el-image {
            display: block;
        }
    }
}
</style>