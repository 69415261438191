/**
* 验证是否为空
* @param {*} value 
*/
const isDefine = (value) => {
 if (value == null || value == "" || value == "undefined" || value == undefined || value == "null" || value == "(null)" || value == 'NULL' || typeof(value) == 'undefined') {
   return false;
 } else {
   value = value + "";
   value = value.replace(/\s/g, "");
   if (value == "") {
     return false;
   }
   return true;
 }
}

/**
 * 删除字符串左右空格
 */
 const trim = (str) => {
    return str.replace(/(^\s*)|(\s*$)/g, "");
}

/*
   * 获取url中"?"符后的参数
   *
   */
const getRequest = function () {
  var url = window.location.href;
  var theRequest;
  if (url.indexOf("?") != -1) {
    var urlArr = url.split('?');
    var paremtsArr = urlArr[1].split('&');
    theRequest = {};
    for (var i = 0; i < paremtsArr.length; i++) {
      theRequest[paremtsArr[i].split("=")[0]] = unescape(paremtsArr[i].split("=")[1]);
    }
  }
  return theRequest;
}

export default {
    isDefine,
    trim,
    getRequest
}