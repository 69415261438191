let envName = 'prod';
let env = require(`../env/${envName}.js`);
let host = env.base
let newUrl = host.newHost + '/ycsj_questions_bank/api'                              

let config = {
    newUrl,
    getLatestVersion: newUrl + '/VersionController/getLatestVersion',
    login: newUrl + '/login',
    teacherHomePage: newUrl + '/TeacherHomePage',
    curriculumController: newUrl + '/CurriculumController',
    pfrResourcesController: newUrl + '/PfrResourcesController',
    // quesition
    questionInfoConfig: newUrl + '/QuestionInfoConfig',
    examInfoConfig: newUrl + '/ExamInfoConfig',
    managerClass: newUrl + '/Update',
    versionControlle: newUrl + '/VersionController',
}

export default config