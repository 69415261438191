<template>
  <el-dialog
    :model-value="isLoginAlert"
    :title="title"
    :close-on-click-modal="false"
    custom-class="default-dialog login-dialog"
    :before-close="handleClose"
  >
    <el-alert :title="errInfo.title"
                v-if="errInfo.show"
                :closable="false"
                :type="errInfo.type"
                :style="loginType == 3?'width:450px;margin-left:-58px;':''"
                show-icon></el-alert>
    
    <!-- 登录 -->
    <div class="login-content signIn"
           v-if="loginType == 1">
        <el-input class="userPhone"
                  v-model="userTel"
                  placeholder="手机号"
                  clearable></el-input>
        <el-input class="passWord"
                  v-model="userPassWord"
                  placeholder="密码"
                  clearable
                  type="password"
                  @keyup.enter="loginButton"
                  autocomplete="new-password"></el-input>
      </div>
    <!-- 登录 end -->

    <!-- 忘记密码 -->
    <div class="login-content register"
           v-else-if="loginType == 2">
        <el-input class="userPhone"
                  v-model="userTel"
                  placeholder="手机号"
                  clearable></el-input>
        <div class="code ub">
          <el-input class="code-input"
                    v-model="userCode"
                    placeholder="验证码"
                    clearable
                    autocomplete="new-password"></el-input>
          <el-button class="code-button default-button"
                     type="primary"
                     :disabled='codeDisabled'
                     @click="sendCode(0)"
                     v-text="codeDisabled ? sendCodeTime + 's' : '发送验证码'"></el-button>
        </div>
        <el-input class="passWord"
                  v-model="userPassWord"
                  placeholder="密码"
                  clearable
                  type="password"
                  @keyup.enter="loginButton"
                  autocomplete="new-password"></el-input>
    </div>
    <!-- 忘记密码 end -->

    <!-- 注册 -->
    <div class="login-content forgetPassWord"
        v-else-if="loginType == 3">
      <el-input class="userName"
                v-model="userTel"
                placeholder="手机号"
                clearable></el-input>
      <el-input class="passWord"
                v-model="userPassWord"
                placeholder="密码"
                clearable
                type="password"></el-input>
      <div class="code ub">
        <el-input class="code-input"
                  v-model="userCode"
                  placeholder="验证码"
                  clearable
                  @keyup.enter="loginButton"
                  autocomplete="new-password"></el-input>
        <el-button class="code-button default-button"
                    :disabled='codeDisabled'
                     type="primary"
                    @click="sendCode(1)"
                  v-text="codeDisabled ? sendCodeTime + 's' : '发送验证码'"></el-button>
      </div>
    </div>
    <!-- 注册 end -->

    <!-- 登录/注册按钮 -->
    <el-button class="dialog-footer default-button"
                type="primary"
                @click="loginButton"
                v-text="loginType == 1?'确 定':loginType == 2?'重新设置密码':loginType == 3?'注 册':''"></el-button>

    <!-- 登录切换 -->
    <div class="login-info ub">
      <el-checkbox class="ub-f1"
        v-model="autoLogin"
        v-if="loginType == 1">下次自动登录</el-checkbox>
      <span class="register">
        <a v-if="loginType != 1"
            href="javascript:;"
            @click="setLoginType(1)">登录</a>
        <a v-if="loginType != 2"
            href="javascript:;"
            @click="setLoginType(2)">忘记密码</a>
        <a v-if="loginType != 3"
            href="javascript:;"
            @click="setLoginType(3)">注册</a>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import {login} from '../http/api'
export default {
  props: ["isLoginAlert"],
  data() {
    return {
      // isLoginAlert: true,
      loginType: 1,
      errInfo: {
        show: false,
        title: '123',
        type: 'info',
      },
      // 账号密码
      userTel: '',
      userPassWord: '',
      userCode: '',
      codeDisabled: false,
      sendCodeTime: 300,
      // 自动登录
      autoLogin: false,
      // 正则验证
      numReg: /^1[345789]\d{9}$/,
      passWordReg: /^[a-zA-Z0-9]{6,15}$/
    }
  },

  computed: {
    title() {
      return this.loginType == 1 ? '登录' : this.loginType == 2 ? '修改密码' : '注册'
    }
  },

  methods: {
    loginButton() {
      console.log(this.$util)
      if (!this.$util.isDefine(this.userTel)) {
        this.errInfo = {
          title: "请输入手机号！",
          show: true
        };
        return false;
      }

      if (!this.numReg.test(this.userTel)) {
        this.errInfo = {
          title: "请输入正确手机号！",
          show: true
        };
        return;
      }

      if (!this.$util.isDefine(this.userPassWord)) {
        this.errInfo = {
          title: "请输入密码",
          show: true
        };
        return;
      }

      if (this.loginType == 2 || this.loginType == 3) {
        if (!this.$util.isDefine(this.userCode)) {
          this.errInfo = {
            title: "请输入验证码",
            show: true
          };
          return;
        }
      }

      if (!this.passWordReg.test(this.userPassWord)) {
        this.errInfo = {
          title: "请输入正确密码！",
          show: true
        };
        return;
      }

      if (this.loginType == 1) {
        this.login();
      } else if (this.loginType == 2) {
        this.forgotPassword(0);
      } else {
        this.register(1);
      }

      this.errInfo = {
        title: "",
        isshow: false
      };
    },

    /**
     * 登录
     */
    login() {
      let params = {
        type: 1,
        c: this.userTel,
        p: this.userPassWord
      }
      console.log(params)
      login(params).then((res) => {
        console.log(res)
        this.$message({
          message: "登录成功！",
          type: "success",
          showClose: true,
          duration: 800
        });
        this.$store.dispatch('setLoginAlertShow', false)
        this.$store.dispatch('setUserInfo', res.userinfo)
        localStorage.setItem("autoLogin", this.autoLogin);
        if(this.autoLogin) {
          localStorage.setItem("userInfo", JSON.stringify(res.userinfo));
        } else {
          sessionStorage.setItem("userInfo", JSON.stringify(res.userinfo));
        }
        this.$router.replace("/");
      })
    },

    /**
     * 切换登录/注册窗口
     * @param {Number} type 1 登录 2 重置密码 3 注册
     */
    setLoginType(type) {
      this.loginType = type;
      this.userCode = "";
      this.errInfo.isshow = false;
      this.codeDisabled = false;
      this.sendCodeTime = 300;
    },

    /**
     * 发送验证码
     */
    sendCode(state) {
      console.log(state)
      this.errInfo.show = false
      if (!this.$util.isDefine(this.userTel)) {
        this.errInfo = {
          title: "请输入手机号！",
          show: true
        };
        return false;
      }

      if (!this.numReg.test(this.userTel)) {
        this.errInfo = {
          title: "请输入正确手机号！",
          show: true
        };
        return;
      }
      
      let params = {
        type: 2,
        c: this.userTel,
        state: state
      }
      console.log(params)
      login(params).then((res) => {
        console.log(res)
        this.$message({
          message: "发送成功！",
          type: "success",
          showClose: true
        });

        this.codeDisabled = true;
        this.timeCountDown();
      })
    },

    /**
     * 忘记密码
     */
    forgotPassword(state) {
      let params = {
        type: 4,
        c: this.userTel,
        p: this.userPassWord,
        code: this.userCode,
        state: state  
      }
      login(params).then((res) => {
        console.log(res)
        this.$message({
          message: "修改成功！",
          type: "success",
          showClose: true
        });
        this.setLoginType(1);
        this.loginButton();
      })
    },

    /**
     * 注册
     */
    register(state) {
      let params = {
        type: 3,
        c: this.userTel,
        p: this.userPassWord,
        code: this.userCode,
        state: state  
      }
      login(params).then((res) => {
        console.log(res)
        this.$message({
          message: "注册成功！",
          type: "success",
          showClose: true
        });
        this.setLoginType(1);
        this.loginButton();
      })
    },

    // 倒计时
    timeCountDown() {
      let that = this
      if (this.sendCodeTime === 0) {
        this.sendCodeTime = 300;
        this.codeDisabled = false;
        clearInterval(this.timer);
      } else {
        this.sendCodeTime--;
        this.timer = setTimeout(function() {
          that.timeCountDown();
        }, 1000);
      }
    },

    handleClose() {
      this.$store.dispatch('setLoginAlertShow', false)
      this.$router.replace("/");

    }
  },

  watch: {
  }
}
</script>

<style lang="scss">
.login-dialog {
  width: 923px;
  height: 457px;

  .el-dialog__body {
    width: 334px;
    margin: 0 auto;
    padding: 70px 0 0;
    position: relative;

    .el-alert {
      position: absolute;
      top: 16px;
      text-align: left;
      border: 1px solid #d46f16;
      color: #d46f16;
      background-color: #ffe6e5;
    }

    .login-content {
      .el-input {
        margin-bottom: 23px;

        input {
          height: 40px;
          line-height: 40px;
          background-color: #f4f4f4;
        }
      }

      .code {
        .code-button {
          height: 40px;
          line-height: 40px;
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }

    .dialog-footer {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      
    }

    .login-info {
      margin-top: 15px;
      text-align: right;
      width: 100%;
      
      .register {
        line-height: 32px;
        a {
          color: #808080;
          padding: 0 5px;
        }
      }
    }
  }
}
</style>