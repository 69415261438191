<template>
  <div class="model-test-center-process" v-if="modleTestInfo">
        <div class="tab-header ub ub-pc" >
            <div class="tab-item ub ub-ac ub-pc ub-f1" 
                v-for="(item, index) in modleTestInfo.examList" 
                :key="index"
                @click="leverSwitch(index)">
                    <span :class="index == leverIndex ? 'act' : ''">{{item.mainName}}</span>
            </div>
        </div>
        <ModelTestScrollResources 
            v-if="modleTestInfo.examList" 
            :curriculaList="modleTestInfo.examList[leverIndex].curriculaList" 
            :mainId="modleTestInfo.examList[leverIndex].mainId"
            :permit="modleTestInfo.examList[leverIndex].permit"
            :downloadUrl="modleTestInfo.examList[leverIndex].resourcesUrl"></ModelTestScrollResources>
  </div>
</template>

<script>
import ModelTestScrollResources from './ModelTestScrollResources'

export default {
    props: ['modleTestInfo'],
    
    components: {
        ModelTestScrollResources
    },

    data() {
        return {
            leverIndex: 0,
            paperData: [{
                pic: '123',
                title: '456'
            }, {
                pic: '123',
                title: '789'
            }, {
                pic: '123',
                title: '789'
            }, {
                pic: '123',
                title: '789'
            }],
        }
    },

    methods: {
        /**
         * 切换级别
         */
        leverSwitch (index) {
            this.leverIndex = index
            console.log(this.modleTestInfo.examList[index])
        },
    }
}
</script>

<style lang="scss">
.model-test-center-process {
    .tab-header {
        height: 83px;
        background-color: #fff;
        .tab-item {
            width: 271px;
            position: relative;

            span {
                display: block;
                width: 76px;
                height: 83px;
                line-height: 83px;
                text-align: center;
                cursor: pointer;
                position: relative;
            }

            span.act {
                // background-color: var(--second-bgc);
                border-radius: 100px;
                // color: var(--primary-color);
            }

            span.act::before, span:hover::before {
                content: '';
                width: 76px;
                height: 3px;
                background-color: var(--el-color-primary);
                position: absolute;
                bottom: 0;
                left: 0px;
            }
        }

    }
}
</style>