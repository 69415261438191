<template>
  <div :class="['page-header', isHome]">
      <div class="header-content ub ub-ac">
        <a href="/"><img class="header-logo" src="../assets/logo.png" alt=""></a>
        <div class="menu ub-f1">
          <el-menu class="ub">
            <el-menu-item :class="[route == item.path ? 'act' : '']" index="1" v-for="(item, index) in routes" :key="index">
              <router-link v-bind:to="item.path" v-if="item.meta.isMenu">{{ item.meta.title }}</router-link>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="login">
          <div class="login-button" @click="loginAlert" v-if="!getUserInfo">登录</div>
          <div class="login-info" v-else>
            <div class="user-info ub ub-ac ub-pc">
              <div class="user-img" :style="'background-image: url(' + userImg + ');'"></div>
            </div>
            <ul class="user-menu">
              <li>{{ getUserInfo.name }}</li>
              <li @click="showUserInfo">个人信息</li>
          <li><router-link v-if="getUserInfo.role == 1 ||getUserInfo.role == 0" to="/StudentMyClass">我的班级</router-link></li>

              <li><router-link v-if="getUserInfo.role == 2" to="/TeacherManagerClass">班级管理</router-link></li>
             
              <li @click="signOut()">退出</li>
            </ul>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },

  data() {
    return {
      activeIndex: 0,
      routes: [],
      route: '',
    }
  },

  mounted() {
    this.routes = this.$router.options.routes
  },

  created() {
    console.log(this)
  },

  computed: {
    ...mapGetters([
      'getUserInfo'
    ]),

    isHome() {
      return this.$route.path == '/' ? 'is-home' : ''
    },

    userImg() {
      return this.getUserInfo.user_img ? this.getUserInfo.user_img : require('../assets/image/header_default_user_logo.png')
    }
  },

  methods: {
    loginAlert() {
      this.$store.dispatch('setLoginAlertShow', true)
    },

    showUserInfo() {
      this.$store.dispatch('setUserInfoAlertShow', true)
    },

    signOut() {
      this.$confirm("是否退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.$store.dispatch('setUserInfo', '')
          console.log(this.$route)
          if (this.$route.meta.login) {
            this.$router.push( "/");
          }
        })
    }
  },

  watch: {
    'state.isLoginAlert' (newVal) {
       console.log(newVal)
     }
  }
}
</script>

<style lang="scss">
.page-header {
  width: 100%;
  background-color: var(--el-color-primary);

  .header-content {
    width: 1200px;
    height: 90px;
    margin: 0 auto;

    .header-logo {
      width: 157px;
      height: 49px;
      margin: 0px 25px 0 8px
    }

    .menu {
      .el-menu {
        border: none;
        background-color: unset;

        .el-menu-item {
          height: 90px;
          color: #fff;
          font-size: 18px;
          padding: 0 !important;

          a {
            width: 100%;
            height: 90px;
            line-height: 90px;
            box-sizing: border-box;
            padding: 0 40px;
          }

          .router-link-active {
            font-weight: bold;
          }
        }

        .el-menu-item:hover {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    .login {
      .login-button {
        width: 64px;
        height: 34px;
        line-height: 34px;
        border: 2px solid #fff;
        border-radius: 30px;
        cursor: pointer;
        transition: all .2s;
        -webkit-transition: all .2s;
        -moz-transition: all .2s;
        -o-transition: all .2s;
        color: #fff;
        text-align: center;
      }

      .login-button:hover {
        background-color: #fff;
        color: #000;
      }

      .login-info {
        position: relative;
        .user-info {
          width: 80px;
          height: 90px;
          cursor: pointer;

          .user-img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
            position: relative;
            background-color: #f4f4f4;
          }
        }

        .user-menu {
          position: absolute;
          background-color: #fff;
          display: none;
          width: 158px;
          position: absolute;
          right: 0;
          top: 90px;
          text-align: left;
          background-color: #eeeeee;
          z-index: 1999;
          display: none;

          li {
            width: 100%;
            box-sizing: border-box;
            line-height: 41px;
            padding: 0 28px;
            text-align: left;
            cursor: pointer;

            a {
              display: inline-block;
              width: 100%;
              height: 100%;
              color: #000000;
            }
          }

          li:hover {
            background-color: #e4e4e4;
          }

          li:first-child {
            border-bottom: 1px dashed var(--el-color-primary);
          }
        }
      }

      .login-info:hover {
        .user-info {
          background-color: rgba(225, 225, 225, 0.2);
        }
        .user-menu {
          display: block;
        }
      }
    }
  }
}

.page-header.is-home {
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>