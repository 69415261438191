<template>
 <div class="button">
     <el-button :class="isAct ? 'el-button-act' : ''" size="mini" :loading="isButtonLoading" round  @click="buttonTap">{{text}}</el-button>
 </div>
</template>

<script>
 export default {
     props: {
         isAct: {
             type: Boolean,
             default: false
         },
         text: {
             type: String,
             default: '提交'
         },

         change: {
             type: Function,
         },

         isButtonLoading: {
             type: Boolean,
             default: false
         }
     },
   data () {
     return {

     }
   },
   components: {

   },
   
   methods: {
       buttonTap() {
           this.$emit('buttonClick')
       }
   }
 }
</script>

<style lang="scss" scoped>
.button {
    .el-button {
        width: 184px;
        height: 37px;
        line-height: 33px;
        padding: 0;
        font-size: 16px;
        border-color: var(--el-color-primary);
        border-width: 2px;
        color: var(--el-color-primary);
    }

    .el-button:hover, .el-button:active {
        border-color: var(--el-color-primary);
        color: var(--el-color-primary);
    }

    .el-button-act {
        background-color: var(--el-color-primary);
        color: #fff;
    }

    .el-button-act:active {
        background-color: var(--el-color-primary);
        color: #fff;
    }

    .el-button-act:hover {
        background-color: var(--el-color-primary);
        color: #fff;
    }
}
 
</style>
