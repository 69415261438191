<template>
  <div class="course-center">
        <page-header></page-header>
        <div class="content ub ub-ver">
            <div class="mode-process" v-if="modleTestInfo">
                <ModelTestScrollProcess  :modleTestInfo="modleTestInfo"></ModelTestScrollProcess>
            </div>
            <div class="mode-resources" v-if="modleTestInfo">
                <ModelTestCenterResources  :modleTestInfo="modleTestInfo"></ModelTestCenterResources>
            </div>
            <loading-info v-if="isLoading" :isLoading="isLoading" loadText="加载中..."></loading-info>
            <el-empty :description="isLoadErr ? loadErrMsg : '加载失败，请重试'" v-if="isLoadErr"></el-empty>
        </div>
  </div>
</template>

<script>
import { curriculumController } from '../../http/api'
import PageHeader from '@/components/PageHeader.vue'
import ModelTestScrollProcess from './components/ModelTestScrollProcess.vue'
import ModelTestCenterResources from './components/ModelTestCenterResources.vue'
import LoadingInfo from '../../components/LoadingInfo.vue'
import { mapGetters } from 'vuex'
export default {
  components: { 
    PageHeader,
    ModelTestScrollProcess,
    ModelTestCenterResources,
    LoadingInfo
  },

  data() {
      return {
        modleTestInfo: '',
        isLoading: false,
        isLoadErr: false,
        loadErrMsg: '',
      }
  },

  computed: {
      ...mapGetters([
        'getUserInfo'
      ])
  },

  created() {
    this.getModleTest()
    console.log(this.$api)
  },

  methods: {
    getModleTest() {
      this.isLoading = true
      curriculumController({
          type: 1,
          role: this.getUserInfo.role,
          token: this.getUserInfo.token,
          uid: this.getUserInfo.id
      }).then((res) => {
          console.log(res)
          this.modleTestInfo = res.data
          this.isLoading = false
          console.log(this.modleTestInfo)
      }).catch((err) => {
          console.log(err)
          this.isLoadErr = true
          this.loadErrMsg = err.data.msg
          this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss">
.course-center {
    min-height: 100%;
    background-color: #f0f3f8;
    .content {
        width: 1192px;
        margin: 0 auto;
        min-height: 600px;

        .mode-process {
            // height: 191px;
            padding: 29px 0;
            background-color: #fff;
            margin-bottom: 14px;
        }

        .mode-resources {
            margin-bottom: 14px;
        }
    }
}
</style>