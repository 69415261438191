<template>
  <div class="module-five">
      <div class="module-content">
        <h5>程序蛙</h5>
        <div class="module-list ub ub-wrap">
            <div class="module-item ub ub-ver ub-ac" v-for="(item, index) in list" :key="index">
                <el-image :src="item.image" lazy></el-image>
                <div class="item-title">{{ item.title }}</div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: [{
                image: require('../../../assets/image/home_module_five_item1.png'),
                title: '闯关',
            },
            {
                image: require('../../../assets/image/home_module_five_item2.png'),
                title: '任务',
            },
            {
                image: require('../../../assets/image/home_module_five_item3.png'),
                title: '人文历史',
            },
            {
                image: require('../../../assets/image/home_module_five_item4.png'),
                title: '图形化，拖拽式',
            },
            {
                image: require('../../../assets/image/home_module_five_item5.png'),
                title: '游戏',
            },
            {
                image: require('../../../assets/image/home_module_five_item6.png'),
                title: '故事',
            }]
        }
    }
}
</script>

<style lang="scss">
.module-five {
position: relative;
    padding-top: 160px;

    .module-content {
        width: 1352px;
        height: 1805px;
        border: 5px solid var(--el-color-primary);
        margin: 0 auto;
        border-radius: 30px;

        h5 {
            padding: 0;
            margin: 0;
            font-size: 66px;
            letter-spacing: 8px;
            width: 822px;
            height: 154px;
            line-height: 154px;
            border: 5px solid var(--el-color-primary);
            border-radius: 30px;
            position: absolute;
            left: 50%;
            margin-left: -411px;
            top: 68px;
            background-color: #fff;
            z-index: 10;
            color: var(--el-color-primary);
            font-weight: 500;
            text-align: center;
        }

        .module-list {
            margin: 50px auto 0;
            .module-item {
                width: 50%;

                img {
                    width: 526px;
                    height: 405px;
                    margin: 50px auto 27px;
                }

                .item-title {
                    width: 380px;
                    height: 93px;
                    line-height: 93px;
                    background-color: var(--el-color-primary);
                    text-align: center;
                    border-radius: 50px;
                    font-size: 44px;
                    color: #fff;
                    margin: 0 auto;
                }
            }
        }
    }
}
</style>