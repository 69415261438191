<template>
 <div class="main-title" >
    <div class="main-info-con" v-html="title" v-if="titleType == 'mainInfo'"></div>
    <div class="main-quesition-con" v-html="title" v-if="titleType == 'mainQuesition'">
        
    </div>
    <div class="main-option-con ub" v-else-if="titleType == 'mainOption'">
        作答区
        <div class="ub ub-f1" v-if='getQuesitionData.pageType == 2'>
            <div :class="['judge ub-f1', isRight(1)]">
                <span class="iconfont" v-if="getQuesitionData.actQueData.questionType != 4" :class="getQuesitionData.actQueData.userQuestionIsRight == 1 ? 'icon-duihao' : 'icon-cuohao'"></span>
                {{isRight(2)}}
            </div>
            <div class="score">本题得分：{{getQuesitionData.actQueData.userQuestionSum}}分</div>
        </div>
    </div>
 </div>
</template>

<script>
import { mapGetters } from 'vuex'

 export default {
     props: {
         titleType: {
             type: String,
             default: ''
         },

         title: {
             type: String,
             default: ''
         },
     },
   data () {
     return {
         
     }
   },

   components: {

   },

   mounted() {
       
   },

   computed: {
       ...mapGetters([
        'getQuesitionData'
        ]),

       isRight() {
           return function (type) {
               if (type == 1) {
                //    return this.getQuesitionData.actQueData.useCorrectOrNot == 1 ? this.getQuesitionData.actQueData.userQuestionIsRight == 1 ? 'right' : 'err' :'not-reviewed'
                return this.getQuesitionData.actQueData.questionType == 4 ? this.getQuesitionData.actQueData.useCorrectOrNot == 1 ? '' : 'not-reviewed' : this.getQuesitionData.actQueData.userQuestionIsRight == 1 ? 'right' : 'err'
               } else if (type == 2) {
                //    return this.getQuesitionData.actQueData.useCorrectOrNot == 1 ? this.getQuesitionData.actQueData.userQuestionIsRight == 1 ? '正确' : '错误' :'未批改'
                return this.getQuesitionData.actQueData.questionType == 4 ? this.getQuesitionData.actQueData.useCorrectOrNot == 1 ? '已批改' : '未批改' : this.getQuesitionData.actQueData.userQuestionIsRight == 1 ? '正确' : '错误'
               }
           }
           
       }
   }
 }
</script>

<style lang="scss">
.main-title {
    height: 53px;
    line-height: 53px;
    color: #fff;
    background-color: var(--el-color-primary);
    padding: 0 24px;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    span {
        font-size: 14px;
    }

    .judge {
        span {
            display: inline-block;
            width: 38px;
            height: 38px;
            background-color: #fff;
            border-radius: 50%;
            position: relative;
            float: left;
            margin-top: 7.5px;
            margin-right: 12px;
            margin-left: 32px;
        }

        span::after {
            content: '';
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #fe6f71;
            position: absolute;
            left: 3px;
            top: 3px;
            z-index: 9;
        }

        span::before {
            height: 38px;
            line-height: 38px;
            font-size: 28px;
            color: #fff;
            font-weight: bold;
            position: absolute;
            left: 5px;
            z-index: 10;
        }
        /* content: '';
        width: ; */
    }

    .judge.err {
         span::after { 
             background-color: #fe6f71;
         }
    }

    .judge.right {
        span::after { 
            background-color: #22ac38;
        }
    }

    .judge.not-reviewed {
        padding-left: 42px;
        color: #fff100;
    }

    .score {
        flex: right;
    }
}
 
</style>
