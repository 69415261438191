export default {
  methods: {
    /**
     * 进入全屏
     * @param element
     */
    requestFullScreen(element) {
      // var requestMethod =
      //   element.requestFullScreen ||
      //   element.webkitRequestFullScreen ||
      //   element.mozRequestFullScreen ||
      //   element.msRequestFullScreen;
      // if (requestMethod) {
      //   requestMethod.call(element);
      // } else if (typeof window.ActiveXObject !== "undefined") {
      //   var wscript = new ActiveXObject("WScript.Shell");
      //   if (wscript !== null) {
      //     wscript.SendKeys("{F11}");
      //   }
      // }
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },

    /**
     * 退出全屏
     */
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },

    /**
     * 判断是否是全屏的方法
     */
    isFullscreen() {
      return (
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement
      );
    },

    // 禁用鼠标右击、F12 来禁止打印和打开调试工具
    prohibit() {
      document.oncontextmenu = function () {
        return false;
      };
      document.onkeydown = function (e) {
        if (
          (e.ctrlKey && e.keyCode === 65) ||
          e.keyCode === 67 ||
          e.keyCode === 73 ||
          e.keyCode === 74 ||
          e.keyCode === 80 ||
          e.keyCode === 83 ||
          e.keyCode === 85 ||
          e.keyCode === 86
          // ||e.keyCode === 117
        ) {
          return false;
        }
        //123是F12键 
        if (e.keyCode === 18) {
          return false;
        }
      };
    },
  }
}
