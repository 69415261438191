<template>
  <div class="module-three">
      <div class="module-content ub">
          <div :class="['module-item', 'module-item' + (index + 1)]" v-for="(item, index) in list" :key="index">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-subtitle">{{ item.subTitle }}</div>
            <div class="item-content">
              <p v-for="(subItem, subIndex) in item.content" :key="subIndex">{{ subItem }}</p>
              <el-image :src="item.image" lazy></el-image>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: [{
                title: '模式',
                subTitle: '线下授课',
                content: ['课堂学习+实际操作', '课程内容包含了知识讲解、游戏互动、知识拓展和实际操作等环节。'],
                image: require('../../../assets/image/home_module_three_item1_bg.png')
            }, {
                title: '课程资源',
                subTitle: '全套课程资源',
                content: ['课件+课本+练习册', '课件包含创作工具、教学课程、管理系统'],
                image: require('../../../assets/image/home_module_three_item2_bg.png')
            }, {
                title: '课程特色',
                subTitle: '"互联网+" 教育',
                content: ['教学简单化，编程趣味化', '多学科融合'],
                image: require('../../../assets/image/home_module_three_item3_bg.png')
            }]
        }
    }
}
</script>

<style lang="scss">
.module-three {
    height: 758px;
    background-color: #e9e9e9;
    .module-content {
        width: 1133px;
        margin: 0 auto;

        .module-item {
            width: 346px;
            height: 550px;
            background-color: #ffae3b;
            border: 5px solid #fff;
            border-radius: 15px;
            -webkit-box-shadow: 4px 5px 0 #c6c6c6;
            box-shadow: 4px 5px 0 #c6c6c6;
            margin-top: 130px;
            overflow: hidden;
            position: relative;

            .item-title {
                width: 100%;
                height: 132px;
                line-height: 132px;
                color: #fff;
                font-size: 68px;
                text-align: center;
            }

            .item-subtitle {
                width: 100%;
                height: 91px;
                line-height: 91px;
                background-color: #fff;
                font-size: 44px;
                color: #ec6941;
                font-weight: bold;
                text-align: center;
            }

            .item-content {
                padding: 60px 38px 0 63px;
                text-align: left;
                color: #fff;
                font-weight: bold;
                font-size: 18px;
                p {
                    margin-bottom: 12px;
                    position: relative;
                    letter-spacing: 2px;
                }

                p::before {
                    content: '';
                    width: 17px;
                    height: 17px;
                    background-color: #fff;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    left: -38px;
                    top: 4px;
                    display: block;
                    position: absolute;
                }
            }
        }

        .module-item1 {
            .item-title {
                letter-spacing: 70px;
                text-indent: 70px;
            }
        }
    }
}
</style>