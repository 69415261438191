<template>
 <div class="model-test-con">
     <div class="model-title">{{title}}</div>
     <div class="model-body">
         <model-test-scroll :paperData="paperData" v-if="paperData.length > 0" :classId="classId"></model-test-scroll >
         <div class="no-data" v-else>暂无数据</div>
     </div>
 </div>
</template>

<script>
import ModelTestScroll from './ModelTestScroll'
 export default {
     props:['title', 'paperData', 'classId'],
   data () {
     return {

     }
   },
   components: {
      ModelTestScroll
   }
 }
</script>

<style lang="scss" scoped>

.model-test-con{
    background-color: #fff;
    max-height: 404px;
    margin-bottom: 13px;

    .model-title {
        height: 80px;
        line-height: 80px;
        font-size: 20px;
        text-align: center;
    }

    .model-body {
        padding-bottom: 10px;

        .no-data {
            padding-bottom: 20px;
            text-align: center;
            color: #ccc;
        }
    }
}

.model-test-con:last-child {
    margin-bottom: 0;
}
 
</style>
